import React from 'react'
import ReactNumberFormat from 'react-number-format'
import { connect } from 'react-redux'

const NumberFormat = (props) => {
  const {
    decimalSeparator,
    dispatch,
    inputRef,
    max,
    min,
    noThousandSeparator = false,
    onBlur,
    onChange,
    onInputChange,
    value,
    thousandSeparator,
    ...other
  } = props
  const handleValueChange = (values) => {
    if (values.value === '') {
      onChange(null)
      if (onInputChange) {
        onInputChange(null)
      }
    } else {
      onChange(values.floatValue)
      if (onInputChange) {
        onInputChange(values.floatValue)
      }
    }
  }
  const handleBlur = () => onBlur()

  const isAllowed = (values) => {
    if (max && values.value && values.value > max) {
      return false
    }
    if (min && values.value && values.value < min) {
      return false
    }
    return true
  }

  return (
    <ReactNumberFormat
      isAllowed={isAllowed}
      decimalSeparator={decimalSeparator}
      thousandSeparator={noThousandSeparator ? '' : thousandSeparator}
      value={value}
      {...other}
      onValueChange={handleValueChange}
      onBlur={handleBlur}
    />
  )
}

export default connect(
  (state) => ({
    decimalSeparator: state.app.decimalSeparator,
    thousandSeparator: state.app.thousandSeparator,
  }),
  null
)(NumberFormat)
