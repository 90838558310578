import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagPoland = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <g fill="#FFF">
      <path d="M0 85.337h512v341.326H0z" />
      <path d="M0 85.337h512V256H0z" />
    </g>
    <path fill="#D80027" d="M0 256h512v170.663H0z" />
  </SvgIcon>
)

export default React.memo(FlagPoland)
