import { combineReducers } from 'redux'
import deepmerge from 'deepmerge'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const copyReducer = createReducer(initialState.table.copy, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({ ...initialState.table.copy }),
  [types.TABLE_COPY]: (state, { tree, entity, entityId }) => {
    const { copy, ...rest } = deepmerge({}, tree)
    return {
      entity,
      entityId,
      tree: rest,
    }
  },
  [types.TABLE_RESET_COPY]: (state, { entity }) => {
    if (entity === state.entity) {
      return { ...initialState.table.copy }
    }
    return state
  },
})

const dashboardFilterReducer = createReducer(
  initialState.table.dashboardFilter,
  {
    [types.AUTH_SIGNOUT_SUCCEED]: () => ({
      ...initialState.table.dashboardFilter,
    }),
    [types.TABLE_SET_DASHBOARD_FILTER]: (state, { data }) => data,
  }
)

const filtersReducer = createReducer(initialState.table.filters, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.table.filters,
  [types.TABLE_RESTORE_COPY]: (state, { tree }) => {
    if (
      tree.filters &&
      typeof tree.filters === 'object' &&
      !Array.isArray(tree.filters)
    ) {
      const copy = deepmerge({}, tree.filters)
      return copy
    }
    return state
  },
  [types.TABLE_ADD_FILTER_ITEM]: (state, action) => {
    const { [action.filter]: filter = {}, ...other } = state
    const { inverse } = action
    try {
      let selected = [...(filter.selected || [])]
      let inversed = [...(filter.inversed || [])]

      const ids = Array.isArray(action.id) ? action.id : [action.id]
      for (const id of ids) {
        if (!inverse) {
          if (!selected.includes(id)) {
            selected.push(id)
          }
          inversed = inversed.filter((item) => item !== id)
        } else {
          selected = selected.filter((item) => item !== id)
          if (!inversed.includes(id)) {
            inversed.push(id)
          }
        }
      }
      return {
        ...other,
        [action.filter]: {
          operator: filter.operator || 'or',
          expanded: filter.expanded,
          selected,
          inversed,
          neutral: filter.neutral || [],
        },
      }
    } catch (e) {
      return state
    }
  },
  [types.TABLE_DELETE_FILTER]: (state, action) => {
    const { [action.filter]: filter, ...other } = state
    if (filter) {
      return {
        ...other,
        [action.filter]: {
          operator: filter.operator || 'or',
          expanded: filter.expanded,
          selected: [],
          inversed: [],
        },
      }
    } else {
      return state
    }
  },
  [types.TABLE_DELETE_FILTERS]: () => initialState.table.filters,
  [types.TABLE_LOAD]: (state, action) => {
    const filters = { ...state }
    try {
      Object.keys(action.data.filters || {}).forEach((filter) => {
        if (!filters[filter]) {
          filters[filter] = {
            operator: filter.operator || 'or',
            expanded: filters[filter] || false,
            selected: [],
            inversed: [],
          }
        }
      })
      return filters
    } catch (e) {
      return state
    }
  },
  [types.TABLE_REMOVE_FILTER_ITEM]: (state, action) => {
    const { id, name } = action
    const { [action.filter]: filter, ...other } = state
    if (filter) {
      const selected = filter.selected.filter((item) => item !== id)
      const inversed = filter.inversed.filter((item) => item !== id)
      let neutral = [...(filter.neutral || [])]
      if (name) {
        const find = neutral.find((x) => x.id === id)
        if (!find) {
          neutral.push({ id, name })
        }
      }
      return {
        ...other,
        [action.filter]: {
          operator: filter.operator || 'or',
          expanded: filter.expanded,
          selected,
          inversed,
          neutral,
        },
      }
    } else {
      return state
    }
  },
  [types.TABLE_RESET]: () => initialState.table.filters,
  [types.TABLE_SELECT_FILTER_ITEM]: (state, action) => {
    const { [action.filter]: filter, ...other } = state
    if (filter) {
      return {
        ...other,
        [action.filter]: {
          operator: filter.operator || 'or',
          expanded: filter.expanded,
          selected: [action.id],
          inversed: [],
          neutral: filter.neutral || [],
        },
      }
    } else {
      return state
    }
  },
  [types.TABLE_SET_FILTER]: (state, action) => {
    const {
      [action.filter]: filter = { selected: [], inversed: [], neutral: [] },
      ...other
    } = state
    try {
      const selected = action.selected
        ? Array.isArray(action.selected)
          ? action.selected
          : [action.selected]
        : action.selected
      return {
        ...other,
        [action.filter]: {
          operator: filter.operator || 'or',
          expanded: filter.expanded || false,
          selected: action.selected
            ? [...new Set([...filter.selected, ...selected])]
            : filter.selected,
          inversed: action.inversed
            ? [...new Set([...filter.inversed, action.inversed])]
            : filter.inversed,
          neutral: filter.neutral || [],
        },
      }
    } catch (e) {
      return state
    }
  },
  [types.TABLE_SET_SAVED_FILTER_FIELDS]: (state, action) => {
    const { fields } = action
    const filters = { ...state }
    const expanded = []
    try {
      Object.keys(filters || {}).forEach((filter) => {
        if (filters[filter].expanded) {
          expanded.push(filter)
        }
        filters[filter] = {
          operator: 'or',
          expanded: false,
          selected: [],
          inversed: [],
          neutral: [],
        }
      })
      for (const filter of fields) {
        filters[filter.field] = {
          operator: filter.operator,
          expanded: expanded.includes(filter.field),
          selected: filter.selected,
          inversed: filter.inversed,
          neutral: filter.neutral || [],
        }
      }
      return filters
    } catch (e) {
      return state
    }
  },
  [types.TABLE_SET_TYPE]: () => initialState.table.filters,
  [types.TABLE_TOGGLE_FILTER_OPERATOR]: (state, action) => {
    const { [action.filter]: filter, ...other } = state
    if (filter) {
      return {
        ...other,
        [action.filter]: {
          ...filter,
          operator: filter.operator === 'and' ? 'or' : 'and',
        },
      }
    } else {
      return state
    }
  },
  [types.TABLE_TOGGLE_FILTER_PANEL]: (state, action) => {
    const { [action.filter]: filter, ...other } = state
    if (filter) {
      return {
        ...other,
        [action.filter]: {
          ...filter,
          expanded: !filter.expanded,
        },
      }
    } else {
      return state
    }
  },
})

const dataReducer = createReducer(initialState.table.data, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({ ...initialState.table.data }),
  [types.TABLE_RESTORE_COPY]: (state, { tree }) => {
    const copy = deepmerge({}, tree.data)
    return copy
  },
  [types.TABLE_DESELECT_ITEM]: (state, action) => ({
    ...state,
    selected: state.selected.filter((item) => item !== action.id),
  }),
  [types.TABLE_INSERT_ITEM]: (state, action) => {
    return { ...state, rows: [action.data, ...state.rows] }
  },
  [types.TABLE_LOAD]: (state, action) => ({
    ...state,
    rows: action.data.rows || [],
    filters: action.data.filters || {},
    total: action.data.total,
    ranking: action.data.ranking || false,
    type: action.data.type,
  }),
  [types.TABLE_REMOVE_ITEM]: (state, action) => ({
    ...state,
    rows: state.rows.filter((item) => item.id !== action.id),
  }),
  [types.TABLE_DELETE_FILTERS]: (state, { resetTable }) => {
    if (resetTable) {
      return { ...initialState.table.data }
    } else {
      return state
    }
  },
  [types.TABLE_REMOVE_ITEMS]: (state, { ids }) => ({
    ...state,
    rows: state.rows.filter((x) => !ids.includes(x.id)),
  }),
  [types.TABLE_RESET]: () => ({ ...initialState.table.data }),
  [types.TABLE_SELECT_ALL]: (state) => ({
    ...state,
    selected: state.rows.map((x) => x.id),
  }),
  [types.TABLE_SELECT_ITEM]: (state, action) => {
    const { id, multiSelect } = action
    let selected = [...state.selected, id]
    if (!multiSelect) {
      selected = [id]
    }
    // const selectedSorted = state.rows
    //   .filter(item => selected.includes(item.id))
    //   .map(item => item.id)
    return { ...state, selected }
  },
  [types.TABLE_SET_INITDATA]: () => initialState.table.data,
  [types.TABLE_SET_SELECTED]: (state, action) => ({
    ...state,
    selected: action.selected,
  }),
  [types.TABLE_SET_TYPE]: () => initialState.table.data,
  [types.TABLE_SORT_SELECTED]: (state) => {
    const dataWithoutSelected = state.rows.filter(
      (item) => !state.selected.includes(item.id)
    )
    const dataSelected = state.rows.filter((item) =>
      state.selected.includes(item.id)
    )
    return { ...state, rows: [...dataSelected, ...dataWithoutSelected] }
  },
})

const infoReducer = createReducer(initialState.table.info, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({ ...initialState.table.info }),
  [types.TABLE_CLEAR_FILTERS]: (state, { value }) => ({
    ...state,
    clearFilters: value,
  }),
  [types.TABLE_RESTORE_COPY]: (state, { tree }) => {
    const copy = deepmerge({}, tree.info)
    return copy
  },
  [types.TABLE_INSERT_ITEM]: (state, action) => {
    if (state.entityType === action.entityType) {
      return { ...state, scrollTop: 0 }
    }
    return state
  },
  [types.TABLE_RESET]: (state) => {
    const { entityType, order, tab } = state
    return { ...initialState.table.info, entityType, order, tab }
  },
  [types.TABLE_RESET_SEARCH]: (state) => {
    return { ...state, searchText: '', searchKey: state.searchKey + 1 }
  },
  [types.TABLE_SET_FILTER_LIST]: (state, action) => ({
    ...state,
    filterList: action.filterList,
  }),
  [types.TABLE_SET_LOADING]: (state, action) => ({
    ...state,
    loading: action.loading,
    firstload: !state.firstload ? !action.loading : state.firstload,
  }),
  [types.TABLE_SET_MATCH_INFO]: (state, action) => ({
    ...state,
    matchId: action.id,
    matchName: action.name,
  }),
  [types.TABLE_SET_ORDER]: (state, action) => ({
    ...state,
    order: action.value,
  }),
  [types.TABLE_SET_PREVLOCATION]: (state, action) => ({
    ...state,
    prevLocation: action.prevLocation,
  }),
  [types.TABLE_SET_SAVED_FILTER]: (state, action) => ({
    ...state,
    filter: action.id,
  }),
  [types.TABLE_SET_SAVED_FILTER_NONE]: (state) => ({
    ...state,
    filter: 'none',
  }),
  [types.TABLE_SET_SCROLLTOP]: (state, action) => ({
    ...state,
    scrollTop: action.scrollTop,
  }),
  [types.TABLE_SET_SEARCH_FIELD]: (state, { field }) => ({
    ...state,
    searchField: field,
  }),
  [types.TABLE_SET_SEARCH_TEXT]: (state, action) => ({
    ...state,
    searchText: action.searchText,
  }),
  [types.TABLE_SET_FILTER_SEARCH]: (state, { value }) => ({
    ...state,
    filterSearch: value,
  }),
  [types.TABLE_SET_SKIP]: (state, action) => ({
    ...state,
    skip: action.value,
  }),
  [types.TABLE_SET_TAB]: (state, { tab }) => ({
    ...state,
    tab,
  }),
  [types.TABLE_SET_TYPE]: (state, action) => ({
    ...initialState.table.info,
    //prevLocation: state.prevLocation,
    entityType: action.entityType,
    order: action.entityType === state.entityType ? state.order : 'default',
    tab: '',
  }),
})

const insightsReducer = createReducer(initialState.table.insights, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.table.insights,
  [types.TABLE_SET_INSIGHTS]: (state, { data }) => ({
    ...state,
    ...data,
    error: data.status !== 'ok',
  }),
})

const matchingReducer = createReducer(initialState.table.matching, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.table.matching,
  [types.TABLE_GET_MATCHING_SUCCEED]: (state, { data }) => ({
    ...state,
    data,
  }),
  [types.TABLE_SET_MATCHING_LOADED]: (state, { value }) => ({
    ...state,
    loaded: value,
  }),
})

export default combineReducers({
  copy: copyReducer,
  dashboardFilter: dashboardFilterReducer,
  data: dataReducer,
  filters: filtersReducer,
  info: infoReducer,
  insights: insightsReducer,
  matching: matchingReducer,
})
