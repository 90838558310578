import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagLatvia = (props) => (
  <SvgIcon viewBox="0 0 513 342" {...props}>
    <path fill="#FFF" d="M0 0h513v342H0z" />
    <path
      className="prefix__st1"
      d="M0 0h513v127.6H0zm0 214.4h513V342H0z"
      fill="#A2001D"
    />
  </SvgIcon>
)

export default React.memo(FlagLatvia)
