import { call, delay, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { confirmSaga, globalError } from './shell'
import {
  getFunnel,
  loadCustomers,
  removeCustomerSucceed,
  showConfetti,
  timelineLoadSucceed,
} from 'Actions/funnel'
import { t } from 'Root/app/IntlProvider'

export function* addCustomerSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.post(`/funnel/customers/${id}`)
  if (ok) {
    yield put(getFunnel())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getFunnelSaga(action) {
  if (action && action.entity && !action.open && action.entity !== 'relation') {
    return
  }
  const state = yield select()
  const assignedTo = state.funnel.assignedTo
  const { ok, error, data } = yield http.get(`/funnel/${assignedTo}`)
  if (ok) {
    yield put(loadCustomers(data))
    // ok
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* moveCustomerSaga(action) {
  const state = yield select()
  const { customerId, fromStatus, toStatus } = action
  if (fromStatus === toStatus) {
    return
  }
  const { ok, error, data } = yield http.put(
    `/funnel/customers/${customerId}`,
    { fromStatus, toStatus }
  )
  if (ok) {
    if (
      action.toStatus === 'customer' &&
      action.fromStatus !== action.toStatus
    ) {
      if (!state.funnel.confetti) {
        yield put(showConfetti(true))
      }
      yield delay(10000)
      yield put(showConfetti(false))
    }
    // ok
  } else if (error) {
    yield call(globalError, data)
  }
}
export function* removeCustomerSaga(action) {
  const { customerId, name } = action
  const ok = yield call(confirmSaga, {
    title: t('funnel.message.removeCustomer.title'),
    content: t('funnel.message.removeCustomer.content', { name }),
  })
  if (!ok) {
    return
  }
  yield put(removeCustomerSucceed(customerId))
  const { noContent, error, data } = yield http.delete(
    `/funnel/customers/${customerId}`
  )
  if (noContent) {
    // ok
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* timelineLoadSaga(action) {
  const { customerId } = action
  yield put(timelineLoadSucceed([]))
  const { ok, error, data } = yield http.get(
    `/funnel/customers/${customerId}/timeline`
  )
  if (ok) {
    yield put(timelineLoadSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}
