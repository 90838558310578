import * as types from './types'
import { createAction } from 'Utils/redux'

export const activate = createAction(types.AUTH_ACTIVATE, 'payload')
export const activateInfo = createAction(types.AUTH_ACTIVATE_INFO, 'token')
export const activateInfoSucceed = createAction(
  types.AUTH_ACTIVATE_INFO_SUCCEED,
  'data'
)
export const activateSetResend = createAction(
  types.AUTH_ACTIVATE_SET_RESEND,
  'value'
)
export const cancelChangeEmail = createAction(
  types.AUTH_CANCEL_CHANGE_EMAIL,
  'account'
)
export const cancelChangeEmailSucceed = createAction(
  types.AUTH_CANCEL_CHANGE_EMAIL_SUCCEED,
  'account'
)
export const changeCompany = createAction(types.AUTH_CHANGE_COMPANY, 'id')
export const changeCompanySucceed = createAction(
  types.AUTH_CHANGE_COMPANY_SUCCEED,
  'id'
)
export const changeEmail = createAction(types.AUTH_CHANGE_EMAIL, 'payload')
export const changePassword = createAction(
  types.AUTH_CHANGE_PASSWORD,
  'payload'
)
export const checkEmail = createAction(types.AUTH_CHECK_EMAIL, 'payload')
export const checkPassword = createAction(types.AUTH_CHECK_PASSWORD, 'payload')
export const connectNylas = createAction(types.AUTH_CONNECT_NYLAS, 'payload')
export const forceRefresh = createAction(types.AUTH_FORCE_REFRESH)
export const getAccounts = createAction(types.AUTH_GET_ACCOUNTS)
export const getAccountsSucceed = createAction(
  types.AUTH_GET_ACCOUNTS_SUCCEED,
  'data'
)
export const getAccountStatus = createAction(
  types.AUTH_GET_ACCOUNT_STATUS,
  'accountId'
)
export const getAccountStatusSucceed = createAction(
  types.AUTH_GET_ACCOUNT_STATUS_SUCCEED,
  'data'
)
export const getPartner = createAction(types.AUTH_GET_PARTNER, 'id')
export const getQrCode = createAction(types.AUTH_GET_QRCODE, 'password')
export const getSessions = createAction(types.AUTH_GET_SESSIONS)
export const getSubscription = createAction(types.AUTH_GET_SUBSCRIPTION)
export const initializeSystem = createAction(
  types.AUTH_INITIALIZE_SYSTEM,
  'payload'
)
export const initializeSystemSucceed = createAction(
  types.AUTH_INITIALIZE_SYSTEM_SUCCEED
)
export const initSession = createAction(types.AUTH_SESSION_INIT)
export const initSessionSucceed = createAction(types.AUTH_SESSION, 'data')
export const previewMode = createAction(types.AUTH_PREVIEW_MODE)
export const previewModeSucceed = createAction(
  types.AUTH_PREVIEW_MODE_SUCCEED,
  'value'
)
export const putSubscription = createAction(
  types.AUTH_PUT_SUBSCRIPTION,
  'payload'
)
export const registerNylas = createAction(
  types.AUTH_REGISTER_NYLAS,
  'code',
  'state'
)
export const registerNylasBusy = createAction(
  types.AUTH_REGISTER_NYLAS_BUSY,
  'value'
)
export const removeAccount = createAction(
  types.AUTH_REMOVE_ACCOUNT,
  'accountId',
  'email'
)
export const removeEmail2 = createAction(types.AUTH_REMOVE_EMAIL2, 'email')
export const resetPassword = createAction(types.AUTH_RESET_PASSWORD, 'payload')
export const sendRecoveryCode = createAction(
  types.AUTH_SEND_RECOVERY_CODE,
  'payload'
)
export const sendPasswordLink = createAction(types.AUTH_PASSWORDLINK, 'payload')
export const sendVerificationCode = createAction(
  types.AUTH_SEND_VERIFICATION_CODE,
  'token'
)
export const setAuthenticated = createAction(
  types.AUTH_SET_AUTHENTICATED,
  'value'
)
export const setCompanySearch = createAction(
  types.AUTH_SET_COMPANY_SEARCH,
  'value'
)
export const setCompanySetSso = createAction(
  types.AUTH_COMPANY_SET_SSO,
  'payload'
)
export const setEmail = createAction(types.AUTH_SET_EMAIL, 'email')
export const setNewEmail = createAction(
  types.AUTH_SET_NEW_EMAIL,
  'email',
  'account'
)
export const setPartner = createAction(types.AUTH_SET_PARTNER, 'data')
export const setQrCode = createAction(types.AUTH_SET_QRCODE, 'data')
export const setSessions = createAction(types.AUTH_SET_SESSIONS, 'data')
export const setSso = createAction(types.AUTH_SET_SSO, 'payload')
export const setSubscription = createAction(types.AUTH_SET_SUBSCRIPTION, 'data')
export const setTwoFactorEnabled = createAction(
  types.AUTH_SET_TWOFACTOR_ENABLED,
  'enabled'
)
export const showSso = createAction(types.AUTH_SHOW_SSO)
export const signin = createAction(types.AUTH_SIGNIN, 'payload')
export const signinSucceed = createAction(types.AUTH_SIGNIN_SUCCEED, 'data')
export const signout = createAction(types.AUTH_SIGNOUT, 'skipCall')
export const signoutSucceed = createAction(types.AUTH_SIGNOUT_SUCCEED)
export const signup = createAction(types.AUTH_SIGNUP, 'payload')
export const sourcesLoad = createAction(types.AUTH_SOURCES_LOAD, 'entity')
export const sourcesRemove = createAction(
  types.AUTH_SOURCES_REMOVE,
  'entity',
  'source'
)
export const sourcesRename = createAction(
  types.AUTH_SOURCES_RENAME,
  'entity',
  'from',
  'to'
)
export const sourcesSetData = createAction(
  types.AUTH_SOURCES_SET_DATA,
  'entity',
  'data'
)
export const sourcesSetFilter = createAction(
  types.AUTH_SOURCES_SET_FILTER,
  'value'
)
export const sourcesSetLoading = createAction(
  types.AUTH_SOURCES_SET_LOADING,
  'value'
)
export const ssoSignin = createAction(types.AUTH_SSO_SIGNIN, 'payload')
export const ssoSigninFailed = createAction(
  types.AUTH_SSO_SIGNIN_FAILED,
  'provider',
  'state',
  'error',
  'description'
)
export const ssoSigninSucceed = createAction(
  types.AUTH_SSO_SIGNIN_SUCCEED,
  'provider',
  'code',
  'state'
)
export const stopSubscription = createAction(types.AUTH_STOP_SUBSCRIPTION)
export const tagsLoad = createAction(types.AUTH_TAGS_LOAD, 'entity')
export const tagsRemove = createAction(types.AUTH_TAGS_REMOVE, 'entity', 'tag')
export const tagsRename = createAction(
  types.AUTH_TAGS_RENAME,
  'entity',
  'from',
  'to'
)
export const tagsSetFilter = createAction(types.AUTH_TAGS_SET_FILTER, 'value')
export const tagsSetLoading = createAction(types.AUTH_TAGS_SET_LOADING, 'value')
export const tagsSetData = createAction(
  types.AUTH_TAGS_SET_DATA,
  'entity',
  'data'
)
export const twoFactorEnable = createAction(
  types.AUTH_TWOFACTOR_ENABLE,
  'payload'
)
export const updateAccount = createAction(
  types.AUTH_UPDATE_ACCOUNT,
  'accountId',
  'data'
)
export const updateInfo = createAction(types.AUTH_UPDATE_INFO, 'data')
export const validateEmail = createAction(types.AUTH_VALIDATE_EMAIL, 'token')
export const validateToken = createAction(types.AUTH_VALIDATE_TOKEN)
