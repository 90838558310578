import { call, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { show } from 'redux-modal'
import {
  addMyTasks,
  addTasks,
  getItemSucceed,
  getSharedTasksSucceed,
  loadMyTasks,
  loadTasks,
  setTabValue,
  setSharedUsers,
  sharedTasksChanged,
} from 'Actions/task'
import { getTasks } from 'Actions/entity'
import { getRange } from 'Actions/event'
import { globalError } from './shell'

export function* createSaga(action) {
  const { values } = action
  const { created, error, data } = yield http.post(`/tasks`, values)
  if (created) {
    yield refreshAll()
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* completedSaga(action) {
  const { id, callback, completed } = action
  const { ok, error, data } = yield http.put(`/tasks/${id}/completed`, {
    completed,
  })
  if (ok) {
    yield refreshAll()
    if (callback) {
      yield call(callback())
    }
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getItemSaga(action) {
  const { callback, id, options } = action
  if (!id) {
    yield put(getItemSucceed({}))
    yield put(show('editTask', { callback, isNew: true, ...options }))
  } else {
    const { ok, data } = yield http.get(`/tasks/${id}`)
    if (ok) {
      yield put(getItemSucceed(data))
      yield put(show('editTask', { callback, id, ...options }))
    } else {
      yield call(globalError, data)
    }
  }
}

export function* getSharedTasksSaga() {
  const { ok, data } = yield http.get(`/tasks/shared-tasks`)
  if (ok) {
    yield put(getSharedTasksSucceed(data))
    const state = yield select()
    const users = state.task.users
    const realUsers = (users || []).filter((user) =>
      data.find((x) => x.id === user)
    )
    if (users.join('') !== realUsers.join('')) {
      yield put(sharedTasksChanged(realUsers))
    }
  } else {
    yield call(globalError, data)
  }
}

export function* loadTasksSaga() {
  const state = yield select()
  const users = state.task.users
  if ((users || []).length === 0) {
    yield put(sharedTasksChanged([state.auth.user.id]))
    return
  }
  const tab = state.task.tab
  const { ok, data } = yield http.get(`/tasks?tab=${tab}&users=${users}`)
  if (ok) {
    yield put(addTasks(data))
  } else {
    yield call(globalError, data)
  }
}

export function* loadMyTasksSaga() {
  const { ok, data } = yield http.get('/tasks/my')
  if (ok) {
    yield put(addMyTasks(data))
  } else {
    yield call(globalError, data)
  }
}

function* refreshAll() {
  yield put(loadMyTasks())
  const state = yield select()
  if (state.task.formOpen) {
    yield put(loadTasks())
  }
  if (state.event.info.formOpen) {
    yield put(getRange())
  }
  if (
    state.entity.data.id &&
    state.entity.info.entityType &&
    state.entity.info.entityType !== 'email'
  ) {
    yield put(getTasks(state.entity.data.id))
  }
}

export function* setTabSaga(action) {
  const { tab } = action
  yield put(addTasks([]))
  yield put(setTabValue(tab))
  yield put(loadTasks())
}

export function* sharedTasksChangedSaga(action) {
  const { users } = action
  yield put(setSharedUsers(users))
  yield put(loadTasks())
}

export function* updateSaga(action) {
  const { id, values } = action
  const { ok, error, data } = yield http.put(`/tasks/${id}`, values)
  if (ok) {
    yield refreshAll()
  } else if (error) {
    yield call(globalError, data)
  }
}
