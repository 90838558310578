import * as types from './types'
import { createAction } from 'Utils/redux'

export const getItem = createAction(types.QUICK_GET_ITEM, 'entity', 'id')
export const getItemSucceed = createAction(types.QUICK_GET_ITEM_SUCCEED, 'data')
export const getList = createAction(types.QUICK_GET_LIST, 'tab', 'text')
export const getListSucceed = createAction(types.QUICK_GET_LIST_SUCCEED, 'data')
export const loading = createAction(types.QUICK_LOADING, 'value')
export const pinItem = createAction(types.QUICK_PIN_ITEM, 'entity', 'id')
export const setHighlight = createAction(types.QUICK_SET_HIGHLIGHT, 'text')
export const setSelected = createAction(types.QUICK_SET_SELECTED, 'data')
export const setTab = createAction(types.QUICK_SET_TAB, 'tab')
export const unpinItem = createAction(types.QUICK_UNPIN_ITEM, 'entity', 'id')
