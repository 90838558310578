import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

// https://github.com/lipis/flag-icon-css/tree/master/flags/4x3

const FlagNetherlands = (props) => (
  <SvgIcon viewBox="0 0 640 480" {...props}>
    <g strokeWidth="1pt" transform="scale(1.25 .9375)">
      <rect width="512" height="509.8" fill="#fff" rx="0" ry="0" />
      <rect width="512" height="169.9" y="342.1" fill="#21468b" rx="0" ry="0" />
      <path fill="#ae1c28" d="M0 0h512v170H0z" />
    </g>
  </SvgIcon>
)

export default React.memo(FlagNetherlands)
