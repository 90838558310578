import { call, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { globalError } from './shell'
import { creating, generateSucceed } from 'Actions/pdf'
import { hide } from 'redux-modal'
import { showMessage } from 'Actions/shell'
import { messageLevel } from 'Utils/constants'
import { t } from 'Root/app/IntlProvider'

export function* createSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  yield put(creating(true))
  const { created, error, data } = yield http.post(`/pdf`, values)
  if (created) {
    yield call(resolve, data)
  } else if (error) {
    yield call(globalError, data, reject)
  }
  yield put(creating(false))
}

export function* generateSaga(action) {
  const {
    payload: { entity, entityId, ...rest },
  } = action
  yield put(creating(true))
  const { created, error, serviceUnavailable, data } = yield http.post(
    `/${entity}s/${entityId}/generate`,
    rest
  )
  if (created) {
    yield put(generateSucceed(data))
    yield put(hide('generate'))
  } else if (serviceUnavailable) {
    yield put(
      showMessage(
        t('global.service.conversion.notAvailable'),
        messageLevel.warning
      )
    )
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(creating(false))
}
