import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const attachmentsReducer = createReducer(initialState.email.attachments, {
  [types.EMAIL_ADD_ATTACHMENT_SUCCEED]: (state, { values }) => [
    ...state,
    ...values,
  ],
  [types.EMAIL_CLEAR_ATTACHMENTS]: () => initialState.email.attachments,
  [types.EMAIL_DELETE_ATTACHMENT]: (state, { id }) => [
    ...state.filter((x) => x.id !== id),
  ],
  [types.EMAIL_REMOVE_TEMPLATE_ATTACHMENTS]: (state) => [
    ...state.filter((x) => !x.templateId),
  ],
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.email.attachments,
})

const infoReducer = createReducer(initialState.email.info, {
  [types.EMAIL_ADD_CONTEXT]: (state, { value }) => ({
    ...state,
    context: [...state.context, value],
  }),
  [types.EMAIL_DECREMENT_UPLOADING]: (state) => ({
    ...state,
    uploading: state.uploading - 1,
  }),
  [types.EMAIL_INCREMENT_UPLOADING]: (state) => ({
    ...state,
    uploading: state.uploading + 1,
  }),
  [types.EMAIL_REMOVE_CONTEXT]: (state, { value }) => ({
    ...state,
    context: state.context.filter((x) => x !== value),
  }),
  [types.EMAIL_RESET_UPLOADING]: (state) => ({
    ...state,
    uploading: 0,
  }),
})

export default combineReducers({
  attachments: attachmentsReducer,
  info: infoReducer,
})
