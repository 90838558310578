import * as types from './types'
import { createAction } from 'Utils/redux'

export const calendarChangeView = createAction(
  types.EVENT_CALENDAR_CHANGE_VIEW,
  'view'
)
export const calendarSetDate = createAction(
  types.EVENT_CALENDAR_SET_DATE,
  'date'
)
export const clearNewItem = createAction(types.EVENT_CLEAR_NEW_ITEM)
export const create = createAction(types.EVENT_CREATE, 'payload')
export const createSucceed = createAction(
  types.EVENT_CREATE_SUCCEED,
  'id',
  'values'
)
export const downloadIcs = createAction(types.EVENT_DOWNLOAD_ICS, 'payload')
export const getItem = createAction(types.EVENT_GET_ITEM, 'payload')
export const getItemRange = createAction(
  types.EVENT_GET_ITEM_RANGE,
  'startAt',
  'endAt',
  'users',
  'id'
)
export const getItemRangeSucceed = createAction(
  types.EVENT_GET_ITEM_RANGE_SUCCEED,
  'data',
  'id'
)
export const getItemSucceed = createAction(types.EVENT_GET_ITEM_SUCCEED, 'data')
export const getLocations = createAction(types.EVENT_GET_LOCATIONS, 'payload')
export const getLocationsSucceed = createAction(
  types.EVENT_GET_LOCATIONS_SUCCEED,
  'data'
)
export const getRange = createAction(
  types.EVENT_GET_RANGE,
  'startAt',
  'endAt',
  'users'
)
export const getRangeSucceed = createAction(
  types.EVENT_GET_RANGE_SUCCEED,
  'data',
  'startAt',
  'endAt'
)
export const getSharedAgendas = createAction(types.EVENT_GET_SHARED_AGENDAS)
export const getSharedAgendasSucceed = createAction(
  types.EVENT_GET_SHARED_AGENDAS_SUCCEED,
  'users'
)
export const rangeAdd = createAction(types.EVENT_RANGE_ADD, 'values')
export const remove = createAction(types.EVENT_REMOVE, 'id')
export const removeRecurrency = createAction(
  types.EVENT_REMOVE_RECURRENCY,
  'id'
)
export const removeSucceed = createAction(types.EVENT_REMOVE_SUCCEED, 'id')
export const saveNew = createAction(types.EVENT_SAVE_NEW, 'resolve')
export const setFilter = createAction(types.EVENT_SET_FILTER, 'filter')
export const saveFinished = createAction(types.EVENT_SAVE_FINISHED)
export const setFormOpen = createAction(types.EVENT_SET_FORM_OPEN, 'value')
export const setItem = createAction(types.EVENT_SET_ITEM, 'id', 'values')
export const setNewItem = createAction(
  types.EVENT_SET_NEW_ITEM,
  'values',
  'callback'
)
export const setSharedUsers = createAction(
  types.EVENT_SET_SHARED_USERS,
  'users'
)
export const sharedAgendasChanged = createAction(
  types.EVENT_SHARED_AGENDAS_CHANGED,
  'users'
)
export const toggleShowUsers = createAction(types.EVENT_TOGGLE_SHOW_USERS)
export const update = createAction(types.EVENT_UPDATE, 'payload')
export const updateItem = createAction(types.EVENT_UPDATE_ITEM, 'values')
export const updateSucceed = createAction(types.EVENT_UPDATE_SUCCEED, 'values')
