import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagFinland = (props) => (
  <SvgIcon viewBox="0 0 512 341.3" {...props}>
    <path fill="#FFF" d="M0 0h512v341.3H0z" />
    <path
      fill="#2E52B2"
      d="M512 129.3V212H203.7v129.3H121V212H0v-82.7h121V0h82.7v129.3z"
    />
  </SvgIcon>
)

export default React.memo(FlagFinland)
