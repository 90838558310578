import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagPakistan = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <g fill="#FFF">
      <path d="M393.508 170.516l17.512 18.875 23.363-10.821-12.541 22.487 17.513 18.876-25.263-4.978-12.539 22.488-3.073-25.564-25.263-4.978 23.363-10.82z" />
      <path d="M0 85.343h512v341.326H0z" />
    </g>
    <path fill="#01411c" d="M128 85.331h384v341.337H128z" />
    <path
      d="M361.909 298.793c-31.037 22.426-74.378 15.446-96.804-15.592-22.427-31.038-15.446-74.379 15.593-96.804 9.677-6.992 20.55-11.125 31.613-12.563-21.283-3.183-43.777 1.613-62.598 15.211-38.2 27.602-46.792 80.944-19.191 119.145 27.601 38.199 80.944 46.792 119.145 19.189 18.82-13.598 30.436-33.448 34.096-54.655-4.839 10.05-12.176 19.076-21.854 26.069zm-1.329-125.904l17.484 18.842 23.322-10.802-12.519 22.447 17.483 18.844-25.219-4.968-12.519 22.45-3.067-25.521-25.22-4.969 23.323-10.802z"
      fill="#FFF"
    />
  </SvgIcon>
)

export default React.memo(FlagPakistan)
