import * as types from 'Actions/types'
import { createAction } from 'Utils/redux'

export const addNote = createAction(
  types.ENTITY_ADD_NOTE,
  'id',
  'content',
  'ref',
  'noteType',
  'rating',
  'secured',
  'backUrl',
  'replyId'
)
export const addTag = createAction(types.ENTITY_ADD_TAG_INIT, 'tag')
export const addTagSucceed = createAction(types.ENTITY_ADD_TAG, 'tag')
export const anonymizeItem = createAction(
  types.ENTITY_ANONIMYZE_ITEM,
  'id',
  'confirm'
)
export const changeCompanyId = createAction(
  types.ENTITY_CHANGE_COMPANYID,
  'companyId'
)
export const create = createAction(types.ENTITY_CREATE, 'payload')
export const created = createAction(types.ENTITY_CREATED, 'entity', 'open')
export const deleteCertificate = createAction(
  types.ENTITY_DELETE_CERTIFICATE,
  'id'
)
export const deleteItem = createAction(
  types.ENTITY_DELETE_ITEM,
  'id',
  'confirm',
  'anonymize'
)
export const deleteNote = createAction(types.ENTITY_DELETE_NOTE, 'id', 'noteId')
export const deleteUnsavedNote = createAction(
  types.ENTITY_DELETE_UNSAVED_NOTE,
  'entity',
  'id'
)
export const deselectItems = createAction(types.ENTITY_DESELECT_ITEMS)
export const downloadNoteAttachment = createAction(
  types.ENTITY_DOWNLOAD_NOTE_ATTACHMENT,
  'id',
  'noteId'
)
export const expandNote = createAction(
  types.ENTITY_EXPAND_NOTE,
  'id',
  'noteId',
  'value'
)
export const get = createAction(types.ENTITY_GET, 'id')
export const getActivities = createAction(
  types.ENTITY_GET_ACTIVITIES,
  'id',
  'more'
)
export const getActivitiesSucceed = createAction(
  types.ENTITY_GET_ACTIVITIES_SUCCEED,
  'id',
  'data'
)
export const getAttachments = createAction(
  types.ENTITY_GET_ATTACHMENTS_INIT,
  'id'
)
export const getAttachmentsSucceed = createAction(
  types.ENTITY_GET_ATTACHMENTS,
  'id',
  'data'
)
export const getCertificateUrl = createAction(
  types.ENTITY_GET_CERTIFICATE_URL,
  'id'
)
export const getCertificateUrlSucceed = createAction(
  types.ENTITY_GET_CERTIFICATE_URL_SUCCEED,
  'id',
  'data'
)
export const getCertificates = createAction(types.ENTITY_GET_CERTIFICATES, 'id')
export const getCertificatesSucceed = createAction(
  types.ENTITY_GET_CERTIFICATES_SUCCEED,
  'id',
  'data'
)
export const getEmails = createAction(types.ENTITY_GET_EMAILS, 'id')
export const getEmailsSucceed = createAction(
  types.ENTITY_GET_EMAILS_SUCCEED,
  'id',
  'data'
)
export const getEvents = createAction(types.ENTITY_GET_EVENTS, 'id', 'limit')
export const getEventsSucceed = createAction(
  types.ENTITY_GET_EVENTS_SUCCEED,
  'data',
  'limit'
)
export const getFiles = createAction(types.ENTITY_GET_FILES, 'entity', 'id')
export const getInsightsUrl = createAction(
  types.ENTITY_GET_INSIGHTS_URL,
  'id',
  'tab'
)
export const getLastNote = createAction(types.ENTITY_GET_LAST_NOTE_INIT, 'id')
export const getLastNoteSucceed = createAction(
  types.ENTITY_GET_LAST_NOTE,
  'id',
  'data'
)
export const getNotes = createAction(
  types.ENTITY_GET_NOTES_INIT,
  'id',
  'openFirst',
  'expandId'
)
export const getNotesSucceed = createAction(
  types.ENTITY_GET_NOTES,
  'id',
  'data',
  'openFirst'
)
export const getNotesUsers = createAction(types.ENTITY_GET_NOTES_USERS, 'id')
export const getNotesUsersSucceed = createAction(
  types.ENTITY_GET_NOTES_USERS_SUCCEED,
  'data'
)
export const getSucceed = createAction(types.ENTITY_GET_SUCCEED, 'id', 'entity')
export const getTasks = createAction(types.ENTITY_GET_TASKS, 'id')
export const getTasksSucceed = createAction(
  types.ENTITY_GET_TASKS_SUCCEED,
  'data'
)
export const getUnsavedNote = createAction(
  types.ENTITY_GET_UNSAVED_NOTE,
  'entity',
  'id'
)
export const mark = createAction(types.ENTITY_MARK, 'id')
export const noteGetViewers = createAction(types.ENTITY_NOTE_GET_VIEWERS, 'ref')
export const noteSetViewers = createAction(
  types.ENTITY_NOTE_SET_VIEWERS,
  'viewers'
)
export const notesCollapseAll = createAction(types.ENTITY_NOTES_COLLAPSE_ALL)
export const notesExpandAll = createAction(types.ENTITY_NOTES_EXPAND_ALL)
export const notesSetBackUrl = createAction(
  types.ENTITY_NOTES_SET_BACK_URL,
  'url'
)
export const notesSetOrder = createAction(types.ENTITY_NOTES_SET_ORDER, 'order')
export const put = createAction(types.ENTITY_PUT, 'id', 'values')
export const removeTag = createAction(types.ENTITY_REMOVE_TAG_INIT, 'tag')
export const removeTagSucceed = createAction(types.ENTITY_REMOVE_TAG, 'tag')
export const refresh = createAction(types.ENTITY_REFRESH)
export const restore = createAction(types.ENTITY_RESTORE, 'id')
export const resetData = createAction(types.ENTITY_RESET_DATA)
export const saveUnsavedNote = createAction(
  types.ENTITY_SAVE_UNSAVED_NOTE,
  'entity',
  'id',
  'values'
)
export const selectCertificate = createAction(
  types.ENTITY_SELECT_CERTIFICATE,
  'id'
)
export const setActivitiesFilter = createAction(
  types.ENTITY_SET_ACTIVITIES_FILTER,
  'filter'
)
export const setActivityLoading = createAction(
  types.ENTITY_ACTIVITY_SET_LOADING,
  'value'
)

export const setContentOverflowX = createAction(
  types.ENTITY_SET_CONTENTOVERFLOWX,
  'value'
)
export const setContentOverflowY = createAction(
  types.ENTITY_SET_CONTENTOVERFLOWY,
  'value'
)
export const setData = createAction(types.ENTITY_SET_DATA, 'data')
export const setDataItem = createAction(types.ENTITY_SET_DATA_ITEM, 'data')
export const setEditMode = createAction(types.ENTITY_SET_EDITMODE, 'editMode')
export const setEditForm = createAction(types.ENTITY_SET_EDITFORM, 'editForm')
export const setEmailsFilter = createAction(
  types.ENTITY_SET_EMAILS_FILTER,
  'filter'
)
export const setEmailsFilterCandidate = createAction(
  types.ENTITY_SET_EMAILS_FILTER_CANDIDATE,
  'filter'
)
export const setEmailsLoaded = createAction(
  types.ENTITY_SET_EMAILS_LOADED,
  'value'
)
export const setFilesLoading = createAction(
  types.ENTITY_SET_FILES_LOADING,
  'loading'
)
export const setHasNotes = createAction(types.ENTITY_SET_HASNOTES, 'value')
export const setId = createAction(types.ENTITY_SET_ID, 'id')
export const setInsights = createAction(types.ENTITY_SET_INSIGHTS, 'data')
export const setLoading = createAction(types.ENTITY_SET_LOADING, 'loading')
export const setNoteSecured = createAction(
  types.ENTITY_SET_NOTE_SECURED,
  'id',
  'noteId',
  'value'
)
export const setNoteSecuredSucceed = createAction(
  types.ENTITY_SET_NOTE_SECURED_SUCCEED,
  'id',
  'noteId',
  'value'
)
export const setNotesFilter = createAction(
  types.ENTITY_SET_NOTES_FILTER,
  'filter'
)
export const setNotesFilterType = createAction(
  types.ENTITY_SET_NOTES_FILTER_TYPE,
  'filter'
)
export const setNoteViewers = createAction(
  types.ENTITY_SET_NOTE_VIEWERS,
  'viewers'
)
export const setNotFound = createAction(types.ENTITY_SET_NOT_FOUND, 'value')
export const setPartialData = createAction(
  types.ENTITY_SET_PARTIAL_DATA,
  'id',
  'data'
)

export const setPrevLocation = createAction(
  types.ENTITY_SET_PREVLOCATION,
  'prevLocation'
)
export const setSelection = createAction(
  types.ENTITY_SET_SELECTION,
  'selection'
)
export const setSaving = createAction(types.ENTITY_SET_SAVING, 'saving')
export const setTabCaption = createAction(
  types.ENTITY_SET_TAB_CAPTION,
  'tabCaption'
)
export const setTabCount = createAction(types.ENTITY_SET_TAB_COUNT, 'tabCount')
export const setType = createAction(types.ENTITY_SET_TYPE, 'entityType')
export const setUnsavedNote = createAction(
  types.ENTITY_SET_UNSAVED_NOTE,
  'values'
)
export const showConfetti = createAction(types.ENTITY_SHOW_CONFETTI, 'value')
export const unMark = createAction(types.ENTITY_UNMARK, 'id')
export const updateCertificate = createAction(
  types.ENTITY_UPDATE_CERTIFICATE,
  'id',
  'values'
)
export const updateData = createAction(types.ENTITY_UPDATE_DATA, 'data')
export const updateNote = createAction(
  types.ENTITY_UPDATE_NOTE,
  'id',
  'noteId',
  'content',
  'rating'
)
export const updateNoteType = createAction(
  types.ENTITY_UPDATE_NOTE_TYPE,
  'id',
  'noteId',
  'noteType'
)
export const uploadNoteAttachment = createAction(
  types.ENTITY_UPLOAD_NOTE_ATTACHMENT,
  'id',
  'noteId',
  'file',
  'ref',
  'secured'
)
export const uploadNoteAttachmentEnd = createAction(
  types.ENTITY_UPLOAD_NOTE_ATTACHMENT_END,
  'noteId'
)
export const uploadNoteAttachmentStart = createAction(
  types.ENTITY_UPLOAD_NOTE_ATTACHMENT_START,
  'noteId'
)
export const uploadNoteAttachmentSucceed = createAction(
  types.ENTITY_UPLOAD_NOTE_ATTACHMENT_SUCCEED,
  'noteId',
  'data'
)
