import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const CalendarClock = (props) => (
  <SvgIcon {...props}>
    <path d="M15 13h1.5v2.82l2.44 1.41-.75 1.3L15 16.69V13m4-5H5v11h4.67c-.43-.91-.67-1.93-.67-3a7 7 0 017-7c1.07 0 2.09.24 3 .67V8M5 21a2 2 0 01-2-2V5c0-1.11.89-2 2-2h1V1h2v2h8V1h2v2h1a2 2 0 012 2v6.1c1.24 1.26 2 2.99 2 4.9a7 7 0 01-7 7c-1.91 0-3.64-.76-4.9-2H5m11-9.85A4.85 4.85 0 0011.15 16c0 2.68 2.17 4.85 4.85 4.85A4.85 4.85 0 0020.85 16c0-2.68-2.17-4.85-4.85-4.85z" />
  </SvgIcon>
)

export default React.memo(CalendarClock)
