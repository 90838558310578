import { all, call, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { getEntity } from 'Root/app/IntlProvider'
import { globalError } from './shell'
import { searchTextSucceed, setNoResult } from 'Actions/search'

export function* searchTextSaga(action) {
  const { payload } = action
  const { entity, field, filters, order, tabs = [], text } = payload
  const plural = yield call(getEntity, entity, true)
  const calls = []
  const criteria = {
    text,
    field,
    filters,
    order,
    ranking: false,
    skip: 0,
  }
  calls.push(http.post(`/${plural}/search`, criteria))
  const restTabs = (tabs || [])
    .filter((x) => x.entity !== entity)
    .map((x) => x.entity)
  for (const tab of restTabs) {
    const plural = yield call(getEntity, tab, true)
    calls.push(http.post(`/${plural}/search`, criteria))
  }
  const [{ ok, data, error }, ...rest] = yield all(calls)
  if (ok) {
    const totals = {
      [entity]: {
        number: data.rows.length,
        email: data.rows.filter((x) => x.email === text).length,
      },
    }
    for (const [i, tab] of restTabs.entries()) {
      totals[tab] = {
        number: rest[i].data.rows.length,
        email: rest[i].data.rows.filter((x) => x.email === text).length,
      }
    }
    yield put(searchTextSucceed(data.rows, totals, text))
    if (data.rows.length === 0) {
      yield put(setNoResult(true))
    }
  } else if (error) {
    yield call(globalError, data)
  }
}
