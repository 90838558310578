import React, { Component } from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'
import { reduxForm, Field, Form } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'
import complexity from 'complexity'
import GridContainer from 'Components/common/GridContainer'
import GridItem from 'Components/common/GridItem'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { activate, activateInfo } from 'Actions/auth'
import Base from 'Components/shell/PublicBase'
import RefreshIcon from '@material-ui/icons/Refresh'
import TextField, { passwordOptions } from 'Components/common/form/TextField'
import PinCodeField from 'Components/common/form/PinCodeField'
import withTranslate from 'Root/app/withTranslate'

const styles = (theme) => ({
  divider: {
    marginBottom: 10,
    marginTop: 10,
  },
  link: {
    cursor: 'pointer',
  },
  marginTop: {
    marginTop: 20,
  },
  pincode: {
    paddingTop: 15,
  },
  resend: {
    marginTop: 15,
  },
  rightButton: {
    marginLeft: theme.spacing(0),
  },
  submit: {
    marginTop: 20,
  },
})

const validate = ({ password }, { t }) => {
  const errors = {}
  if (!password) {
    errors.password = t('auth.activate.error.password.required')
  } else if (!complexity.check(password, passwordOptions)) {
    errors.password = t('auth.activate.error.password.invalid')
  }
  return errors
}

class Activate extends Component {
  state = {
    code: '',
    token: this.props.match.params.token,
  }

  componentDidMount() {
    this.props.activateInfo(this.state.token)
  }

  onSubmit = (data) => {
    const { code, token } = this.state
    const values = { ...data, code, token }
    return new Promise((resolve, reject) => {
      this.props.activate({
        values,
        resolve,
        reject,
      })
    })
  }

  handlePinCodeChange = (code) => {
    this.setState({ code })
  }

  render() {
    const {
      classes,
      handleSubmit,
      invalid,
      phone,
      pristine,
      resend,
      submitting,
      t,
    } = this.props
    return (
      <Base title={t('auth.activate.title')}>
        <Form
          noValidate
          onSubmit={handleSubmit(this.onSubmit)}
          autoComplete="off"
        >
          <GridContainer direction="column">
            <GridItem>
              <Typography component="p">
                <FormattedMessage id="auth.activate.info.password" />
              </Typography>
            </GridItem>
            <GridItem errorHeight className={classes.marginTop}>
              <Field
                name="password"
                required
                password
                passwordStrength
                component={TextField}
                placeholder={t('auth.activate.password.placeholder')}
                label={t('auth.activate.password.label')}
                autoFocus
              />
            </GridItem>
            <GridItem errorHeight className={classes.marginTop}>
              <Typography>
                <FormattedMessage
                  id="auth.activate.info.code"
                  values={{
                    phone: <strong>{phone}</strong>,
                  }}
                />
              </Typography>
            </GridItem>
            <GridItem>
              <GridContainer
                justifyContent="center"
                className={classes.pincode}
              >
                <PinCodeField onChange={this.handlePinCodeChange} />
              </GridContainer>
            </GridItem>
            {/* {resend && (
              <GridItem className={classes.resend}>
                <Typography variant="body2">
                  <a onClick={this.handleShowMore} className={classes.link}>
                    <FormattedMessage id="auth.activate.resend.click" />
                  </a>{' '}
                  <FormattedMessage id="auth.activate.resend.info" />
                </Typography>
              </GridItem>
            )} */}
            <GridItem>
              <GridContainer justifyContent="center">
                <Button
                  variant="contained"
                  className={classes.submit}
                  type="submit"
                  color="primary"
                  disabled={
                    pristine ||
                    invalid ||
                    submitting ||
                    this.state.code.length < 4
                  }
                >
                  <FormattedMessage id="auth.activate.button.submit" />
                  {submitting && (
                    <RefreshIcon
                      className={clsx('wn-spin', classes.rightButton)}
                    />
                  )}
                </Button>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Form>
      </Base>
    )
  }
}

export default compose(
  withTranslate,
  connect(
    (state) => ({
      phone: state.auth.activate.phone,
      resend: state.auth.activate.resend,
    }),
    { activate, activateInfo }
  ),
  withStyles(styles),
  reduxForm({
    form: 'activate',
    validate,
  })
)(Activate)
