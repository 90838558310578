import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const SortNumericAscending = (props) => (
  <SvgIcon {...props}>
    <path d="M19 17h3l-4 4-4-4h3V3h2v14M9 13H7c-1.1 0-2 .9-2 2v1a2 2 0 002 2h2v1H5v2h4c1.11 0 2-.89 2-2v-4a2 2 0 00-2-2m0 3H7v-1h2v1M9 3H7c-1.1 0-2 .9-2 2v4a2 2 0 002 2h2c1.11 0 2-.89 2-2V5a2 2 0 00-2-2m0 6H7V5h2v4z" />
  </SvgIcon>
)

export default React.memo(SortNumericAscending)
