import { call, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { addExports, setLoading } from 'Actions/export'
import { globalError } from './shell'

export function* loadExportsSaga() {
  const { ok, data } = yield http.get(`/exports`)
  yield put(setLoading(true))
  if (ok) {
    yield put(addExports(data))
  } else {
    yield call(globalError, data)
  }
  yield put(setLoading(false))
}
