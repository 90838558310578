import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ContentCopy = (props) => (
  <SvgIcon {...props}>
    <path d="M19 21H8V7h11m0-2H8a2 2 0 00-2 2v14a2 2 0 002 2h11a2 2 0 002-2V7a2 2 0 00-2-2m-3-4H4a2 2 0 00-2 2v14h2V3h12V1z" />
  </SvgIcon>
)

export default React.memo(ContentCopy)
