import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const infoReducer = createReducer(initialState.automation.info, {
  [types.AUTOMATION_SET_LOADING]: (state, { value }) => ({
    ...state,
    loading: value,
  }),
})

const listReducer = createReducer(initialState.automation.list, {
  [types.AUTOMATION_GET_LIST_SUCCEED]: (state, action) => action.data,
})

const logReducer = createReducer(initialState.automation.log, {
  [types.AUTOMATION_GET_LOG_SUCCEED]: (state, action) => action.data,
})

export default combineReducers({
  info: infoReducer,
  list: listReducer,
  log: logReducer,
})
