import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const infoReducer = createReducer(initialState.import.info, {
  [types.LOOKUP_IMPORT_EXCEL_UPLOADING]: (state, action) => ({
    ...state,
    busy: action.value,
  }),
})

export default combineReducers({
  info: infoReducer,
})
