import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const iframeReducer = createReducer(initialState.iframe, {
  [types.IFRAME_RELOAD_FRAME]: (state) => ({
    ...state,
    loading: true,
    value: state.value + 1,
  }),
  [types.IFRAME_SET_LOADING]: (state, { value }) => ({
    ...state,
    loading: value,
  }),
  [types.IFRAME_SET_VISIBLE]: (state, { value }) => ({
    ...state,
    visible: value,
  }),
})

export default iframeReducer
