import React from 'react'
import { DateTimePicker } from '@material-ui/pickers'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import withTranslate from 'Root/app/withTranslate'

const styles = () => ({
  input: {
    paddingTop: 3,
  },
  root: {
    height: 29,
  },
})

const DateTimeField = ({
  classes,
  input,
  label,
  meta,
  t,
  getEntityCaption,
  ...other
}) => {
  const { touched = true, error, active } = meta || {}
  const { value, onChange, name } = input

  return (
    <FormControl error={touched && !!error}>
      <DateTimePicker
        name={name}
        autoOk
        label={label}
        InputProps={{ classes: { input: classes.input, root: classes.root } }}
        value={value || null}
        onChange={onChange}
        ampm={false}
        cancelLabel={t('common.form.dateTimeField.cancelLabel')}
        clearLabel={t('common.form.dateTimeField.clearLabel')}
        format="DD-MM-YYYY HH:mm"
        {...other}
      />
      <FormHelperText>{!active && touched && error}</FormHelperText>
    </FormControl>
  )
}

export default compose(withTranslate, withStyles(styles))(DateTimeField)
