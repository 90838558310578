import numeral from 'numeral'

export default (bytes, decimals = 2, small = false) => {
  if (typeof bytes === 'undefined') {
    return ''
  }
  if (bytes === 0) {
    return '0 bytes'
  }
  const k = 1024
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return (
    numeral(parseFloat(bytes / Math.pow(k, i))).format(
      `0,0.[${'0'.repeat(decimals)}]`
    ) + (small ? sizes[i].toLowerCase() : ' ' + sizes[i])
  )
}
