import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const PaylistCheck = (props) => (
  <SvgIcon {...props}>
    <path d="M14 10H2v2h12v-2m0-4H2v2h12V6M2 16h8v-2H2v2m19.5-4.5L23 13l-7 7-4.5-4.5L13 14l3 3 5.5-5.5z" />
  </SvgIcon>
)

export default React.memo(PaylistCheck)
