import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TagOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M21.41 11.58l-9-9A2 2 0 0011 2H4a2 2 0 00-2 2v7a2 2 0 00.59 1.42l9 9A2 2 0 0013 22a2 2 0 001.41-.59l7-7A2 2 0 0022 13a2 2 0 00-.59-1.42M13 20l-9-9V4h7l9 9M6.5 5A1.5 1.5 0 115 6.5 1.5 1.5 0 016.5 5z" />
  </SvgIcon>
)

export default React.memo(TagOutline)
