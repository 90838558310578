import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagVietnam = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <path fill="#D80027" d="M196.641 85.337H0v341.326h512V85.337z" />
    <path
      fill="#FFDA44"
      d="M256 157.279l22.663 69.747H352l-59.332 43.106 22.664 69.749L256 296.774l-59.332 43.107 22.664-69.749L160 227.026h73.337z"
    />
  </SvgIcon>
)

export default React.memo(FlagVietnam)
