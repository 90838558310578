import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const dataReducer = createReducer(initialState.template.data, {
  [types.TEMPLATE_SET_DATA]: (state, { data }) => ({ ...state, ...data }),
  [types.TEMPLATE_ADD_ATTACHMENT_SUCCEED]: (state, { data }) => ({
    ...state,
    attachments: [...state.attachments, `c-${data.id}`],
    uploadedAttachments: [
      ...state.uploadedAttachments,
      { id: data.id, name: data.name },
    ],
  }),
  [types.TEMPLATE_RESET_DATA]: () => initialState.template.data,
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.template.data,
})

const filterReducer = createReducer(initialState.template.filter, {
  [types.TEMPLATE_SET_FILTER]: (_, { filter }) => filter,
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.template.filter,
})

const infoReducer = createReducer(initialState.template.info, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.template.info,
})

const itemReducer = createReducer(initialState.template.item, {
  [types.TEMPLATE_COPY]: (state) => ({
    ...state,
    canCopy: false,
    id: null,
    isCopy: true,
  }),
  [types.TEMPLATE_GET_SUCCEED]: (state, { data }) => data,
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.template.item,
})

const mediaReducer = createReducer(initialState.template.media, {
  [types.TEMPLATE_MEDIA_GET_SUCCEED]: (state, { data }) => ({
    description: data.description,
    file: {
      name: data.name,
      size: data.size,
      type: data.type,
    },
    id: data.id,
    url: data.url,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.template.media,
})

const mediasReducer = createReducer(initialState.template.medias, {
  [types.TEMPLATE_MEDIA_LOAD_SUCCEED]: (state, { data }) => data,
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.template.medias,
})

export default combineReducers({
  data: dataReducer,
  filter: filterReducer,
  info: infoReducer,
  item: itemReducer,
  media: mediaReducer,
  medias: mediasReducer,
})
