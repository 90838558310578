import * as types from './types'
import { createAction } from 'Utils/redux'

export const searchText = createAction(types.SEARCH_SEARCH_TEXT, 'payload')

export const addToList = createAction(types.SEARCH_ADD_TO_LIST, 'item')
export const addToSelected = createAction(types.SEARCH_ADD_TO_SELECTED, 'id')
export const searchTextSucceed = createAction(
  types.SEARCH_SEARCH_TEXT_SUCCEED,
  'data',
  'totals',
  'text'
)
export const setList = createAction(types.SEARCH_SET_LIST, 'items')
export const setNoResult = createAction(types.SEARCH_SET_NO_RESULT, 'value')
export const setSelected = createAction(types.SEARCH_SET_SELECTED, 'selected')
