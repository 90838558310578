import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const applyReducer = createReducer(initialState.apply, {
  [types.APPLY_SET_DATA]: (state, { data }) => {
    return data
  },
})

export default applyReducer
