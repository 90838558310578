import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Star from '@material-ui/icons/Star'
import StarBorder from '@material-ui/icons/StarBorder'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from 'Components/common/Tooltip'
import { useFormatMessage } from 'Root/app/useTranslate'

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: 5,
    marginTop: -2,
  },
  root: {
    display: 'flex',
  },
}))

const Communication = (props) => {
  const { children, name, onClick, readonly, value } = props
  const classes = useStyles()
  const t = useFormatMessage()
  const [selected, setSelected] = useState(false)
  const [hover, setHover] = useState(false)

  useEffect(() => {
    const isSelected = (value || []).includes(name)
    setSelected(isSelected)
  }, [name, value])

  const handleClick = () => {
    const newValue = !selected
    setSelected(newValue)
    onClick(newValue)
  }

  const handleMouseEnter = () => {
    if (readonly || selected) {
      return
    }
    setHover(true)
  }

  const handleMouseLeave = () => {
    if (readonly || selected) {
      return
    }
    setHover(false)
  }

  return (
    <div
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>{children}</div>
      <Tooltip title={t('common.form.communication.tooltip')}>
        <div>
          {selected ? (
            <IconButton
              onClick={handleClick}
              size="small"
              className={classes.button}
            >
              <Star className={classes.selected} />
            </IconButton>
          ) : (
            <>
              {hover && (
                <IconButton
                  onClick={handleClick}
                  size="small"
                  className={classes.button}
                >
                  <StarBorder color="action" />
                </IconButton>
              )}
            </>
          )}
        </div>
      </Tooltip>
    </div>
  )
}

export default Communication
