import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ChartBarH = (props) => (
  <SvgIcon viewBox="0 0 1280 1280" {...props}>
    <path d="M160 640v534h960v-108H267V960h480V746H267V640h693V426H267V320h267V106H160v534z" />
  </SvgIcon>
)

export default React.memo(ChartBarH)
