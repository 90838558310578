import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TableRow = (props) => (
  <SvgIcon {...props}>
    <path d="M22 14a2 2 0 01-2 2H4a2 2 0 01-2-2v-4a2 2 0 012-2h16a2 2 0 012 2v4M4 14h4v-4H4v4m6 0h4v-4h-4v4m6 0h4v-4h-4v4z" />
  </SvgIcon>
)

export default React.memo(TableRow)
