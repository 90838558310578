import * as types from './types'
import { createAction } from 'Utils/redux'

export const activate = createAction(types.TEMPLATE_ACTIVATE, 'payload')
export const addAttachment = createAction(types.TEMPLATE_ADD_ATTACHMENT, 'file')
export const addAttachmentSucceed = createAction(
  types.TEMPLATE_ADD_ATTACHMENT_SUCCEED,
  'data'
)
export const copy = createAction(types.TEMPLATE_COPY)
export const create = createAction(types.TEMPLATE_CREATE, 'payload')
export const deactivate = createAction(types.TEMPLATE_DEACTIVATE, 'payload')
export const get = createAction(types.TEMPLATE_GET, 'id')
export const getNew = createAction(types.TEMPLATE_GET_NEW)
export const getSucceed = createAction(types.TEMPLATE_GET_SUCCEED, 'data')
export const mediaCreate = createAction(types.TEMPLATE_MEDIA_CREATE, 'payload')
export const mediaGet = createAction(types.TEMPLATE_MEDIA_GET, 'id')
export const mediaGetSucceed = createAction(
  types.TEMPLATE_MEDIA_GET_SUCCEED,
  'data'
)
export const mediaLoad = createAction(types.TEMPLATE_MEDIA_LOAD, 'data')
export const mediaLoadSucceed = createAction(
  types.TEMPLATE_MEDIA_LOAD_SUCCEED,
  'data'
)
export const mediaRemove = createAction(types.TEMPLATE_MEDIA_REMOVE, 'payload')
export const mediaUpdate = createAction(types.TEMPLATE_MEDIA_UPDATE, 'payload')
export const resetData = createAction(types.TEMPLATE_RESET_DATA)
export const setData = createAction(types.TEMPLATE_SET_DATA, 'data')
export const setFilter = createAction(types.TEMPLATE_SET_FILTER, 'filter')
export const setUploading = createAction(types.TEMPLATE_SET_UPLOADING, 'value')
export const update = createAction(types.TEMPLATE_UPDATE, 'payload')
