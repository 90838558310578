import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const PaperclipPlus = (props) => (
  <SvgIcon {...props}>
    <path d="M 10.917 5.787 L 10.917 17.287 C 10.917 19.496 9.126 21.287 6.917 21.287 C 4.708 21.287 2.917 19.496 2.917 17.287 L 2.917 4.787 C 2.917 3.406 4.037 2.287 5.417 2.287 C 6.798 2.287 7.917 3.406 7.917 4.787 L 7.917 15.287 C 7.917 15.839 7.47 16.287 6.917 16.287 C 6.365 16.287 5.917 15.839 5.917 15.287 L 5.917 5.787 L 4.417 5.787 L 4.417 15.287 C 4.417 16.667 5.537 17.787 6.917 17.787 C 8.298 17.787 9.417 16.667 9.417 15.287 L 9.417 4.787 C 9.417 2.578 7.626 0.787 5.417 0.787 C 3.208 0.787 1.417 2.578 1.417 4.787 L 1.417 17.287 C 1.417 20.324 3.88 22.787 6.917 22.787 C 9.955 22.787 12.417 20.324 12.417 17.287 L 12.417 5.787 L 10.917 5.787 Z" />
    <path d="M 22.949 6.521 L 19.103 6.521 L 19.103 10.768 L 17.822 10.768 L 17.822 6.521 L 13.976 6.521 L 13.976 5.105 L 17.822 5.105 L 17.822 0.857 L 19.103 0.857 L 19.103 5.105 L 22.949 5.105 L 22.949 6.521 Z" />
  </SvgIcon>
)

export default React.memo(PaperclipPlus)
