const set = (name, value) => {
  window.localStorage.setItem(name, value)
  window.sessionStorage.setItem(name, value)
}

const get = (name, defaultValue = null) => {
  const sessionValue = window.sessionStorage.getItem(name)
  if (sessionValue !== null && sessionValue !== 'null') {
    return sessionValue
  }
  const value = window.localStorage.getItem(name)
  if (value != null && value !== 'null') {
    window.sessionStorage.setItem(name, value)
    return value
  }
  return defaultValue
}

const sync = () => {
  let keys = Object.keys(window.sessionStorage)
  for (const key of keys) {
    const value = window.sessionStorage.getItem(key)
    if (value != null && value !== 'null') {
      window.localStorage.setItem(key, window.sessionStorage.getItem(key))
    }
  }
}

const remove = (name) => {
  window.localStorage.removeItem(name)
  window.sessionStorage.removeItem(name)
}

module.exports = {
  get,
  remove,
  set,
  sync,
}
