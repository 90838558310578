import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Titled } from 'react-titled'
import Paper from '@material-ui/core/Paper'
import GridContainer from 'Components/common/GridContainer'
import GridItem from 'Components/common/GridItem'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ForceFlowIcon from 'Icons/ForceFlow'
import ForceFlowTextIcon from 'Icons/ForceFlowText'
import { withStyles, withTheme } from '@material-ui/core/styles'
import Tooltip from 'Components/common/Tooltip'
import { toArray } from 'Utils/lookup'
import * as Icons from 'Icons'
import withTranslate from 'Root/app/withTranslate'
import { changeLanguage } from 'Actions/app'
import withWidth from 'Root/app/withWidth'
import { isWidthUp } from '@material-ui/core/withWidth'

const styles = (theme) => ({
  children: {
    marginTop: 30,
  },
  flowText: {
    color: '#7f8c8d',
    fontStyle: 'italic',
  },
  forceFlowIcon: {
    fontSize: 64,
  },
  forceFlowTextIcon: {
    height: 44,
    width: 147,
  },
  forceText: {
    color: '#d35400',
    fontWeight: 500,
  },
  icon: {
    paddingLeft: '0px !important',
  },
  languages: {
    marginTop: 20,
  },
  paper: {
    padding: 40,
    paddingTop: 20,
    paddingBottom: 20,
  },
  root: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: { marginTop: 30 },
    padding: 8,
  },
  terms: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 15,
  },
  termsDivider: {
    marginLeft: 10,
    marginRight: 10,
  },
  titleMain: {
    textAlign: 'right',
    textTransform: 'uppercase',
    color:
      theme.palette.type === 'light'
        ? theme.palette.primary.main
        : theme.palette.primary.light,
  },
  titleSub: {
    color: theme.palette.text.secondary,
    textAlign: 'right',
  },
})

export class PublicBase extends Component {
  handleLanguageClick = (language) => () => {
    const { changeLanguage } = this.props
    changeLanguage(language)
  }
  render = () => {
    const {
      children,
      classes,
      company,
      languages,
      t,
      theme,
      title,
      maxWidth = 360,
      width,
    } = this.props
    return (
      <div className={classes.root}>
        <Titled title={() => 'ForceFlow'}></Titled>
        <GridContainer justifyContent="center">
          <Paper
            className={classes.paper}
            elevation={isWidthUp('sm', width) ? 1 : 0}
            style={{ maxWidth, width: maxWidth }}
          >
            <GridContainer direction="row">
              <GridItem xs={2} className={classes.icon}>
                {!company && (
                  <ForceFlowIcon
                    className={classes.forceFlowIcon}
                    color={theme.palette.type === 'dark' ? 'white' : undefined}
                  />
                )}
              </GridItem>
              <GridItem xs={10}>
                <GridContainer direction="column" alignItems="flex-end">
                  <GridItem xs={12}>
                    {!company ? (
                      <div>
                        <ForceFlowTextIcon
                          className={classes.forceFlowTextIcon}
                          colorFlow={
                            theme.palette.type === 'dark' ? 'white' : undefined
                          }
                        />
                      </div>
                    ) : (
                      <Typography variant="h5" className={classes.titleMain}>
                        {company}
                      </Typography>
                    )}
                    <Typography
                      variant="subtitle1"
                      className={classes.titleSub}
                    >
                      {title}
                    </Typography>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridItem className={classes.children} xs={12}>
              {children}
            </GridItem>
            <GridContainer
              justifyContent="center"
              className={classes.languages}
            >
              <GridItem>
                {toArray(languages).map((language) => {
                  const Icon = language && language.icon && Icons[language.icon]
                  return (
                    <Tooltip
                      key={language.id}
                      title={t(`lookup.language.${language.id}`)}
                    >
                      <IconButton
                        onClick={this.handleLanguageClick(language.id)}
                      >
                        {Icon && <Icon />}
                      </IconButton>
                    </Tooltip>
                  )
                })}
              </GridItem>
            </GridContainer>
          </Paper>
          {!company ? (
            <GridItem className={classes.terms} align="center" xs={12}>
              <Typography variant="body2">
                <a
                  href="https://forceflow.nl/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="shell.publicBase.privacy" />
                </a>
              </Typography>
              <Typography variant="body2" className={classes.termsDivider}>
                -
              </Typography>
              <Typography variant="body2">
                <a
                  href="https://forceflow.nl/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="shell.publicBase.terms" />
                </a>
              </Typography>
            </GridItem>
          ) : (
            <GridItem className={classes.terms} align="center" xs={12}>
              <span>Powered by&nbsp;</span>
              <span>
                <a
                  href="https://forceflow.nl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ForceFlow
                </a>
              </span>
            </GridItem>
          )}
        </GridContainer>
      </div>
    )
  }
}

export default compose(
  withTranslate,
  withStyles(styles),
  withTheme,
  withWidth,
  connect((state) => ({ languages: state.app.languages || {} }), {
    changeLanguage,
  })
)(PublicBase)
