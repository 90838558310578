import * as types from './types'
import { createAction } from 'Utils/redux'

export const addCustomer = createAction(types.FUNNEL_ADD_CUSTOMER, 'id')
export const getFunnel = createAction(types.FUNNEL_GET_FUNNEL)
export const loadCustomers = createAction(
  types.FUNNEL_LOAD_CUSTOMERS,
  'container'
)
export const moveCustomer = createAction(
  types.FUNNEL_MOVE_CUSTOMER,
  'fromStatus',
  'toStatus',
  'customerId'
)
export const removeCustomer = createAction(
  types.FUNNEL_REMOVE_CUSTOMER,
  'customerId',
  'name'
)
export const removeCustomerSucceed = createAction(
  types.FUNNEL_REMOVE_CUSTOMER_SUCCEED,
  'customerId'
)
export const setAssignedTo = createAction(types.FUNNEL_SET_ASSIGNED_TO, 'value')
export const showConfetti = createAction(types.FUNNEL_SHOW_CONFETTI, 'value')
export const timelineLoad = createAction(
  types.FUNNEL_TIMELINE_LOAD,
  'customerId'
)
export const timelineLoadSucceed = createAction(
  types.FUNNEL_TIMELINE_LOAD_SUCCEED,
  'values'
)
