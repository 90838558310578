import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export default function createSvgIcon(path, displayName, svgProps) {
  const Component = React.memo(
    React.forwardRef((props, ref) => (
      <SvgIcon
        data-mui-test={`${displayName}Icon`}
        ref={ref}
        {...props}
        {...svgProps}
      >
        {path}
      </SvgIcon>
    ))
  )

  if (process.env.NODE_ENV !== 'production') {
    Component.displayName = `${displayName}Icon`
  }

  Component.muiName = SvgIcon.muiName

  return Component
}
