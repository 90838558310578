import { useState, useCallback, useEffect } from 'react'

function useWidth() {
  const getSize = useCallback((width) => {
    if (width <= 600) {
      return 'xs'
    } else if (width > 600 && width <= 960) {
      return 'sm'
    } else if (width > 960 && width <= 1280) {
      return 'md'
    } else if (width > 1280 && width <= 1920) {
      return 'lg'
    }
    return 'xl'
  }, [])

  const [windowWidth, setWindowWidth] = useState(getSize(window.innerWidth))

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getSize(window.innerWidth))
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [getSize])
  return windowWidth
}

export default useWidth
