import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagDenmark = (props) => (
  <SvgIcon viewBox="0 0 640 480" {...props}>
    <defs>
      <clipPath id="prefix__a" clipPathUnits="userSpaceOnUse">
        <path fillOpacity={0.67} d="M-64 0h682.67v512H-64z" />
      </clipPath>
    </defs>
    <g
      fillRule="evenodd"
      clipPath="url(#prefix__a)"
      transform="translate(60) scale(.9375)"
      strokeWidth="1pt"
    >
      <path fill="#fb1b22" d="M-64 0h676.57v512H-64z" />
      <path fill="#fff" d="M-64 219.43h676.57v73.143H-64z" />
      <path fill="#fff" d="M155.43 0h73.143v512H155.43z" />
    </g>
  </SvgIcon>
)

export default React.memo(FlagDenmark)
