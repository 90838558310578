import { call, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { hide } from 'redux-modal'
import { showMessage } from 'Actions/shell'
import {
  deleteMyPhotoSucceed,
  setMyData,
  setSaving,
  uploadMyPhotoSucceed,
} from 'Actions/user'
import { setTheme } from 'Actions/theme'
import { confirmSaga, globalError } from './shell'
import { loadCompanies, loadUsers, loadViewers } from 'Actions/cache'
import { t } from 'Root/app/IntlProvider'
import { messageLevel } from 'Utils/constants'
import { getSessions } from 'Actions/auth'
import storage from 'Utils/storage'
import { addToList, addToSelected } from 'Actions/search'

export function* activateSaga(action) {
  const {
    payload: { id, name },
  } = action
  const yes = yield call(confirmSaga, {
    title: t('user.message.activate.title'),
    content: t('user.message.activate.content', { user: name }),
  })
  if (!yes) {
    return
  }
  const { ok, error, data } = yield http.post(`/users/${id}/activate`)
  if (ok) {
    yield put(loadUsers())
  } else if (error) {
    if (data.error === 'Email is in use') {
      yield put(showMessage(t('user.message.error.inUse'), messageLevel.error))
    } else {
      yield call(globalError, data)
    }
  }
}

export function* createSaga(action) {
  const {
    payload: { values, reject, resolve },
  } = action
  const { created, error, data } = yield http.post(`/users`, values)
  if (created) {
    //yield put(getCertificates(id))
    yield put(loadUsers())
    yield put(loadCompanies())
    yield call(resolve)
  } else if (error) {
    if (data.error === 'Email is in use') {
      yield put(showMessage(t('user.message.error.inUse'), messageLevel.error))
      yield call(reject)
    } else if (data.error === 'Invalid sourcer domain') {
      yield put(
        showMessage(
          t('user.message.error.invalidSourcerEmail'),
          messageLevel.error,
          5000
        )
      )
      yield call(reject)
    } else {
      yield call(globalError, data, reject)
    }
  }
}

export function* createViewerSaga(action) {
  const { payload } = action
  const { created, error, data } = yield http.post(`/users/viewers`, payload)
  if (created) {
    yield put(hide('viewerForm'))
    yield put(addToList(data))
    yield put(addToSelected(data.id))
  } else if (error) {
    if (data.error === 'Email is in use') {
      yield put(showMessage(t('user.message.error.inUse'), messageLevel.error))
    } else {
      yield call(globalError, data)
    }
  }
}

export function* deactivateSaga(action) {
  const {
    payload: { id, name },
  } = action
  const yes = yield call(confirmSaga, {
    title: t('user.message.deactivate.title'),
    content: t('user.message.deactivate.content', { user: name }),
  })
  if (!yes) {
    return
  }
  const { noContent, error, data } = yield http.delete(`/users/${id}`)
  if (noContent) {
    yield put(loadUsers())
    yield put(loadCompanies())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* deleteMyPhotoSaga() {
  const ok = yield call(confirmSaga, {
    title: t('user.message.deleteMyPhoto.title'),
    content: t('user.message.deleteMyPhoto.content'),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete(`/users/profile/photo`)
  if (noContent) {
    yield put(deleteMyPhotoSucceed())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* editSaga(action) {
  const {
    payload: { id, values, reject, resolve },
  } = action

  const { ok, error, data } = yield http.put(`/users/${id}`, values)
  if (ok) {
    //yield put(getCertificates(id))
    yield put(loadUsers())
    yield put(loadCompanies())
    yield call(resolve)
  } else if (error) {
    if (data.error === 'Email is in use') {
      yield put(showMessage(t('user.message.error.inUse'), messageLevel.error))
      yield call(reject)
    } else if (data.error === 'Invalid sourcer domain') {
      yield put(
        showMessage(
          t('user.message.error.invalidSourcerEmail'),
          messageLevel.error,
          5000
        )
      )
      yield call(reject)
    } else {
      yield call(globalError, data, reject)
    }
  }
}

export function* getMySaga() {
  const { ok, error, data } = yield http.get('/users/profile')
  if (ok) {
    yield put(setMyData(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* inviteSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.post(`/users/${id}/invite`)
  if (ok) {
    yield put(loadUsers())
    yield put(showMessage(t('user.message.invited')))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* putMySaga(action) {
  const { values } = action
  yield put(setSaving(true))
  yield put(setMyData(values))
  const { ok, error, data } = yield http.put('/users/profile', values)
  if (ok) {
    if (values.palette) {
      storage.set('palette', values.palette)
    }
    yield put(setMyData(data))
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setSaving(false))
}

export function* putMyThemeSaga(action) {
  const { remove, theme } = action
  if (remove) {
    storage.remove('palette')
  } else {
    storage.set('palette', theme)
  }
  yield put(setTheme(theme))
}

export function* removeViewerSaga(action) {
  const {
    payload: { id, name },
  } = action
  const yes = yield call(confirmSaga, {
    title: t('user.message.removeViewer.title'),
    content: t('user.message.removeViewer.content', { user: name }),
  })
  if (!yes) {
    return
  }
  const { noContent, error, data } = yield http.delete(`/users/viewers/${id}`)
  if (noContent) {
    yield put(loadViewers())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* saveDefaultTemplateSaga(action) {
  const { payload } = action
  const { ok, error, data } = yield http.post(
    `/users/default-template`,
    payload
  )
  if (ok) {
    // do nothing
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* saveFilterSaga(action) {
  const { payload } = action
  const { ok, error, data } = yield http.post(`/users/filter`, payload)
  if (ok) {
    // do nothing
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* signoutSaga(action) {
  const { sessionId } = action
  const { ok, error, data } = yield http.post(`/users/signout/${sessionId}`)
  if (ok) {
    yield put(getSessions())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* terminateSaga(action) {
  const {
    payload: { id, name, reject, resolve },
  } = action
  const yes = yield call(confirmSaga, {
    title: t('user.message.terminate.title'),
    content: t('user.message.terminate.content', { user: name }),
  })
  if (!yes) {
    return yield call(reject)
  }
  const { ok, error, data } = yield http.post(`/users/${id}/terminate`)
  if (ok) {
    yield call(resolve)
    yield put(loadUsers())
    yield put(loadCompanies())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* uploadMyPhotoSaga(action) {
  const { file } = action
  const { ok, error, data } = yield http.post(`/users/profile/photo`, file)
  if (ok) {
    yield put(uploadMyPhotoSucceed(data.url))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* updateViewerSaga(action) {
  const { id, ...rest } = action.payload
  const { ok, error, data } = yield http.put(`/users/viewers/${id}`, rest)
  if (ok) {
    yield put(hide('viewerForm'))
  } else if (error) {
    if ((data || {}).error === 'Email is in use') {
      yield put(showMessage(t('user.message.error.inUse'), messageLevel.error))
    } else {
      yield call(globalError, data)
    }
  }
}

export function* logLocationSaga({ location }) {
  if (location && location.pathname) {
    const state = yield select()
    const authenticated = state.auth.info.authenticated
    if (authenticated) {
      const parts = location.pathname.split('/')
      const entity = parts.length > 1 ? parts[1] : null
      const entityId = parts.length > 2 ? parts[2] : null
      const tab = parts.length > 3 ? parts[3] : null
      yield http.post(`/users/action`, { entity, entityId, tab })
    }
  }
}
