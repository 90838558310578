import React from 'react'
import TextField from '@material-ui/core/TextField'

const MessageField = ({ autoFocus, fullWidth, input, label, rows = 4 }) => {
  return (
    <TextField
      {...input}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      label={label}
      multiline
      rows={rows}
      variant="outlined"
    ></TextField>
  )
}

export default MessageField
