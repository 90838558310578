import * as types from 'Actions/types'
import { combineReducers } from 'redux'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'
import orderBy from 'lodash.orderby'

const planningReducer = createReducer(initialState.project.planning, {
  [types.PROJECT_GET_CANDIDATES_SUCCEED]: (state, { data, edit }) => {
    return {
      ...state,
      candidates: data.filter((x) => (edit ? x : x.onJob)),
    }
  },
  [types.PROJECT_GET_PLANNING_SUCCEED]: (state, { data }) => {
    const { jobs, timelines } = data
    return {
      ...state,
      jobs,
      timelines,
    }
  },
  [types.PROJECT_JOB_MOVE_DOWN]: (state, { jobId }) => {
    const index = state.jobs.findIndex((x) => x.id === jobId)
    console.log(index)
    if (index === -1 || index === state.jobs.length - 1) {
      return state
    }
    const jobs = state.jobs.map((item, i) => {
      if (i === index) {
        return { ...item, order: i + 1 }
      } else if (i === index + 1) {
        return { ...state.jobs[i], order: i - 1 }
      } else {
        return { ...state.jobs[i], order: i }
      }
    })
    return {
      ...state,
      jobs: orderBy(jobs, ['order', 'name']),
    }
  },
  [types.PROJECT_JOB_MOVE_UP]: (state, { jobId }) => {
    const index = state.jobs.findIndex((x) => x.id === jobId)
    console.log(index)
    if (index === -1 || index === 0) {
      return state
    }
    const jobs = state.jobs.map((item, i) => {
      if (i === index) {
        return { ...item, order: i - 1 }
      } else if (i === index - 1) {
        return { ...state.jobs[i], order: i + 1 }
      } else {
        return { ...state.jobs[i], order: i }
      }
    })
    return {
      ...state,
      jobs: orderBy(jobs, ['order', 'name']),
    }
  },
  [types.PROJECT_SET_INTERVAL]: (state, { start, end }) => {
    if (!start) {
      return {
        ...state,
        interval: initialState.project.planning.interval,
      }
    }
    return {
      ...state,
      interval: {
        start,
        end,
      },
    }
  },
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.project.planning,
})

const sourcesReducer = createReducer(initialState.project.sources, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.project.sources,
  [types.AUTH_CHANGE_COMPANY_SUCCEED]: () => initialState.project.sources,
  [types.PROJECT_ADD_SOURCES]: (state, { data }) => ({
    ...state,
    data,
  }),
  [types.PROJECT_SET_SOURCES_DIRTY]: (state, { value }) => ({
    ...state,
    dirty: value,
  }),
})

const timelinesReducer = createReducer(initialState.project.timelines, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({
    ...initialState.project.timelines,
  }),
  [types.PROJECT_GET_TIMELINES]: (state, { start, end }) => {
    return {
      ...state,
      interval: {
        end: end || state.interval.end,
        start: start || state.interval.start,
      },
    }
  },
  [types.PROJECT_GET_TIMELINES_SUCCEED]: (state, { data }) => {
    return {
      ...state,
      groups: data.groups,
      list: data.list,
    }
  },
  [types.PROJECT_RESET_TIMELINE_INTERVAL]: (state) => {
    return {
      ...state,
      interval: initialState.project.timelines.interval,
    }
  },
  [types.PROJECT_SET_SCROLL_POSITION]: (state, { top, left }) => ({
    ...state,
    position: {
      left,
      top,
    },
  }),
  [types.PROJECT_SET_LOADING]: (state, { value }) => ({
    ...state,
    loading: value,
  }),
  [types.PROJECT_SET_TIMELINE_INTERVAL]: (state, { start, end }) => {
    return {
      ...state,
      interval: {
        end,
        start,
      },
    }
  },
})

export default combineReducers({
  planning: planningReducer,
  sources: sourcesReducer,
  timelines: timelinesReducer,
})
