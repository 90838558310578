import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagIsrael = (props) => (
  <SvgIcon viewBox="0 0 513 342" {...props}>
    <path fill="#FFF" d="M0 0h513v342H0z" />
    <path
      d="M340.6 122.4h-56.1l-28-48.6-28 48.6h-56.1l28 48.6-28 48.6h56.1l28 48.6 28-48.6h56.1l-28-48.6 28-48.6zM293.2 171L276 204.2h-38.9L219.8 171l17.2-33.2h38.9l17.3 33.2zm-36.7-71.8l11.9 23.3h-23.9l12-23.3zm-58.3 38.6h23.9l-10.8 21-13.1-21zm0 66.4l13-22.1 11.9 22.1h-24.9zm58.3 37.5l-11.9-22.1h23.9l-12 22.1zm59.4-37.5h-25l11.9-22.1 13.1 22.1zm-26.1-66.4h26.1l-13 22.1-13.1-22.1zM0 21.3h512V64H0zm0 256h512V320H0z"
      fill="#2E52B2"
    />
  </SvgIcon>
)

export default React.memo(FlagIsrael)
