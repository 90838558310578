import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TimelineTextOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M5 12c0 1.11-.89 2-2 2a2 2 0 112-2M4 2v6H2V2h2M2 22v-6h2v6H2M24 6v12c0 1.11-.89 2-2 2H10a2 2 0 01-2-2v-4l-2-2 2-2V6a2 2 0 012-2h12c1.11 0 2 .89 2 2m-2 0H10v4.83L8.83 12 10 13.17V18h12V6M12 9h8v2h-8V9m0 4h6v2h-6v-2z" />
  </SvgIcon>
)

export default React.memo(TimelineTextOutline)
