import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagIceland = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <path fill="#0052B4" d="M0 85.334h512v341.337H0z" />
    <path
      fill="#FFF"
      d="M512 295.883H202.195v130.783h-79.76V295.883H0v-79.772h122.435V85.329h79.76v130.782H512v61.218z"
    />
    <path
      fill="#D80027"
      d="M512 234.666v42.663H183.652v149.337h-42.674V277.329H0v-42.663h140.978V85.329h42.674v149.337z"
    />
  </SvgIcon>
)

export default React.memo(FlagIceland)
