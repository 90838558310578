import React from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const styles = () => ({
  root: {
    padding: 16,
  },
})

const GridContainer = ({
  children,
  classes,
  className,
  root,
  spacing,
  justifyContent,
  ...props
}) => (
  <Grid
    container
    spacing={spacing || 1}
    justify={justifyContent || 'flex-start'}
    className={clsx(className, root && classes.root)}
    {...props}
  >
    {children}
  </Grid>
)

export default withStyles(styles)(GridContainer)
