import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const quickReducer = createReducer(initialState.quick, {
  [types.QUICK_GET_ITEM_SUCCEED]: (state, { data }) => ({
    ...state,
    item: data,
  }),
  [types.QUICK_GET_LIST_SUCCEED]: (state, { data }) => ({
    ...state,
    list: data,
  }),
  [types.QUICK_LOADING]: (state, { value }) => ({
    ...state,
    loading: value,
  }),
  [types.QUICK_LOADING_ITEM]: (state, { value }) => ({
    ...state,
    loadingItem: value,
  }),
  [types.QUICK_SET_HIGHLIGHT]: (state, { text }) => ({
    ...state,
    highlight: text,
  }),
  [types.QUICK_SET_SELECTED]: (state, { data }) => ({
    ...state,
    selected: data,
  }),
  [types.QUICK_SET_TAB]: (state, { tab }) => ({
    ...state,
    tab,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.quick,
})

export default quickReducer
