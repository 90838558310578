import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const CheckboxField = ({ input, label, ...other }) => {
  const { value, ...otherInput } = input
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="default"
          checked={value || false}
          {...otherInput}
          {...other}
        />
      }
      label={label}
    />
  )
}

export default CheckboxField
