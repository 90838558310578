import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { hideMessage } from 'Actions/shell'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
class Message extends Component {
  handleSnackbarClose = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.props.hideMessage()
  }
  render = () => {
    const { duration, severity, text, visible } = this.props
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={visible || false}
        disableWindowBlurListener
        autoHideDuration={duration}
        onClose={this.handleSnackbarClose}
      >
        <Alert severity={severity}>{text || ''}</Alert>
      </Snackbar>
    )
  }
}

export default compose(
  connect(
    (state) => ({
      duration: state.shell.message.duration,
      severity: state.shell.message.level || 'info',
      text: state.shell.message.text,
      visible: state.shell.message.visible,
    }),
    { hideMessage }
  )
)(Message)
