import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagGreece = (props) => (
  <SvgIcon viewBox="0 0 513 342" {...props}>
    <path fill="#FFF" d="M0 0h513v342H0z" />
    <g fill="#1571CC">
      <path d="M0 0h513v38H0zM0 76h513v38H0zM0 152h513v38H0zM0 228h513v38H0zM0 304h513v38H0z" />
    </g>
    <path fill="#1571CC" d="M0 0h190v190H0z" />
    <g fill="#FFF">
      <path d="M0 76h190v38H0z" />
      <path d="M76 0h38v190H76z" />
    </g>
  </SvgIcon>
)

export default React.memo(FlagGreece)
