import { call, delay, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { getNumber, setNumber } from 'Actions/deletion'
import { load, setSelected } from 'Actions/table'
import { confirmSaga, globalError } from './shell'
import { t } from 'Root/app/IntlProvider'

export function* getNumberSaga() {
  const { ok, error, data } = yield http.get(`/deletions/number`)
  if (ok) {
    yield put(setNumber(data.number))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* restoreItemsSaga() {
  const state = yield select()
  const entities = []
  const { selected, rows } = state.table.data
  const item = selected.length > 1 ? 'Items' : 'Item'
  const title = t('deletion.message.restore.title', { item })
  const content = t('deletion.message.restore.content', {
    item: item.toLowerCase(),
  })
  const yes = yield call(confirmSaga, {
    title,
    content,
    options: { delay: 2000 },
  })
  if (!yes) {
    return
  }
  // eslint-disable-next-line require-atomic-updates
  for (const item of selected) {
    const row = rows.find((x) => x.id === item)
    if (row) {
      const { entity, id } = row
      entities.push({ entity, id })
    }
  }
  const { ok, error } = yield http.post(`/deletions/restore`, { entities })
  if (ok) {
    yield delay(2000)
    yield put(setSelected([]))
    yield put(load(state.table.info.skip))
    yield put(getNumber())
  } else if (error) {
    yield call(globalError)
  }
}
