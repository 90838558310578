import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const CommentAccountOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M9 22a1 1 0 01-1-1v-3H4a2 2 0 01-2-2V4a2 2 0 012-2h16a2 2 0 012 2v12a2 2 0 01-2 2h-6.1l-3.7 3.71c-.2.19-.45.29-.7.29H9m1-6v3.08L13.08 16H20V4H4v12h6m6-2H8v-1c0-1.33 2.67-2 4-2s4 .67 4 2v1m-4-8a2 2 0 012 2 2 2 0 01-2 2 2 2 0 01-2-2 2 2 0 012-2z" />
  </SvgIcon>
)

export default React.memo(CommentAccountOutline)
