import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagFrance = (props) => (
  <SvgIcon viewBox="0 0 640 480" {...props}>
    <g strokeWidth="1pt">
      <rect width="630" height="480" fill="#ED2939" />
      <rect width="420" height="480" fill="#fff" />
      <rect width="210" height="480" fill="#002395" />
    </g>
  </SvgIcon>
)

export default React.memo(FlagFrance)
