import * as types from './types'
import { createAction } from 'Utils/redux'

export const getData = createAction(
  types.APPLY_GET_DATA,
  'companySlug',
  'jobSlug',
  'search'
)
export const postData = createAction(types.APPLY_POST_DATA, 'payload')
export const setData = createAction(types.APPLY_SET_DATA, 'data')
