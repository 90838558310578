import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const AccountKey = (props) => (
  <SvgIcon {...props}>
    <path d="M11 10v2h-1v2H8v-2H5.83A2.99 2.99 0 013 14a3 3 0 01-3-3 3 3 0 013-3c1.31 0 2.42.83 2.83 2H11m-8 0a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1m13 4c2.67 0 8 1.34 8 4v2H8v-2c0-2.66 5.33-4 8-4m0-2a4 4 0 01-4-4 4 4 0 014-4 4 4 0 014 4 4 4 0 01-4 4z" />
  </SvgIcon>
)

export default React.memo(AccountKey)
