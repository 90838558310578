import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Booston = (props) => (
  <SvgIcon viewBox="0 0 2000 2000" {...props}>
    <path d="M892.632 657.814L356.516 970.232v662.484l-229.972-132.643V858.31l587.48-305.267z" />
    <path d="M1197.754 1881.512L992.589 2000 448.74 1685.978v-675.472l197.43-116.008v640.158z" />
    <path d="M1858.633 1222.093v277.98l-561.361 324.092-560.775-352.547v-203.706l543.557 305.268z" />
    <path d="M1858.633 500.074v614.184l-592.004 359.112-192.471-103.604 538.16-337.08 10.797-668.757z" />
    <path d="M1522.576 305.998l-10.944 682.328-172.625 104.626-.438-614.183-583.103-341.748L992.589 0z" />
    <path d="M1257.436 764.192L705.124 443.31l-578.58 300.744v-243.98L663.536 189.99l593.755 348.023z" />
  </SvgIcon>
)

export default React.memo(Booston)
