import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Rm = (props) => (
  <SvgIcon viewBox="0 0 1000 1000" {...props}>
    <path d="M984.5 753.3h-131l-43.6-291.6-119.6 291.6h-52.4L524.3 461.7l-43.6 291.6h-131l33-194.9.1-.5 14.4-84.5v-.2l21.2-124.6 17.3-101.9H566l101.4 270.1 100.8-270.1h130.3z" />
    <path
      d="M224 364.3c14.1-10.7 34.4-16.1 60.9-16.1H396l17.3-101.4H235.7c-28.7 0-53.8 4.2-75.2 12.5-21.4 8.3-39.1 19.6-53 33.9C93.5 307.5 83 324 76 342.8s-10.6 39-10.6 60.5c0 38.6 9.5 69.8 28.4 93.7 18.9 24 46.8 40.2 83.8 48.7L15.5 753.3h166.8l104.9-159.9 22.8-34.7h50.3l.1-.4 14.4-84.9v-.2h-90c-26.5 0-46.8-5.4-60.9-16.1-14.2-10.7-21.2-26.2-21.2-46.4.1-20.2 7.2-35.7 21.3-46.4z"
      fill="#85b753"
    />
  </SvgIcon>
)

export default React.memo(Rm)
