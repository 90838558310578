import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, Form } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import compose from 'recompose/compose'
import complexity from 'complexity'
import GridContainer from 'Components/common/GridContainer'
import GridItem from 'Components/common/GridItem'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { resetPassword } from 'Actions/auth'
import Base from 'Components/shell/PublicBase'
import TextField, { passwordOptions } from 'Components/common/form/TextField'
import withTranslate from 'Root/app/withTranslate'

const styles = () => ({
  marginBottom: {
    marginBottom: 30,
  },
  submit: {
    marginTop: 20,
  },
})

const validate = ({ password }, { t }) => {
  const errors = {}
  if (!password) {
    errors.password = t('auth.resetPassword.error.password.required')
  } else if (!complexity.check(password, passwordOptions)) {
    errors.password = t('auth.resetPassword.error.password.invalid')
  }
  return errors
}

class ResetPassword extends Component {
  state = {
    showLink: false,
  }

  resetPassword = (values) =>
    new Promise((resolve, reject) => {
      this.props.resetPassword({
        values,
        resolve,
        reject,
      })
    })
  handleSubmit = async (values) => {
    const userId = this.props.match.params.userid
    const token = this.props.match.params.token
    const data = { ...values, userId, token }
    try {
      await this.resetPassword(data)
    } catch (e) {}
  }

  render() {
    const { classes, handleSubmit, invalid, pristine, submitting, t } =
      this.props
    return (
      <Base title={t('auth.resetPassword.title')}>
        <Form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit)}
          autoComplete="off"
        >
          <GridContainer direction="column">
            <GridItem xs={12}>
              <Typography component="p">
                <FormattedMessage id="auth.resetPassword.info" />
              </Typography>
            </GridItem>
            <GridItem errorHeight xs={12}>
              <Field
                name="password"
                required
                password
                passwordStrength
                component={TextField}
                placeholder={t('auth.resetPassword.password.placeholder')}
                label={t('auth.resetPassword.password.label')}
                autoFocus
              />
            </GridItem>
            <GridItem className={classes.marginBottom} xs={12}>
              <GridContainer justifyContent="center">
                <Button
                  variant="contained"
                  className={classes.submit}
                  type="submit"
                  color="primary"
                  disabled={pristine || invalid || submitting}
                >
                  <FormattedMessage id="auth.resetPassword.button.submit" />
                </Button>
              </GridContainer>
            </GridItem>
            {this.state.showLink && (
              <GridItem xs={12}>
                <GridContainer justifyContent="center">
                  <Typography component="div" variant="subtitle1">
                    <FormattedMessage id="auth.resetPassword.passwordForgotten.info" />
                  </Typography>
                  <Typography variant="subtitle1">
                    <Link to={'/password-forgotten'}>
                      <FormattedMessage id="auth.resetPassword.passwordForgotten.siginin.text" />
                    </Link>
                  </Typography>
                </GridContainer>
              </GridItem>
            )}
          </GridContainer>
        </Form>
      </Base>
    )
  }
}

export default compose(
  withTranslate,
  connect(null, { resetPassword }),
  withStyles(styles),
  reduxForm({
    form: 'resetPassword',
    validate,
  })
)(ResetPassword)
