import React from 'react'
import { injectIntl } from 'react-intl'

const getTranslate = (intl) => (id, values) => {
  return intl.formatMessage({ id }, values)
}

export const getEntityName =
  (intl) =>
  (entity, plural = false) => {
    const t = getTranslate(intl)
    if (plural) {
      return t(`global.entity.plural.${entity}`)
    }
    return t(`global.entity.single.${entity}`)
  }

export default (WrappedComponent) => {
  return injectIntl(
    // eslint-disable-next-line react/display-name
    class extends React.Component {
      constructor(props) {
        super(props)
        const translate = getTranslate(props.intl)
        const getEntity = getEntityName(props.intl)
        this.state = {
          getEntity,
          locale: props.intl.locale,
          translate,
        }
      }

      static getDerivedStateFromProps(props, state) {
        const { intl } = props
        if (intl && intl.locale !== state.locale) {
          return {
            getEntity: getEntityName(intl),
            locale: intl.locale,
            translate: getTranslate(intl),
          }
        }
        return null
      }
      // componentDidUpdate(prevProps) {
      //   const { intl } = this.props
      //   if (intl && intl.locale !== prevProps.intl.locale) {
      //     //console.log('CHANGED', intl.locale)
      //     const translate = getTranslate(intl)
      //     const getEntity = getEntityName(intl)
      //     this.setState({ getEntity, translate })
      //   }
      // }

      render() {
        const { getEntity, translate } = this.state
        return (
          <WrappedComponent
            t={translate}
            getEntityCaption={getEntity}
            {...this.props}
          />
        )
      }
    }
  )
}
