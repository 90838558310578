import * as types from './types'
import { createAction } from 'Utils/redux'

export const addAttachment = createAction(types.EMAIL_ADD_ATTACHMENT, 'file')
export const addAttachmentSucceed = createAction(
  types.EMAIL_ADD_ATTACHMENT_SUCCEED,
  'values'
)
export const addContext = createAction(types.EMAIL_ADD_CONTEXT, 'value')
export const addRecipients = createAction(types.EMAIL_ADD_RECIPIENTS, 'payload')
export const clearAttachments = createAction(types.EMAIL_CLEAR_ATTACHMENTS)
export const connect = createAction(types.EMAIL_CONNECT, 'id', 'payload')
export const createCandidate = createAction(
  types.EMAIL_CREATE_CANDIDATE,
  'attachmentId'
)
export const deleteAttachment = createAction(
  types.EMAIL_DELETE_ATTACHMENT,
  'id'
)
export const deleteAttachmentSucceed = createAction(
  types.EMAIL_DELETE_ATTACHMENT_SUCCEED,
  'id'
)
export const deleteDraft = createAction(types.EMAIL_DELETE_DRAFT, 'payload')
export const decrementUploading = createAction(types.EMAIL_DECREMENT_UPLOADING)
export const incrementUploading = createAction(types.EMAIL_INCREMENT_UPLOADING)
export const getTemplate = createAction(types.EMAIL_GET_TEMPLATE, 'payload')
export const removeContext = createAction(types.EMAIL_REMOVE_CONTEXT, 'value')
export const removeTemplateAttachments = createAction(
  types.EMAIL_REMOVE_TEMPLATE_ATTACHMENTS
)
export const resetUploading = createAction(types.EMAIL_RESET_UPLOADING)
export const saveJob = createAction(types.EMAIL_SAVE_JOB, 'id', 'jobId')
export const send = createAction(types.EMAIL_SEND, 'values')
export const sendBulk = createAction(types.EMAIL_SEND_BULK, 'values')
export const setJobName = createAction(types.EMAIL_SET_JOB_NAME, 'jobName')
export const showBulkEmail = createAction(types.EMAIL_SHOW_BULKEMAIL, 'options')
export const showNewEmail = createAction(types.EMAIL_SHOW_NEWEMAIL, 'options')
export const translate = createAction(types.EMAIL_TRANSLATE, 'payload')
export const viewGet = createAction(types.EMAIL_VIEW_GET, 'id')
