import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'
import deepmerge from 'deepmerge'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

const certificateReducer = createReducer(initialState.certificate, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.certificate,
  [types.CERTIFICATE_ADD_CERTIFICATE]: (state, { payload }) => {
    let selectedIndex = state.selectedIndex
    const index = uuidv4()
    if (!selectedIndex) {
      selectedIndex = index
    }
    return {
      ...state,
      list: [...state.list, { file: payload, index, check: true }],
      selectedIndex,
    }
  },
  [types.CERTIFICATE_DELETE_CERTIFICATE]: (state, { value }) => {
    const list = state.list
      .filter((x) => x.index !== value)
      .reduce((total, item) => [...total, { ...item }], [])

    return {
      ...state,
      selectedIndex: list.length > 0 ? list[0].index : '',
      list,
    }
  },
  [types.CERTIFICATE_RESET]: () => initialState.certificate,
  [types.CERTIFICATE_SET_BUSY]: (state, { value }) => ({
    ...state,
    busy: value,
  }),
  [types.CERTIFICATE_SET_VALUES]: (state, { values }) => {
    const newValues = deepmerge({}, values)
    if (moment.isMoment(newValues.endAt)) {
      newValues.endAt = moment(newValues.endAt).toDate()
    }
    if (moment.isMoment(newValues.startAt)) {
      newValues.startAt = moment(newValues.startAt).toDate()
    }
    const index = state.selectedIndex
    const newState = deepmerge({}, state)
    newState.list = newState.list.map((item) =>
      item.index === index ? { ...item, ...newValues } : item
    )
    return newState
  },
  [types.CERTIFICATE_SET_SELECTED_INDEX]: (state, { index }) => ({
    ...state,
    selectedIndex: index,
  }),
})

export default certificateReducer
