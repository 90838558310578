import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Merge = (props) => (
  <SvgIcon {...props}>
    <path d="M8 17l4-4h3.2c.4 1.2 1.5 2 2.8 2 1.7 0 3-1.3 3-3s-1.3-3-3-3c-1.3 0-2.4.8-2.8 2H12L8 7V3H3v5h3l4.2 4L6 16H3v5h5v-4z" />
  </SvgIcon>
)

export default React.memo(Merge)
