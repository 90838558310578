import { call, delay, put, select, take } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { showMessage } from 'Actions/shell'
import { show } from 'redux-modal'
import { addTags, loadFilters } from 'Actions/cache'
import { confirmSaga, globalError } from './shell'
import {
  getMatchingSucceed,
  load,
  loadSucceed,
  removeFilterItem,
  removeItems,
  setFilter,
  setInsights,
  setLoading,
  setMatchInfo,
  setMatchingLoaded,
  setOrder,
  setSavedFilter,
  setSavedFilterFields,
  setSearchField,
  setSearchText,
  setSelected,
  setSkip,
} from 'Actions/table'
import { getNumber } from 'Actions/deletion'
import { messageLevel } from 'Utils/constants'
import { getEntity, getEntityCaption, t } from 'Root/app/IntlProvider'
import { isMongoId } from 'Utils/string'
import { getLimit } from 'Selectors/table'

import * as types from 'Actions/types'

const getFields = (filters) => {
  let result = []
  for (const filter in filters) {
    if (
      Object.prototype.hasOwnProperty.call(filters, filter) &&
      (filters[filter].selected.length > 0 ||
        (filters[filter].inversed || []).length > 0)
    ) {
      result.push({
        field: filter,
        operator: filters[filter].operator,
        selected: filters[filter].selected,
        inversed: filters[filter].inversed,
        neutral: filters[filter].neutral || [],
      })
    }
  }
  return result
}

const getFilters = (filters) => {
  let result = {}
  for (const filter in filters) {
    if (
      Object.prototype.hasOwnProperty.call(filters, filter) &&
      (filters[filter].selected.length > 0 ||
        (filters[filter].inversed || []).length > 0)
    ) {
      result = {
        ...result,
        [filter]: {
          operator: filters[filter].operator,
          list: [...filters[filter].selected],
          inversed: [...(filters[filter].inversed || [])],
          neutral: [...(filters[filter].neutral || [])],
        },
      }
    }
  }
  return result
}

export function* getInsightsUrlSaga(action) {
  const { url } = action
  const { ok, error, data } = yield http.get(
    `/jobs/insights?url=${encodeURIComponent(url)}`
  )
  if (ok) {
    yield put(setInsights(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getLastNoteSaga(action) {
  const { id, resolve } = action
  const state = yield select()
  const entity = yield call(getEntity, state.table.info.entityType, true)
  const { ok, data } = yield http.get(`/${entity}/${id}/notes/last`)
  if (ok) {
    yield call(resolve, data)
  } else {
    yield call(resolve, {})
  }
}

export function* getMatchingSaga(action) {
  const { context, id, defaultValues } = action
  let url
  if (context === 'company') {
    url = '/companies/matching'
  } else if (context === 'candidate') {
    url = `/candidates/${id}/matching${defaultValues ? '/default' : ''}`
  } else if (context === 'job') {
    url = `/jobs/${id}/matching${defaultValues ? '/default' : ''}`
  }
  if (!url) {
    return
  }
  const { ok, error, data } = yield http.get(url)
  if (ok) {
    yield put(getMatchingSucceed(data))
    yield put(setMatchingLoaded(true))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* addTagFilterSaga(action) {
  const { tag } = action
  const state = yield select()
  const tags = ((state.table.filters || {}).tags || {}).selected || []
  if (!tags.includes(tag)) {
    yield put(setFilter('tags', tag))
  } else {
    yield put(removeFilterItem('tags', tag))
  }
  yield put(load())
}

export function* addTalentpoolFilterSaga(action) {
  const { talentpool } = action
  const state = yield select()
  const talentpools =
    ((state.table.filters || {}).talentpools || {}).selected || []
  if (!talentpools.includes(talentpool)) {
    yield put(setFilter('talentpools', talentpool))
  } else {
    yield put(removeFilterItem('talentpools', talentpool))
  }
  yield put(load())
}

export function* addTagsSaga(action) {
  const {
    payload: { tags, resolve },
  } = action
  const state = yield select()
  const entity = yield call(getEntity, state.table.info.entityType, true)
  // close form
  const { ok, error, notFound, data } = yield http.post(`/${entity}/tags`, {
    ids: state.table.data.selected,
    tags,
  })
  if (ok) {
    // timeout needed for elasticsearch to be updated
    yield delay(2000)
    // yield put(setSelected([]))
    yield put(load(state.table.info.skip, true))
  } else if (notFound) {
    const item = yield call(getEntityCaption, state.table.info.entityType)
    yield put(
      showMessage(t('table.message.notFound', { item }), messageLevel.warning)
    )
  } else if (error) {
    yield call(globalError, data)
  }
  if (resolve) {
    yield call(resolve)
  }
}

export function* deleteCurrentFilterSaga() {
  const state = yield select()
  const id = state.table.info.filter
  const filter = state.cache.filters.list.find((item) => item.id === id)
  if (!filter) {
    return
  }
  const ok = yield call(confirmSaga, {
    title: t('table.message.deleteFilter.title'),
    content: t('table.message.deleteFilter.content', { filter: filter.name }),
  })
  if (!ok) {
    return
  }
  yield http.delete(`/filters/${id}`)
  yield put(loadFilters(true))
  yield put(setSavedFilter('none'))
}

export function* deleteItemsSaga(action) {
  try {
    const { anonymize = false } = action
    const state = yield select()
    const selection = state.table.data.selected
    const itemSingle = yield call(getEntityCaption, state.table.info.entityType)
    const itemPlural = yield call(
      getEntityCaption,
      state.table.info.entityType,
      true
    )
    const number = parseInt(selection.length, 10)
    const item = number > 1 ? itemPlural : itemSingle
    const title = t(
      `table.message.${anonymize ? 'anonymize' : 'delete'}.title`,
      { item }
    )
    const content = t(
      `table.message.${anonymize ? 'anonymize' : 'delete'}.content`,
      {
        item: item.toLowerCase(),
        number,
      }
    )
    const ok = yield call(confirmSaga, {
      title,
      content,
    })
    if (!ok) {
      return
    }
    const entities = yield call(getEntity, state.table.info.entityType, true)
    yield http.delete(
      `/${entities}${anonymize ? '/anonymize' : ''}?id=${selection.join(',')}`
    )
    yield put(removeItems(selection))
    yield put(setSelected([]))
    yield delay(4000)
    yield put(load(state.table.info.skip))
    yield put(getNumber())
  } catch (err) {
    console.log('Delete error', err)
  }
}

export function* exportSelectionSaga(action) {
  const { language, users } = action
  const selection = yield loadCriteria()
  delete selection.highlight
  delete selection.skip
  delete selection.limit
  const state = yield select()
  const entities = yield call(getEntity, state.table.info.entityType, true)
  const { ok, error, data } = yield http.post(`/${entities}/export`, {
    language,
    selection,
    mailToUsers: users,
  })
  if (ok) {
    yield put(showMessage(t('table.export.message.created'), messageLevel.info))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* exportShowDialogSaga(action) {
  const { plural } = action
  const state = yield select()
  const entity = yield call(getEntity, state.table.info.entityType)
  const { ok, error, data } = yield http.get(`/exports/my/${entity}`)
  if (ok) {
    if (data.busy) {
      yield put(
        showMessage(
          t('table.export.message.busy', { entities: plural }),
          messageLevel.info
        )
      )
    } else {
      yield put(show('export', { plural }))
    }
  } else if (error) {
    yield call(globalError, data)
  }
}

function* loadCriteria(action) {
  const state = yield select()
  const { searchText: text, filterList, order } = state.table.info
  const newState = yield select()
  const field = state.table.info.searchField
  const tab = state.table.info.tab
  const filters = yield call(getFilters, newState.table.filters || {})
  const limit = getLimit(newState)
  const filter =
    !isMongoId(state.table.info.filter) && state.table.info.filter !== 'none'
      ? state.table.info.filter
      : undefined
  const criteria = {
    field,
    filter,
    filters,
    highlight: state.auth.user.settings.showHighlight,
    includeFilters: filterList,
    limit,
    order,
    ranking: false,
    skip: action ? action.skip : 0,
    tab,
    text,
  }
  const { jobId } = state.candidate.selectBar
  if (jobId && state.table.info.entityType === 'candidate') {
    criteria.jobId = jobId
    criteria.ranking =
      typeof state.candidate.selectBar.matching === 'undefined'
        ? typeof state.auth.user.settings.autoMatchOn === 'undefined'
          ? true
          : state.auth.user.settings.autoMatchOn
        : state.candidate.selectBar.matching
  }
  const { candidateId } = state.job.selectBar
  if (candidateId && state.table.info.entityType === 'job') {
    criteria.candidateId = candidateId
    criteria.ranking =
      typeof state.job.selectBar.matching === 'undefined'
        ? typeof state.auth.user.settings.autoMatchOn === 'undefined'
          ? true
          : state.auth.user.settings.autoMatchOn
        : state.job.selectBar.matching
  }
  return criteria
}

const updateFilters = (data, filters) => {
  if (!data || !data.filters || !filters) {
    return
  }
  for (const key in data.filters) {
    const filter = data.filters[key]
    if (filter) {
      const neutral = (filters[key] || {}).neutral || []
      if (neutral.length > 0) {
        for (const item of neutral) {
          const find = filter.find((x) => x.name === item.id)
          if (!find) {
            filter.push({ name: item.id, description: item.name, count: 0 })
          }
        }
      }
    }
  }
}

export function* loadSaga(action) {
  const criteria = yield call(loadCriteria, action)
  yield put(setLoading(true))
  try {
    const { force } = action
    const state = yield select()
    if (state.table.data.selected.length > 0 && !force) {
      // Prevent reorder sorting
      return
    }
    if (!state.table.info.entityType) {
      return
    }
    const entity = yield call(getEntity, state.table.info.entityType)
    const entities = yield call(getEntity, state.table.info.entityType, true)
    const { ok, error, data } = yield http.post(`/${entities}/search`, criteria)
    if (ok) {
      data.type = entity
      yield call(updateFilters, data, state.table.filters)
      yield put(loadSucceed(data))
      const matchName = (data.matchInfo || {}).name || ''
      const matchId = (data.matchInfo || {}).id || ''
      yield put(setMatchInfo(matchId, matchName))
      if (
        criteria.text === '' &&
        Object.keys(criteria.filters).length === 0 &&
        criteria.filters.constructor === Object &&
        data.filters.tags
      ) {
        yield put(addTags(entity, data.filters.tags))
      }
      if (action && (action.skip || 0) !== state.table.info.skip) {
        yield put(setSkip(action.skip || 0))
      }
      return true
    } else if (error) {
      yield call(globalError, data)
    }
  } catch (err) {
    console.error('Table loading error', err)
  } finally {
    yield put(setLoading(false))
  }
}

export function* reloadSaga() {
  const state = yield select()
  yield put(load(state.table.info.skip))
}

export function* saveCurrentFilterSaga(action) {
  const state = yield select()
  const { id, values } = action
  const fields = yield call(getFields, state.table.filters || {})
  const { name, search, ...rest } = values
  let searchField = '',
    searchText = ''
  if (search) {
    searchField = state.table.info.searchField
    searchText = state.table.info.searchText
  }
  const order = state.table.info.order
  if (id === '') {
    const { created, error, data } = yield http.post('/filters', {
      entity: state.table.info.entityType,
      name,
      ...rest,
      fields,
      order,
      search,
      searchField,
      searchText,
    })
    if (created) {
      yield put(loadFilters(true))
      yield take(types.CACHE_FILTERS_LOADED)
      yield put(setSavedFilter(data.id))
    } else if (error) {
      yield call(globalError, data)
    }
  } else {
    const { ok, error, data } = yield http.put(`/filters/${id}`, {
      entity: state.table.info.entityType,
      fields,
      ...rest,
      order,
      search,
      searchField,
      searchText,
    })
    if (ok) {
      yield put(loadFilters(true))
      yield take(types.CACHE_FILTERS_LOADED)
      yield put(setSavedFilter(id))
    } else if (error) {
      yield call(globalError, data)
    }
  }
}

export function* saveMatchingSaga(action) {
  const { context, id, values } = action
  let url
  if (context === 'company') {
    url = '/companies/matching'
  } else if (context === 'candidate') {
    url = `/candidates/${id}/matching`
  } else if (context === 'job') {
    url = `/jobs/${id}/matching`
  }
  const { ok, error, data } = yield http.post(url, values)
  if (ok) {
    if (context === 'candidate' || context === 'job') {
      yield put(load(0, true))
    }
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* setFilterListSaga(action) {
  const { filterList } = action
  if (filterList) {
    const state = yield select()
    const filters = state.table.data.filters
    if (Object.keys(filters).length === 0) {
      yield put(load(state.table.info.skip))
    }
  }
}

export function* setSavedFilterSaga(action) {
  const { id } = action
  const state = yield select()

  if (id === 'none' || !isMongoId(id)) {
    yield put(setSavedFilterFields([]))
  } else {
    // load cache
    if (state.cache.filters.list.length === 0) {
      yield put(loadFilters(true))
      yield take(types.CACHE_FILTERS_LOADED)
    }
    const newState = yield select()
    const savedFilter = newState.cache.filters.list.filter(
      (filter) => filter.id === id
    )[0]
    if (savedFilter) {
      yield put(setSavedFilterFields(savedFilter.fields))
      if (savedFilter.search) {
        yield put(setSearchField(savedFilter.searchField))
        yield put(setSearchText(savedFilter.searchText))
      }
      if (savedFilter.order) {
        yield put(setOrder(savedFilter.order))
      }
    }
  }
  yield put(load(state.table.info.skip))
}
