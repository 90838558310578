import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const NoteOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M14,10H19.5L14,4.5V10M5,3H15L21,9V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3M5,5V19H19V12H12V5H5Z" />
  </SvgIcon>
)

export default React.memo(NoteOutline)
