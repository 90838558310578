import * as types from './types'
import { createAction } from 'Utils/redux'

export const activate = createAction(types.USER_ACTIVATE, 'payload')
export const create = createAction(types.USER_CREATE, 'payload')
export const createViewer = createAction(types.USER_CREATE_VIEWER, 'payload')
export const deleteMyPhoto = createAction(types.USER_DELETE_MY_PHOTO)
export const deactivate = createAction(types.USER_DEACTIVATE, 'payload')
export const deleteMyPhotoSucceed = createAction(
  types.USER_DELETE_MY_PHOTO_SUCCEED
)
export const edit = createAction(types.USER_EDIT, 'payload')
export const getMy = createAction(types.USER_GET_MY)
export const invite = createAction(types.USER_INVITE, 'id')
export const logLocation = createAction(types.USER_LOG_LOCATION, 'location')
export const putMy = createAction(types.USER_PUT_MY, 'values')
export const putMyTheme = createAction(
  types.USER_PUT_MY_THEME,
  'theme',
  'remove'
)
export const removeViewer = createAction(types.USER_REMOVE_VIEWER, 'payload')
export const saveDefaultTemplate = createAction(
  types.USER_SAVE_DEFAULT_TEMPLATE,
  'payload'
)
export const saveFilter = createAction(types.USER_SAVE_FILTER, 'payload')

export const setMyData = createAction(types.USER_SET_MY_DATA, 'data')
export const setSaving = createAction(types.USER_SET_SAVING, 'saving')
export const signout = createAction(types.USER_SIGNOUT, 'sessionId')
export const terminate = createAction(types.USER_TERMINATE, 'payload')
export const updateViewer = createAction(types.USER_UPDATE_VIEWER, 'payload')
export const uploadMyPhoto = createAction(types.USER_UPLOAD_MY_PHOTO, 'file')
export const uploadMyPhotoSucceed = createAction(
  types.USER_UPLOAD_MY_PHOTO_SUCCEED,
  'url'
)
