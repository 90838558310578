import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const InboxArrowUp = (props) => (
  <SvgIcon {...props}>
    <path d="M14,14H10V11H8L12,7L16,11H14V14M16,11M5,15V5H19V15H15A3,3 0 0,1 12,18A3,3 0 0,1 9,15H5M19,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3" />{' '}
  </SvgIcon>
)

export default React.memo(InboxArrowUp)
