import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import ReactCodeInput from 'react-code-input'

const styles = () => ({})

class PinCodeField extends Component {
  state = {
    value: '',
  }
  handleChange = (value) => {
    this.setState({ value })
    const { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }

  render() {
    return (
      <ReactCodeInput
        inputMode="numeric"
        autoFocus={false}
        value={this.state.value}
        onChange={this.handleChange}
      />
    )
  }
}

export default withStyles(styles)(PinCodeField)
