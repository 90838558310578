import React, { Component } from 'react'
import { render } from 'react-dom'
import compose from 'recompose/compose'
import { Provider, connect } from 'react-redux'
import { ThemeProvider, withTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import numeral from 'numeral'
import moment from 'moment'
import 'Utils/moment/nl'
import IntlProvider from './IntlProvider'
import Message from './Message'
import DarkModus from './DarkModus'
import { startVersionCheck } from 'Actions/app'
import Loading from './Loading'

import IntlApp from './IntlApp'
import configureStore from '../store/configureStore'
import { validateToken } from 'Actions/auth'
import { setOnline } from 'Actions/app'
import { createTheme } from 'Selectors/app'

import '!file-loader?name=[name].[ext]!../images/android-chrome-192x192.png'
import '!file-loader?name=[name].[ext]!../images/apple-touch-icon.png'
import '!file-loader?name=[name].[ext]!../images/browserconfig.xml'
import '!file-loader?name=[name].[ext]!../images/favicon-16x16.png'
import '!file-loader?name=[name].[ext]!../images/favicon-32x32.png'
import '!file-loader?name=[name].[ext]!../images/favicon.ico'
import '!file-loader?name=[name].[ext]!../images/site.webmanifest'

import '../styles/forceflow.css'

const { store } = configureStore()

numeral.register('locale', 'nl', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    return number === 1 ? 'ste' : 'e'
  },
  currency: {
    symbol: '€',
  },
})
numeral.localeData('en').currency.symbol = '€'
moment.updateLocale('nl', {
  monthsShort: moment.monthsShort('-MMM-'),
  weekdaysShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
})
moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: function (number, withoutSuffix) {
      return withoutSuffix ? 'now' : 'a few seconds'
    },
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mth',
    MM: '%dmth',
    y: '1y',
    yy: '%dy',
  },
})
moment.updateLocale('nl', {
  relativeTime: {
    future: 'over %s',
    past: '%s geleden',
    s: function (number, withoutSuffix) {
      return withoutSuffix ? 'nu' : 'een paar seconde'
    },
    m: '1m',
    mm: '%dm',
    h: '1u',
    hh: '%du',
    d: '1d',
    dd: '%dd',
    M: '1mnd',
    MM: '%dmnd',
    y: '1j',
    yy: '%dj',
  },
})

class ThemableApp extends Component {
  componentDidMount = () => {
    this.props.validateToken()
    const online = window.navigator.onLine
    window.addEventListener('offline', this.handleOnlineChange)
    window.addEventListener('online', this.handleOnlineChange)
    this.props.setOnline(online)
    this.props.startVersionCheck()
  }

  componentWillUnmount = () => {
    window.removeEventListener('offline', this.handleOnlineChange)
    window.removeEventListener('online', this.handleOnlineChange)
  }

  handleOnlineChange = () => {
    const online = window.navigator.onLine
    this.props.setOnline(online)
    return Promise.resolve('Dummy response to keep the console quiet')
  }

  render() {
    const { noRender, theme } = this.props
    console.log('Render Index')
    return !noRender ? (
      <IntlProvider>
        <DarkModus>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Message />
            <IntlApp />
          </ThemeProvider>
        </DarkModus>
      </IntlProvider>
    ) : (
      <Loading />
    )
  }
}

const Wrapper = compose(
  withTheme,
  connect(
    (state) => ({
      language: state.app.language,
      theme: createTheme(state),
      noRender: typeof state.auth.info.authenticated === 'undefined',
    }),
    {
      setOnline,
      startVersionCheck,
      validateToken,
    }
  )
)(ThemableApp)

const App = () => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Provider store={store}>
      <Wrapper />
    </Provider>
  </MuiPickersUtilsProvider>
)
render(<App />, document.getElementById('app'))
