import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GenderFemale = (props) => (
  <SvgIcon {...props}>
    <path d="M12,4A6,6 0 0,1 18,10C18,12.97 15.84,15.44 13,15.92V18H15V20H13V22H11V20H9V18H11V15.92C8.16,15.44 6,12.97 6,10A6,6 0 0,1 12,4M12,6A4,4 0 0,0 8,10A4,4 0 0,0 12,14A4,4 0 0,0 16,10A4,4 0 0,0 12,6Z" />
  </SvgIcon>
)

export default React.memo(GenderFemale)
