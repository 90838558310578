import { createSelector } from 'reselect'

const getFilter = (state) => state.template.filter
const getMedia = (state) => state.template.medias
const getSubTypes = (state) => state.cache.lookups.templateSubType
const getTypes = (state) => state.cache.lookups.templateType
const getContext = (state) => state.cache.lookups.templateContext
const getRoles = (state) => state.cache.lookups.role
const getCompanyList = (state) => state.cache.entities.companies || []
const getTo = (state) => state.cache.lookups.templateTo
const getLanguage = (state) => state.app.language
const getTemplateList = (state) => state.cache.templates || []
const getTemplateById = (state, id) =>
  (state.cache.templates || []).find((x) => x.id === id)
const getTemplateAttachments = (state, context) => {
  const attachments = state.cache.lookups.templateAttachment
  const result = {}
  for (const key in attachments) {
    if (attachments[key].child.includes(context)) {
      result[key] = { ...attachments[key] }
    }
  }
  return result
}
const getUploadedAttachments = (state) =>
  state.template.data.uploadedAttachments
const getPdfAttachments = (state, context, language) => {
  const contexts = [context]
  if (context === 'job') {
    contexts.push('candidate')
  }
  return (state.cache.templates || [])
    .filter(
      (template) =>
        template.type === 'pdf' && contexts.includes(template.context)
    )
    .map((template) => ({
      deleted: template.deleted,
      id: template.id,
      name:
        (
          (template.locale || []).find((item) => item.language === language) ||
          {}
        ).name || '(No name)',
    }))
}
const getCompanyString = (list, companies) => {
  if (list && list.length > 0) {
    return companies
      .map((company) => {
        const find = (list.find((x) => x.id === company) || {}).name
        return find || company
      })
      .sort()
      .join(', ')
  }
  return ''
}

const getRoleString = (list, roles) => {
  let result = []
  if (list && roles) {
    for (const role of roles) {
      if (list[role]) {
        result.push(list[role].name)
      }
    }
  }
  return result.join(', ')
}

export const getAttachments = createSelector(
  [getTemplateAttachments, getUploadedAttachments, getPdfAttachments],
  (templateAttachments, uploadedAttachments, pdfAttachments) => {
    const result = templateAttachments
    for (const uploaded of uploadedAttachments) {
      const id = `c-${uploaded.id}`
      result[id] = {
        id,
        mutation: {},
        name: uploaded.name,
        order: -1,
        icon: 'AttachFile',
      }
    }
    for (const pdf of pdfAttachments) {
      const id = `b-${pdf.id}`
      result[id] = {
        id,
        mutation: { deleted: pdf.deleted },
        name: pdf.name,
        order: Object.keys(result).length + 1,
        icon: 'Print',
      }
    }
    return result
  }
)

const getTemplates = (state, tab) => {
  if (tab === 'deactivated') {
    return (state.cache.templates || []).filter((template) => template.deleted)
  }
  return (state.cache.templates || []).filter(
    (template) => !template.deleted && template.type === tab
  )
}

export const mediaList = createSelector(
  [getMedia, getFilter],
  (media, filter) =>
    media.filter((x) => {
      if (!filter) {
        return true
      }
      return x.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    })
)
export const templateList = createSelector(
  [
    getTemplates,
    getLanguage,
    getTypes,
    getSubTypes,
    getContext,
    getTo,
    getCompanyList,
    getRoles,
    getFilter,
  ],
  (
    templates,
    language,
    types,
    subTypes,
    context,
    to,
    companies,
    roles,
    filter
  ) => {
    const list = templates
      .map((template) => {
        const locale = template.locale.find((x) => x.language === language)
        return {
          companies: getCompanyString(companies, template.companies),
          hasAttachments: template.hasAttachments,
          id: template.id,
          name: locale
            ? locale.name
            : template.locale[0]
            ? template.locale[0].name
            : '',
          roles: getRoleString(roles, template.roles || []),
          type: types && template.type ? (types[template.type] || {}).name : '',
          subType:
            subTypes && template.subType
              ? (subTypes[template.subType] || {}).name
              : '',
          languages: template.languages,
          context:
            context && template.context
              ? (context[template.context] || {}).name
              : '',
          to: to && template.entity ? (to[template.entity] || {}).name : '',
          deleted: template.deleted,
          visible: template.visible,
        }
      })
      .filter((x) => {
        if (!filter) {
          return true
        }
        return x.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
      })
    return list
  }
)

export const templateActivated = createSelector(
  [getTemplateList, getMedia],
  (templates, media) => {
    return {
      email: templates.filter(
        (template) => template.type === 'email' && !template.deleted
      ).length,
      pdf: templates.filter(
        (template) => template.type === 'pdf' && !template.deleted
      ).length,
      filename: templates.filter(
        (template) => template.type === 'filename' && !template.deleted
      ).length,
      deactivated: templates.filter((template) => template.deleted).length,
      media: media.length,
    }
  }
)

export const getTemplateName = createSelector(
  [getTemplateById, getLanguage],
  (template, language) => {
    if (template) {
      const locale = template.locale.find((x) => x.language === language)
      if (locale) {
        return locale.name
      } else if (template.locale[0]) {
        return template.locale[0].name
      }
    }
    return ''
  }
)
