import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({
  refreshKey,
  directStart,
  isAuthenticated,
  isEnded,
  component: Component,
  ...rest
}) => {
  let redirect = ''
  if (
    rest.location &&
    rest.location.pathname !== '/' &&
    rest.location.pathname.startsWith('/')
  ) {
    redirect = `?redirect=${rest.location.pathname}${rest.location.search}`
  }
  const renderRoute = (props) => {
    if (
      (isEnded || directStart) &&
      rest.location.pathname !== '/subscription'
    ) {
      return <Route render={() => <Redirect to="/subscription" />} />
    }
    if (!isAuthenticated) {
      return <Route render={() => <Redirect to={`/signin${redirect}`} />} />
    }
    return <Component {...props} />
  }
  return <Route {...rest} render={renderRoute} key={refreshKey} />
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.info.authenticated,
  isEnded: state.auth.info.subscriptionEnded,
  directStart: state.auth.info.directStart,
  refreshKey: state.auth.info.refresh,
})

export default connect(mapStateToProps)(PrivateRoute)
