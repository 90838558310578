import React, { useEffect } from 'react'

const PageFocus = ({ onFocus, children }) => {
  const hasWindow = typeof window !== 'undefined'
  const isSupported = hasWindow && Boolean(window.addEventListener)

  useEffect(() => {
    function handleFocus() {
      onFocus()
    }
    if (isSupported) {
      window.addEventListener('focus', handleFocus)
    }
    return () => {
      if (isSupported) {
        window.removeEventListener('focus', handleFocus)
      }
    }
  }, [isSupported, onFocus])

  return React.Children.only(children)
}

PageFocus.displayName = 'PageFocus'

export default PageFocus
