import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const CheckboxCircleBlank = (props) => (
  <SvgIcon {...props}>
    <path d="M12 20a8 8 0 01-8-8 8 8 0 018-8 8 8 0 018 8 8 8 0 01-8 8m0-18A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2z" />
  </SvgIcon>
)

export default React.memo(CheckboxCircleBlank)
