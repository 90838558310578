import { call, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import {
  getList,
  getListSucceed,
  getLogSucceed,
  setLoading,
} from 'Actions/automation'
import { globalError } from './shell'
import { hide } from 'redux-modal'

export function* getListSaga() {
  yield put(setLoading(true))
  const { ok, error, data } = yield http.get(`/automations`)
  if (ok) {
    yield put(getListSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setLoading(false))
}

export function* getLogSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/automations/${id}/log`)
  if (ok) {
    yield put(getLogSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* startSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.post(`/automations/${id}/start`)
  if (ok) {
    yield put(getList())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* stopSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.post(`/automations/${id}/stop`)
  if (ok) {
    yield put(getList())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* updateSettingsSaga(action) {
  const { automation, settings } = action
  const { ok, error, data } = yield http.put(
    `/automations/${automation}`,
    settings
  )
  if (ok) {
    yield put(getList())
    yield put(hide('automationSettings'))
  } else if (error) {
    yield call(globalError, data)
  }
}
