import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagCzech = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <path fill="#0052B4" d="M0 85.337h512v341.326H0z" />
    <path fill="#D80027" d="M512 256v170.663H0L215.185 256z" />
    <path fill="#FFF" d="M512 85.337V256H215.185L0 85.337z" />
  </SvgIcon>
)

export default React.memo(FlagCzech)
