export const translate = (intl) => {
  const t = (id, values) => (intl ? intl.formatMessage({ id }, values) : '')
  return t
}

export const exists = (intl) => {
  const t = (id) => (intl ? !!intl.messages[id] : false)
  return t
}

export const getEntity = (intl, entity, plural = false) => {
  const t = translate(intl)
  if (plural) {
    return t(`global.entity.plural.${entity}`)
  }
  return t(`global.entity.single.${entity}`)
}
