import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiTooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(13),
    maxWidth: (props) => props.maxWidth,
  },
}))
const Tooltip = ({
  children,
  delay = 1000,
  title,
  placement = 'bottom',
  maxWidth,
}) => {
  const classes = useStyles({ maxWidth })
  if (title) {
    return (
      <MuiTooltip
        classes={{ tooltip: classes.tooltip }}
        enterDelay={delay}
        placement={placement}
        title={title}
        disableFocusListener
      >
        {children}
      </MuiTooltip>
    )
  } else {
    return children
  }
}

export default Tooltip
