import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagNepal = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <path fill="#FFF" d="M0 85.337h512v341.326H0z" />
    <path fill="#e05206" d="M0 85.337h512v113.775H0z" />
    <path fill="#0db02b" d="M0 312.888h512v113.775H0z" />
    <circle fill="#e05206" cx={256} cy={256} r={32} />
  </SvgIcon>
)

export default React.memo(FlagNepal)
