import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagRussia = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <path fill="#FFF" d="M0 85.33v341.332h512V85.33z" />
    <path fill="#0052B4" d="M0 85.333h512V426.67H0z" />
    <path fill="#FFF" d="M0 85.333h512v113.775H0z" />
    <path fill="#D80027" d="M0 312.884h512v113.775H0z" />
  </SvgIcon>
)

export default React.memo(FlagRussia)
