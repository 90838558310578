import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const CertificateOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M13 21l2-1 2 1v-7h-4m4-5V7l-2 1-2-1v2l-2 1 2 1v2l2-1 2 1v-2l2-1m1-7H4a2 2 0 00-2 2v10a2 2 0 002 2h7v-2H4V5h16v10h-1v2h1a2 2 0 002-2V5a2 2 0 00-2-2m-9 5H5V6h6m-2 5H5V9h4m2 5H5v-2h6z" />
  </SvgIcon>
)

export default React.memo(CertificateOutline)
