import { createSelector } from 'reselect'
import { hasRoleAdmin } from './auth'
import { toArray } from 'Utils/lookup'

const getActiveFilter = (state) => state.table.info.filter
const getFilters = (state) => state.table.filters || {}
const isAdmin = (state) => hasRoleAdmin(state)
const getCacheFilters = (state) => state.cache.filters.list
const getCurrentEntityType = (state) => state.table.info.entityType
const getSearchField = (state) => state.cache.lookups.searchField
const getRows = (state) => state.table.data.rows || []
const getSelected = (state) => state.table.data.selected
const getOrder = (state) => state.table.info.order
const getShowSortDate = (state) => state.auth.user.settings.showSortDate
const getFilter = (state) => state.table.info.filter
const getOptimizeGrids = (state) => state.auth.user.settings.optimizeGrids

const getSelectionFilters = (state) =>
  (state.cache.filters.list || []).filter((filter) => filter.mainBar)
const getMailboxes = (state) =>
  toArray(state.cache.lookups.mailbox).filter((x) => !x.mutation.deleted)

export const hasFilter = createSelector([getFilters], (filters) => {
  for (const filter in filters) {
    if (
      Object.prototype.hasOwnProperty.call(filters, filter) &&
      (filters[filter].selected.length > 0 ||
        (filters[filter].inversed || []).length > 0)
    ) {
      return true
    }
  }
  return false
})

export const canDeleteFilter = createSelector(
  [getCacheFilters, getActiveFilter, hasRoleAdmin],
  (filters, filter, admin) => {
    let everybody = true
    if (filters.length > 0 && filter && filter !== 'none') {
      const selected = filters.find((x) => x.id === filter)
      if (selected) {
        everybody = selected.everybody
      }
    }
    return (everybody && admin) || !everybody
  }
)

export const getEntityFilters = createSelector(
  [getCacheFilters, getCurrentEntityType],
  (filters, entity) => {
    if (!filters) {
      return []
    }
    return filters.filter((filter) => filter.entity === entity)
  }
)

export const getOrderField = createSelector(
  [getOrder, getShowSortDate],
  (order, showSort) => {
    const field = (order || '').replace('Asc', '').replace('Desc', '')
    if (!showSort || !field || field === 'default') {
      return ''
    }
    return field
  }
)

export const getSavedFilters = createSelector(
  [getCacheFilters, getCurrentEntityType, isAdmin],
  (filters, entity, admin) => {
    if (!filters) {
      return []
    }
    return filters.filter(
      (filter) =>
        filter.entity === entity &&
        ((filter.everybody && admin) || !filter.everybody)
    )
  }
)

export const getSearchFields = createSelector(
  [getSearchField, getCurrentEntityType],
  (lookup, type) => {
    if (!lookup) {
      return []
    }
    return toArray(lookup)
      .filter((field) => field.child.includes(type))
      .map(({ id, description }) => ({ id, description }))
  }
)

export const getRepients = createSelector(
  [getRows, getSelected],
  (rows, selected) => {
    return rows
      .filter(
        (row) =>
          selected.includes(row.id) && (row.email || row.primaryContactEmail)
      )
      .map((row) => ({
        id: row.primaryContactEmail ? row.primaryContact : row.id,
        type: row.primaryContactEmail ? 'contact' : row.type,
        email: row.primaryContactEmail || row.email,
        name: row.primaryContactName || row.emailName || row.name,
      }))
  }
)

export const getFilterName = createSelector(
  [getFilter, getSelectionFilters, getMailboxes],
  (filter, selectionFilters, mailboxes) => {
    let description
    if (filter && filter !== 'none') {
      description = (mailboxes.find((x) => x.id === filter) || {}).description
      if (!description) {
        description = (selectionFilters.find((x) => x.id === filter) || {}).name
      }
    }
    return description
  }
)

export const getFilterTags = createSelector([getFilters], (filters) => {
  return (filters.tags || {}).selected || []
})

export const getFilterTalentpools = createSelector([getFilters], (filters) => {
  return (filters.talentpools || {}).selected || []
})

export const getLimit = createSelector([getOptimizeGrids], (optimizeGrid) => {
  return optimizeGrid ? 50 : 100
})
