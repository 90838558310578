import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const SilverWare = (props) => (
  <SvgIcon {...props}>
    <path d="M8.1 13.34L3.91 9.16a4.008 4.008 0 010-5.66l7.02 7-2.83 2.84m6.78-1.81L13.41 13l6.88 6.88-1.41 1.41L12 14.41l-6.88 6.88-1.41-1.41 9.76-9.76c-.71-1.53-.21-3.68 1.38-5.27 1.91-1.92 4.65-2.28 6.11-.82 1.47 1.47 1.11 4.21-.81 6.12-1.59 1.59-3.74 2.09-5.27 1.38z" />
  </SvgIcon>
)

export default React.memo(SilverWare)
