import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagBulgaria = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <path fill="#496E2D" d="M0 85.337h512v341.326H0z" />
    <path fill="#FFF" d="M0 85.337h512v113.775H0z" />
    <path fill="#D80027" d="M0 312.888h512v113.775H0z" />
  </SvgIcon>
)

export default React.memo(FlagBulgaria)
