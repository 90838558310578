import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const PlayBack = (props) => (
  <SvgIcon {...props}>
    <path d="M16 19.14v-14l-11 7 11 7z" />
  </SvgIcon>
)

export default React.memo(PlayBack)
