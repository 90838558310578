import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const themeReducer = createReducer(initialState.theme, {
  [types.THEME_SET_THEME]: (state, action) => {
    // const palette = deepmerge({}, state.palette)
    const palette = state.palette
    return {
      ...state,
      palette: {
        ...palette,
        type: action.theme,
      },
    }
  },
  [types.THEME_SET_THEME_COLORS]: (state, { primary, secondary }) => {
    const palette = state.palette
    return {
      ...state,
      palette: {
        ...palette,
        primary,
        secondary,
      },
    }
  },
})

export default themeReducer
