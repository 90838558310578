import * as types from './types'
import { createAction } from 'Utils/redux'

export const getActivities = createAction(
  types.ACTIVITY_GET_ACTIVITIES,
  'user',
  'more'
)
export const getActivitiesSucceed = createAction(
  types.ACTIVITY_GET_ACTIVITIES_SUCCEED,
  'data'
)
export const setActiveUser = createAction(
  types.ACTIVITY_SET_ACTIVE_USER,
  'user'
)
export const setFilter = createAction(types.ACTIVITY_SET_FILTER, 'filter')
