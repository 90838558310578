import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagBangladesh = (props) => (
  <SvgIcon viewBox="0 85.333 513 342" {...props}>
    <path fill="#2d6e41" d="M0 85.331h513v342H0z" />
    <circle fill="#F40B32" cx={218.902} cy={256.5} r={115} />
  </SvgIcon>
)

export default React.memo(FlagBangladesh)
