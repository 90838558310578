import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Key = (props) => (
  <SvgIcon {...props}>
    <path d="M7 14a2 2 0 01-2-2 2 2 0 012-2 2 2 0 012 2 2 2 0 01-2 2m5.65-4A5.99 5.99 0 007 6a6 6 0 00-6 6 6 6 0 006 6 5.99 5.99 0 005.65-4H17v4h4v-4h2v-4H12.65z" />
  </SvgIcon>
)

export default React.memo(Key)
