import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagMalaysia = (props) => (
  <SvgIcon viewBox="0 0 513 342" {...props}>
    <path fill="#FFF" d="M0 0h513v342H0z" />
    <path
      d="M0 0h513v24.4H0zm0 48.9h513v24.4H0zm0 48.8h513v24.4H0zm0 48.9h513V171H0zm0 48.8h513v24.4H0zm0 48.9h513v24.4H0zm0 48.8h513v24.4H0z"
      fill="#c00"
    />
    <path fill="#006" d="M0 0h256.5v195.4H0z" />
    <path
      d="M153.3 42.1C122.6 30.7 88.5 46.3 77.1 77s4.2 64.8 34.9 76.2c13.3 5 28 5 41.4 0-30.7 24.5-75.4 19.6-100-11.1s-19.6-75.4 11.1-100c26-20.7 62.9-20.7 88.8 0zm26.7 75l-20.6 23.3 5.4-30.6-31-1.6 27.3-14.9L143 68l28.6 12 8.4-29.9 8.4 29.9L217 68l-18 25.4 27.3 14.9-31 1.6 5.4 30.6-20.7-23.4z"
      fill="#fc0"
    />
  </SvgIcon>
)

export default React.memo(FlagMalaysia)
