import * as types from './types'
import { createAction } from 'Utils/redux'

export const acceptCandidate = createAction(
  types.SHARE_ACCEPT_CANDIDATE,
  'payload'
)

export const getCandidate = createAction(
  types.SHARE_GET_CANDIDATE,
  'user',
  'id',
  'candidate'
)

export const getCandidateSucceed = createAction(
  types.SHARE_GET_CANDIDATE_SUCCEED,
  'data'
)
export const getCandidates = createAction(
  types.SHARE_GET_CANDIDATES,
  'user',
  'id'
)
export const getCandidatesSucceed = createAction(
  types.SHARE_GET_CANDIDATES_SUCCEED,
  'data'
)
export const getNotes = createAction(
  types.SHARE_GET_NOTES,
  'user',
  'id',
  'candidate'
)
export const getNotesSucceed = createAction(
  types.SHARE_GET_NOTES_SUCCEED,
  'data'
)
export const getPhases = createAction(
  types.SHARE_GET_PHASES,
  'user',
  'id',
  'language'
)
export const getPhasesSucceed = createAction(
  types.SHARE_GET_PHASES_SUCCEED,
  'data'
)
export const getRejectReasons = createAction(
  types.SHARE_GET_REJECT_REASONS,
  'user',
  'id',
  'language'
)
export const getRejectReasonsSucceed = createAction(
  types.SHARE_GET_REJECT_REASONS_SUCCEED,
  'data'
)
export const rejectCandidate = createAction(
  types.SHARE_REJECT_CANDIDATE,
  'payload'
)
export const setId = createAction(types.SHARE_SET_ID, 'value')
export const setNotFound = createAction(types.SHARE_SET_NOT_FOUND, 'value')
export const setShare = createAction(types.SHARE_SET_SHARE, 'data')
export const updateEvaluation = createAction(
  types.SHARE_UPDATE_EVALUATION,
  'payload'
)
export const updateCandidate = createAction(
  types.SHARE_UPDATE_CANDIDATE,
  'data'
)
export const updateNote = createAction(types.SHARE_UPDATE_NOTE, 'payload')
export const updateViewer = createAction(types.SHARE_UPDATE_VIEWER, 'payload')
