import { http } from 'Utils/http'
import { call, put } from 'redux-saga/effects'
import { globalError } from './shell'
import { updateField } from 'Actions/form'

export function* getByLineSaga(action) {
  const { address, form, prefix } = action
  const { ok, notFound, error, data } = yield http.post(`/addresses`, {
    address,
  })
  if (ok) {
    for (const field in data) {
      const value = data[field]
      if (value && typeof value === 'object' && value.constructor === Object) {
        for (const item in value) {
          const itemValue = value[item]
          yield put(
            updateField(
              { form, field: `${field}${prefix}.${item}`, touch: true },
              itemValue
            )
          )
        }
      } else {
        yield put(
          updateField({ form, field: `${field}${prefix}`, touch: true }, value)
        )
      }
    }
  } else if (notFound) {
    // do nothing
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getByZipSaga(action) {
  const { zipcode, number, country, form, prefix } = action
  const { ok, notFound, error, data } = yield http.get(
    `/addresses?zipcode=${encodeURIComponent(
      zipcode
    )}&number=${encodeURIComponent(number)}&country=${encodeURIComponent(
      country
    )}`
  )
  if (ok) {
    yield put(
      updateField(
        { form, field: `addressDetails${prefix}.street`, touch: true },
        data.street
      )
    )
    yield put(
      updateField(
        { form, field: `addressDetails${prefix}.city`, touch: true },
        data.city
      )
    )
  } else if (notFound) {
    // do nothing
  } else if (error) {
    yield call(globalError, data)
  }
}
