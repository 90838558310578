import * as types from './types'
import { createAction } from 'Utils/redux'

export const addFilterItem = createAction(
  types.TABLE_ADD_FILTER_ITEM,
  'filter',
  'id',
  'inverse'
)
export const addTagFilter = createAction(types.TABLE_ADD_TAG_FILTER, 'tag')
export const addTalentpoolFilter = createAction(
  types.TABLE_ADD_TALENTPOOL_FILTER,
  'talentpool'
)
export const addTags = createAction(types.TABLE_ADD_TAGS, 'payload')
export const clearFilters = createAction(types.TABLE_CLEAR_FILTERS, 'value')
export const copy = createAction(types.TABLE_COPY, 'tree', 'entity', 'entityId')
export const deleteCurrentFilter = createAction(
  types.TABLE_DELETE_CURRENT_FILTER
)
export const deselectItem = createAction(types.TABLE_DESELECT_ITEM, 'id')
export const deleteFilter = createAction(types.TABLE_DELETE_FILTER, 'filter')
export const deleteFilters = createAction(
  types.TABLE_DELETE_FILTERS,
  'resetTable'
)
export const deleteItems = createAction(types.TABLE_DELETE_ITEMS, 'anonymize')
export const exportShowDialog = createAction(
  types.TABLE_EXPORT_SHOW_DIALOG,
  'plural'
)
export const exportSelection = createAction(
  types.TABLE_EXPORT_SELECTION,
  'language',
  'users'
)
export const getInsightsUrl = createAction(types.TABLE_GET_INSIGHTS_URL, 'url')
export const getLastNote = createAction(
  types.TABLE_GET_LAST_NOTE,
  'id',
  'resolve'
)
export const getMatching = createAction(
  types.TABLE_GET_MATCHING,
  'context',
  'id',
  'defaultValues'
)
export const getMatchingSucceed = createAction(
  types.TABLE_GET_MATCHING_SUCCEED,
  'data'
)
export const insertItem = createAction(
  types.TABLE_INSERT_ITEM,
  'entityType',
  'data'
)
export const load = createAction(types.TABLE_LOAD_INIT, 'skip', 'force')
export const loadSucceed = createAction(types.TABLE_LOAD, 'data')
export const reload = createAction(types.TABLE_RELOAD)
export const removeFilterItem = createAction(
  types.TABLE_REMOVE_FILTER_ITEM,
  'filter',
  'id',
  'name'
)
export const removeItem = createAction(types.TABLE_REMOVE_ITEM, 'id')
export const removeItems = createAction(types.TABLE_REMOVE_ITEMS, 'ids')
export const resetCopy = createAction(types.TABLE_RESET_COPY, 'entity')
export const restoreCopy = createAction(types.TABLE_RESTORE_COPY, 'tree')
export const reset = createAction(types.TABLE_RESET)
export const resetSearch = createAction(types.TABLE_RESET_SEARCH)
export const saveCurrentFilter = createAction(
  types.TABLE_SAVE_CURRENT_FILTER,
  'id',
  'values'
)
export const saveMatching = createAction(
  types.TABLE_SAVE_MATCHING,
  'context',
  'id',
  'values'
)
export const selectAll = createAction(types.TABLE_SELECT_ALL)
export const selectFilterItem = createAction(
  types.TABLE_SELECT_FILTER_ITEM,
  'filter',
  'id'
)
export const selectItem = createAction(
  types.TABLE_SELECT_ITEM,
  'id',
  'multiSelect'
)
export const setDashboardFilter = createAction(
  types.TABLE_SET_DASHBOARD_FILTER,
  'data'
)

export const setFilter = createAction(
  types.TABLE_SET_FILTER,
  'filter',
  'selected',
  'inversed'
)
export const setFilterList = createAction(
  types.TABLE_SET_FILTER_LIST,
  'filterList'
)
export const setFilterSearch = createAction(
  types.TABLE_SET_FILTER_SEARCH,
  'value'
)
export const setInitdata = createAction(types.TABLE_SET_INITDATA)
export const setInsights = createAction(types.TABLE_SET_INSIGHTS, 'data')
export const setLoading = createAction(types.TABLE_SET_LOADING, 'loading')
export const setMatchInfo = createAction(
  types.TABLE_SET_MATCH_INFO,
  'id',
  'name'
)
export const setMatchingLoaded = createAction(
  types.TABLE_SET_MATCHING_LOADED,
  'value'
)
export const setOrder = createAction(types.TABLE_SET_ORDER, 'value')
export const setPrevLocation = createAction(
  types.TABLE_SET_PREVLOCATION,
  'prevLocation'
)
export const setSavedFilter = createAction(types.TABLE_SET_SAVED_FILTER, 'id')
export const setSavedFilterFields = createAction(
  types.TABLE_SET_SAVED_FILTER_FIELDS,
  'fields'
)
export const setSavedFilterNone = createAction(
  types.TABLE_SET_SAVED_FILTER_NONE
)
export const setSearchField = createAction(
  types.TABLE_SET_SEARCH_FIELD,
  'field'
)
export const setSkip = createAction(types.TABLE_SET_SKIP, 'value')
export const setScrollTop = createAction(types.TABLE_SET_SCROLLTOP, 'scrollTop')
export const setSearchText = createAction(
  types.TABLE_SET_SEARCH_TEXT,
  'searchText'
)
export const setSelected = createAction(types.TABLE_SET_SELECTED, 'selected')
export const setTab = createAction(types.TABLE_SET_TAB, 'tab')
export const setType = createAction(types.TABLE_SET_TYPE, 'entityType')

export const sortSelected = createAction(types.TABLE_SORT_SELECTED)
export const toggleFilterOperator = createAction(
  types.TABLE_TOGGLE_FILTER_OPERATOR,
  'filter'
)
export const toggleFilterPanel = createAction(
  types.TABLE_TOGGLE_FILTER_PANEL,
  'filter'
)
export const typeSet = createAction(types.TABLE_TYPE_SET, 'entityType')
