import * as types from './types'
import { createAction } from 'Utils/redux'

export const getList = createAction(types.AUTOMATION_GET_LIST)
export const getListSucceed = createAction(
  types.AUTOMATION_GET_LIST_SUCCEED,
  'data'
)
export const getLog = createAction(types.AUTOMATION_GET_LOG, 'id')
export const getLogSucceed = createAction(
  types.AUTOMATION_GET_LOG_SUCCEED,
  'data'
)
export const setLoading = createAction(types.AUTOMATION_SET_LOADING, 'value')
export const start = createAction(types.AUTOMATION_START, 'id')
export const stop = createAction(types.AUTOMATION_STOP, 'id')

export const updateSettings = createAction(
  types.AUTOMATION_UPDATE_SETTINGS,
  'automation',
  'settings'
)
