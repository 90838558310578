import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const SortAlphabeticalDescending = (props) => (
  <SvgIcon {...props}>
    <path d="M19 7h3l-4-4-4 4h3v14h2m-8-8v2l-3.33 4H11v2H5v-2l3.33-4H5v-2M9 3H7c-1.1 0-2 .9-2 2v6h2V9h2v2h2V5a2 2 0 00-2-2m0 4H7V5h2z" />
  </SvgIcon>
)

export default React.memo(SortAlphabeticalDescending)
