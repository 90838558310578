import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FileTreeOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M12 13H7v5h5v2H5V10h2v1h5v2M8 4v2H4V4h4m2-2H2v6h8V2m10 9v2h-4v-2h4m2-2h-8v6h8V9m-2 9v2h-4v-2h4m2-2h-8v6h8v-6z" />
  </SvgIcon>
)

export default React.memo(FileTreeOutline)
