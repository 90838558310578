export function capitalize(string) {
  if (!string) {
    return string
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const truncate = (text, length) => {
  if (length === 0) {
    return text
  }
  if (length === null) {
    length = 100
  }
  const result = text.replace(/\n/g, ' ')
  if (result.length > length) {
    return result.substring(0, length - 3) + '...'
  } else {
    return result
  }
}

export const isMongoId = (id) => {
  const check = new RegExp('^[0-9a-fA-F]{24}$')
  return check.test(id)
}

export const isNullOrWhiteSpace = (str) => {
  return (
    str === null ||
    str === undefined ||
    str.trim().length === 0 ||
    !str.match(/^[\S\s]*\S[\S\s]*$/)
  )
}

export const stringToArray = (str) =>
  [].concat(str && str.match(/ /) ? str.split(/ \s?/) : str).filter(Boolean)

export const find = (str, markWord) => {
  const results = []

  if (!markWord || !markWord.trim()) {
    return results
  }

  let maxResults = 10
  let slicedStr = str.toLowerCase()
  const lowercasedMarkWord = markWord.trim().toLowerCase()

  let cut = 0
  while (slicedStr.indexOf(lowercasedMarkWord) !== -1 && maxResults > 0) {
    const foundResult = slicedStr.indexOf(lowercasedMarkWord)
    results.push(foundResult + cut)
    cut += foundResult + lowercasedMarkWord.length
    slicedStr = slicedStr.slice(cut)
    maxResults -= 1
  }

  return results.sort()
}

export const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
