import { createSelector } from 'reselect'
import { toArray } from 'Utils/lookup'
import { role } from 'Utils/constants'

const getRole = (state) => state.auth.user.role
const getPhasesLookup = (state) => state.cache.lookups.phase
const getUserPhases = (state) => state.auth.user.phases
const getLookup = (state) => state.lookup.list
const getChild = (state) => state.lookup.child
const getLookupDef = (state, props) =>
  state.cache.lookups['lookupList']
    ? state.cache.lookups['lookupList'][props.list]
    : null
const getLookupList = (state, list) => state.cache.lookups[list]

export const getListItems = createSelector([getLookup], (items) => {
  return items
})

export const countInactiveItems = createSelector(
  [getLookup],
  (items) => items.filter((x) => x.mutation.deleted).length
)

export const getChildListItems = createSelector([getChild], (items) =>
  items.filter((x) => !x.mutation.deleted)
)

export const hasDeletedItems = createSelector([getLookup], (items) =>
  items.some((x) => x.mutation.deleted)
)

export const hasElements = createSelector([getLookupList], (lookup) => {
  if (!lookup) {
    return false
  }
  if (Object.entries(lookup).length === 0 && lookup.constructor === Object) {
    return false
  }
  const elements = toArray(lookup).filter((x) => !x.mutation.deleted)
  return elements.length
})

export const getTitle = createSelector([getLookupDef], (lookup) => {
  if (lookup) {
    return lookup.name
  }
  return 'Unkown'
})

export const canManage = createSelector(
  [getLookupDef, getRole],
  (lookup, userRole) => {
    if (userRole !== role.admin) {
      return false
    }
    if (lookup) {
      return !lookup.system
    }
    return false
  }
)

export const getDefaultIcon = createSelector([getLookupDef], (lookup) =>
  lookup ? lookup.icon : ''
)

export const getPhases = createSelector(
  [getPhasesLookup, getUserPhases],
  (lookup, user) => {
    if ((user || []).length === 0) {
      return lookup
    }
    const result = {}
    const phases = user.filter((x) => x.visible).map((x) => x.id)
    for (const key in lookup) {
      if (phases.includes(key)) {
        result[key] = lookup[key]
      }
    }
    return result
  }
)
