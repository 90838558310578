import { call, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import {
  addItemSucceed,
  deleteItemSucceed,
  getLookup,
  importExcelUploading,
  moveItemIntern,
  restoreItemSucceed,
  setCountries,
  setLanguage,
  setLanguages,
  setLookup,
  updateItemSucceed,
} from 'Actions/lookup'
import { showMessage } from 'Actions/shell'
import { loadLookups } from 'Actions/cache'
import { confirmSaga, globalError, messageSaga } from './shell'
import { t } from 'Root/app/IntlProvider'
import { messageLevel } from 'Utils/constants'
import { toArray } from 'Utils/lookup'

export function* addItemSaga(action) {
  const { language, list, name } = action
  const { created, error, data } = yield http.post(
    `/lookups/${list}/${language}`,
    { name }
  )
  if (created) {
    yield put(getLookup(list, language))
    yield put(addItemSucceed(list, data.value, name))
  } else if (error) {
    if (data.error === 'Item exists') {
      yield put(
        showMessage(t('lookup.message.item.exists'), messageLevel.error)
      )
    } else if (data.error === 'Item exists in deleted') {
      yield put(
        showMessage(
          t('lookup.message.item.existsInDeleted'),
          messageLevel.error
        )
      )
    } else {
      yield call(globalError, data)
    }
  }
}

export function* deleteItemSaga(action) {
  const { list, value, language } = action
  const ok = yield call(confirmSaga, {
    title: t('lookup.message.delete.title'),
    content: t('lookup.message.delete.content'),
  })
  if (!ok) {
    return
  }
  const { noContent, data } = yield http.delete(`/lookups/${list}/${value}`)
  if (noContent) {
    yield put(getLookup(list, language))
    yield put(deleteItemSucceed(list, value))
  } else {
    yield call(globalError, data)
  }
}

export function* getLookupSaga(action) {
  const { lookup, language } = action
  const { ok, data } = yield http.get(`/lookups/${lookup}/${language}`)
  if (ok) {
    // yield put(setLookup([], []))
    yield put(setLookup(toArray(data.lookup), toArray(data.child)))
    yield put(setLanguage(language))
  } else {
    yield call(globalError, data)
  }
}

export function* importExcelSaga(action) {
  const { language, list, ...rest } = action.payload
  const name = rest.name || ''
  if (
    name.toLowerCase() !== `${list}.xls` &&
    name.toLowerCase() !== `${list}.xlsx`
  ) {
    return yield call(messageSaga, {
      title: t('lookup.message.import.title'),
      content: t('lookup.message.import.content', { list }),
    })
  }

  yield put(importExcelUploading(true))
  const { ok, error, data } = yield http.post(
    `/lookups/${list}/import/${language}`,
    {
      ...rest,
    }
  )
  if (ok) {
    // reload lookups
    yield put(loadLookups())
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(importExcelUploading(false))
}

export function* loadCountriesSaga() {
  const { ok, data } = yield http.get(`/lookups/country`)
  if (ok) {
    yield put(setCountries(data))
  }
}

export function* loadLanguagesSaga() {
  const { ok, data } = yield http.get(`/lookups/language`)
  if (ok) {
    yield put(setLanguages(data))
  }
}

export function* moveItemSaga(action) {
  const { language, list, value, newPosition, oldPosition } = action
  yield put(moveItemIntern(list, value, oldPosition, newPosition, language))
  const { ok, data, error } = yield http.put(`/lookups/move/${list}/${value}`, {
    order: newPosition,
  })
  if (ok) {
    yield put(getLookup(list, language))
    yield put(loadLookups())
    //yield put(moveItemIntern(list, value, newPosition, oldPosition, language))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* restoreAllSaga(action) {
  const { language, list } = action
  const yes = yield call(confirmSaga, {
    title: t('lookup.message.lookup.restoreAll.title'),
    content: t('lookup.message.lookup.restoreAll.content'),
  })
  if (!yes) {
    return
  }
  const { ok, error, data } = yield http.post(`/lookups/restore/${list}`)
  if (ok) {
    yield put(getLookup(list, language))
    yield put(loadLookups())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* restoreItemSaga(action) {
  const { language, list, value } = action
  const { ok, error, data } = yield http.post(
    `/lookups/restore/${list}/${value}`
  )
  if (ok) {
    yield put(getLookup(list, language))
    yield put(restoreItemSucceed(list, value))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* sortListSaga(action) {
  const { language, list } = action
  const yes = yield call(confirmSaga, {
    title: t('lookup.message.sort.title'),
    content: t('lookup.message.sort.content'),
  })
  if (!yes) {
    return
  }
  const { ok, data } = yield http.post(
    `/lookups/${list}/${language}/sort-items`
  )
  if (ok) {
    yield put(getLookup(list, language))
  } else {
    yield call(globalError, data)
  }
}

export function* updateItemSaga(action) {
  const { child, code, color, info, language, list, name, required, value } =
    action
  const newColor = color ? (color === 'none' ? null : color) : null
  const { ok, error, data } = yield http.put(
    `/lookups/${list}/${value}/${language}`,
    {
      child,
      code,
      color: newColor,
      info,
      name,
      required,
    }
  )
  if (ok) {
    yield put(setLookup(toArray(data.lookup), toArray(data.child)))
    yield put(
      updateItemSucceed(
        list,
        value,
        name,
        info,
        child,
        newColor,
        code,
        required
      )
    )
  } else if (error) {
    if (data.error === 'Item exists') {
      yield put(getLookup(list, language))
      yield put(
        showMessage(t('lookup.message.item.exists'), messageLevel.error)
      )
    } else if (data.error === 'Item exists in deleted') {
      yield put(getLookup(list, language))
      yield put(
        showMessage(
          t('lookup.message.item.existsInDeleted'),
          messageLevel.error
        )
      )
    } else {
      yield call(globalError, data)
    }
  }
}
