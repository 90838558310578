import React, { Fragment } from 'react'
import { hide } from 'redux-modal'
import moment from 'moment'
import { SubmissionError } from 'redux-form'
import { show } from 'redux-modal'
import Typography from '@material-ui/core/Typography'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { call, delay, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import {
  activateInfoSucceed,
  activateSetResend,
  cancelChangeEmailSucceed,
  changeCompany,
  changeCompanySucceed,
  forceRefresh,
  getAccounts,
  getAccountsSucceed,
  getAccountStatusSucceed,
  getSubscription,
  initSession,
  initSessionSucceed,
  initializeSystemSucceed,
  previewModeSucceed,
  registerNylasBusy,
  sendVerificationCode,
  setCompanySetSso,
  setNewEmail,
  setPartner,
  setQrCode,
  setSessions,
  setSubscription,
  setTwoFactorEnabled,
  signinSucceed,
  signoutSucceed,
  sourcesLoad,
  sourcesSetData,
  sourcesSetLoading,
  tagsLoad,
  tagsSetData,
  tagsSetLoading,
  updateInfo,
} from 'Actions/auth'
import { loadingUsers } from 'Actions/cache'
import { showMessage } from 'Actions/shell'
import { gotoUrl } from 'Actions/app'
import { getMy } from 'Actions/user'
import { confirmSaga, globalError, messageSaga } from './shell'
import { t } from 'Root/app/IntlProvider'
import { messageLevel } from 'Utils/constants'
import { capitalize } from 'Utils/string'
import storage from 'Utils/storage'

const noBusinessEmail = (
  <Fragment>
    <Typography component="div">
      <FormattedMessage id="auth.checkEmail.error.provider.info" />
    </Typography>
    <Typography component="div">
      <FormattedHTMLMessage id="auth.checkEmail.error.provider.support" />
    </Typography>
  </Fragment>
)

export function* activateSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { ok, badRequest, data } = yield http.post('/auth/activate', {
    ...values,
  })
  if (ok) {
    yield call(resolve)
    yield call(signinOkSaga, data)
  } else if (badRequest) {
    yield put(
      showMessage(t('auth.activate.error.token.invalid'), messageLevel.error)
    )
    yield call(reject)
  } else {
    yield call(globalError, data)
  }
}

export function* activateInfoSaga(action) {
  const { token } = action
  const { data, badRequest, notFound, ok } = yield http.get(
    `/auth/activate/${token}`
  )
  if (ok) {
    const { autoSendSms, canResend, ...values } = data

    yield put(activateInfoSucceed(values))
    if (autoSendSms) {
      yield put(sendVerificationCode(token))
    }
    if (canResend) {
      yield delay(7000)
      yield put(activateSetResend(true))
    }
  } else if (notFound) {
    yield put(
      showMessage(t('auth.activate.error.token.unknown'), messageLevel.error)
    )
  } else if (badRequest) {
    yield put(
      showMessage(
        t('auth.activate.error.general.activated'),
        messageLevel.error
      )
    )
    yield put(gotoUrl({ url: '/' }))
  } else {
    yield call(globalError, data)
  }
}

export function* cancelChangeEmailSaga({ account }) {
  const ok = yield call(confirmSaga, {
    title: t('auth.changeEmail.confirm.title'),
    content: t('auth.changeEmail.confirm.content'),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete(
    `/auth/change-email?account=${account}`
  )
  if (noContent) {
    // ok
    yield put(cancelChangeEmailSucceed(account))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* changeCompanySaga(action) {
  const { id } = action
  const {
    ok,
    data = {},
    badRequest,
  } = yield http.get(`/auth/change-company/${id}`)
  if (ok) {
    // set token
    const { token } = data
    storage.set('token', token)
    yield call(initSessionSaga)
    yield put(changeCompanySucceed(id))
    yield put(forceRefresh())
    yield delay(1000)
  } else if (badRequest) {
    if (data.error === 'Company not found') {
      yield put(
        showMessage(
          t('auth.changeEmail.error.email.invalid'),
          messageLevel.error
        )
      )
    } else if (data.error === 'Unauthorized') {
      yield put(
        showMessage(
          t('auth.changeCompany.error.unauthorized'),
          messageLevel.error
        )
      )
    } else {
      yield call(globalError, data)
    }
  }
  yield put(hide('changeCompany'))
}

export function* changeEmailSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { account, email } = values
  const { ok, data, badRequest } = yield http.post('/auth/change-email', values)
  if (ok) {
    yield put(
      showMessage(t('auth.changeEmail.info.send', { email }), messageLevel.info)
    )
    yield put(setNewEmail(email, account))
    yield call(resolve)
  } else if (badRequest) {
    if (data.error === 'Invalid Email address') {
      yield put(
        showMessage(
          t('auth.changeEmail.error.email.invalid'),
          messageLevel.error
        )
      )
      yield call(reject)
    } else if (data.error === 'In use') {
      yield put(
        showMessage(t('auth.changeEmail.error.email.inUse'), messageLevel.error)
      )
      yield call(reject)
    } else if (data.error === 'Invalid domain') {
      yield put(showMessage(noBusinessEmail, messageLevel.error, 10000))
      yield call(reject)
    } else if (data.error === 'Invalid sourcer domain') {
      yield put(
        showMessage(
          t('auth.changeEmail.error.email.invalidSourcerEmail'),
          messageLevel.error,
          5000
        )
      )
      yield call(reject)
    }
  } else {
    yield call(globalError, data, reject)
  }
}

export function* changePasswordSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { ok, data, badRequest } = yield http.post(
    '/auth/change-password',
    values
  )
  if (ok) {
    yield put(
      showMessage(t('auth.changePassword.info.succeed'), messageLevel.info)
    )
    yield call(resolve)
  } else if (badRequest) {
    yield put(
      showMessage(
        t('auth.changePassword.error.oldPassword.invalid'),
        messageLevel.warning
      )
    )
    yield call(reject)
  } else {
    yield call(globalError, data, reject)
  }
}

export function* checkEmailSaga(action) {
  const {
    payload: { email, resolve, reject },
  } = action
  const { ok, data } = yield http.post('/auth/check-email', {
    email,
  })
  if (ok) {
    yield call(resolve)
  } else if (data.error === 'Invalid Email address') {
    yield put(
      showMessage(t('auth.checkEmail.error.email.invalid'), messageLevel.error)
    )
    yield call(reject)
  } else if (data.error === 'Invalid domain') {
    yield put(showMessage(noBusinessEmail, messageLevel.error, 10000))
    yield call(reject)
  } else {
    return yield call(globalError, data, reject)
  }
}

export function* checkPasswordSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { ok, data } = yield http.post('/auth/check-password', values)
  if (ok) {
    yield call(resolve)
  } else if (data.error == 'Unauthorized') {
    yield put(
      showMessage(
        t('auth.twoFactor.error.password.invalid'),
        messageLevel.warning
      )
    )
    yield call(reject)
  } else {
    yield call(globalError, data, reject)
  }
}

export function* connectNylasSaga(action) {
  const { accountId, email } = action.payload
  const url = accountId ? `renew/${accountId}` : `${encodeURIComponent(email)}`
  const { ok, data } = yield http.get(`/nylas/url/${url}`)
  if (ok) {
    const { url } = data
    window.location.href = url
  } else {
    yield call(globalError, data)
  }
}

export function* getAccountsSaga() {
  const { ok, data } = yield http.get('/nylas/accounts')
  if (ok) {
    yield put(getAccountsSucceed(data))
  } else {
    yield call(globalError, data)
  }
}

export function* getAccountStatusSaga(action) {
  const { accountId } = action
  const { ok, data } = yield http.get(`/nylas/accounts/${accountId}/status`)
  if (ok) {
    yield put(getAccountStatusSucceed(data))
  } else {
    yield call(globalError, data)
  }
}

export function* getPartnerSaga(action) {
  const { id } = action
  const { ok, data } = yield http.get(`/auth/partner/${id}`)
  if (ok) {
    yield put(setPartner(data))
  }
}

export function* getQrCodeSaga(action) {
  const { password } = action
  const { ok, data } = yield http.post('/auth/twofactor-qrcode', { password })
  if (ok) {
    yield put(setQrCode(data))
  } else {
    yield call(globalError, data)
  }
}

export function* getSessionsSaga() {
  try {
    yield put(loadingUsers(true))
    const { ok, data } = yield http.get('/auth/signed-in-list')
    if (ok) {
      yield put(setSessions(data))
    } else {
      yield call(globalError, data)
    }
  } finally {
    yield put(loadingUsers(false))
  }
}

export function* getSubscriptionSaga() {
  try {
    yield put(loadingUsers(true))
    const { ok, data } = yield http.get('/companies/subscription')
    if (ok) {
      yield put(setSubscription(data))
    } else {
      yield call(globalError, data)
    }
  } finally {
    yield put(loadingUsers(false))
  }
}

export function* initializeSystem(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { ok, error, data } = yield http.post('/auth/initialize', values)
  if (ok) {
    yield put(initializeSystemSucceed())
    yield put(initSession())
    yield call(resolve)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* initSessionSaga() {
  const { ok, error, data } = yield http.get('/auth/info')
  if (ok) {
    yield put(initSessionSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* previewModeSaga() {
  const { ok, data } = yield http.get(`/auth/preview-mode`)
  if (ok) {
    yield put(previewModeSucceed(data.previewMode))
  } else {
    yield call(globalError, data)
  }
}

export function* putSubscriptionSaga(action) {
  const {
    payload: { demo, values, resolve, reject },
  } = action
  const { ok, data } = yield http.put('/companies/subscription', values)
  if (ok) {
    yield call(resolve)
    yield put(
      updateInfo({ demo: false, directStart: false, subscriptionEnded: false })
    )
    //yield put(setSubscription(data))
    yield put(getSubscription())
    if (demo) {
      yield put(gotoUrl({ url: '/' }))
    }
  } else {
    yield call(globalError, data, reject)
  }
}

export function* registerNylasSaga(action) {
  const { code, state } = action
  yield put(registerNylasBusy(true))
  const { badRequest, ok, data } = yield http.post('/nylas/register', {
    code,
    state,
  })
  if (ok) {
    if (data.success) {
      // reload user info
      yield put(getAccounts())
      yield put(getMy())
    } else {
      // do nothing
    }
  } else if (badRequest) {
    yield put(
      showMessage(t('auth.nylas.error.register.invalid'), messageLevel.error)
    )
  } else {
    yield call(globalError, data)
  }
  yield put(registerNylasBusy(false))
}

export function* removeAccountSaga(action) {
  const { accountId, email } = action
  const ok = yield call(confirmSaga, {
    title: t('auth.profile.tabs.sync.account.message.remove.title'),
    content: t('auth.profile.tabs.sync.account.message.remove.content', {
      email,
    }),
  })
  if (!ok) {
    return
  }
  yield put(registerNylasBusy(true))
  const { noContent, error, data } = yield http.delete(
    `/nylas/accounts/${accountId}`
  )
  if (noContent) {
    // ok
    yield put(getAccounts())
    yield put(getMy())
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(registerNylasBusy(false))
}

export function* removeEmail2Saga({ email }) {
  const ok = yield call(confirmSaga, {
    title: t('auth.changeEmail.message.delete.title'),
    content: t('auth.changeEmail.message.delete.content', { email }),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete('/auth/email2')
  if (noContent) {
    // ok
    yield put(getMy())
    yield put(hide('changeEmail'))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* resetPasswordSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { ok, badRequest, unauthorized, data } = yield http.post(
    '/auth/reset-password',
    values
  )
  if (ok) {
    yield call(resolve)
    yield call(signinOkSaga, data)
  } else if (badRequest) {
    if (data.error === 'Token is expired') {
      yield put(
        showMessage(
          t('auth.resetPassword.error.link.expired'),
          messageLevel.error
        )
      )
      yield call(reject)
    } else if (data.error === 'Token not valid') {
      yield put(
        showMessage(
          t('auth.resetPassword.error.link.invalid'),
          messageLevel.error
        )
      )
      yield call(reject)
    } else if (data.error === 'Only Sso') {
      yield put(
        showMessage(t('auth.resetPassword.error.link.sso'), messageLevel.error)
      )
      yield call(reject)
    } else if (data.error === '2fa code missing') {
      yield put(
        showMessage(t('auth.resetPassword.password.changed'), messageLevel.info)
      )
      yield call(resolve)
      yield put(gotoUrl({ url: '/' }))
    } else {
      yield call(globalError, data, reject)
    }
  } else if (unauthorized) {
    yield call(reject)
  } else {
    yield call(globalError, data, reject)
  }
}

export function* sendPasswordLinkSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const email = values.email
  const { ok, data, unauthorized } = yield http.post(
    '/auth/send-password-link',
    { email }
  )
  if (ok) {
    yield put(
      showMessage(
        <Typography component="div">
          <FormattedHTMLMessage
            id="auth.changePassword.message.send"
            values={{ email }}
          />
        </Typography>,
        messageLevel.info,
        15000
      )
    )
    yield put(gotoUrl({ url: '/' }))
    yield call(resolve)
  } else if (unauthorized && data.error === 'sso') {
    yield put(
      showMessage(t('auth.changePassword.message.sso'), messageLevel.error)
    )
    yield put(gotoUrl({ url: '/sso' }))
    yield call(resolve)
  } else {
    yield call(globalError, data, reject)
  }
}

export function* sendRecoveryCodeSaga(action) {
  const { ok } = yield http.post(`/auth/recovery`, action.payload)
  if (ok) {
    yield put(showMessage(t('auth.signin.message.recovery.sent'), 'info'))
  } else {
    yield put(
      showMessage(t('auth.signin.message.recovery.error'), messageLevel.error)
    )
  }
}

export function* sendVerificationCodeSaga(action) {
  const { token } = action
  const { data, ok } = yield http.post(`/auth/send-verification`, {
    token,
  })
  if (ok) {
    // yield put(showMessage(translate({ id: 'auth.activate.message.verification.sent' }), 'info'))
  } else {
    yield call(globalError, data)
  }
}

export function* setSsoSaga(action) {
  const { payload } = action
  const { ok, data } = yield http.put('/auth/sso', payload)
  if (ok) {
    yield put(
      setCompanySetSso({
        ssoEnabled: payload.enabled,
        ssoForced: payload.force,
      })
    )
  } else {
    yield call(globalError, data)
  }
}

export function* showSsoSaga() {
  const { ok, data } = yield http.get('/auth/sso')
  if (ok) {
    yield put(show('sso', { initialValues: data }))
  } else {
    yield call(globalError, data)
  }
}

function* signinOkSaga(action) {
  const {
    demo,
    directStart,
    email,
    token,
    redirect,
    role = 'user',
    subscriptionDaysRemaining,
    subscriptionEnded,
    initialized = true,
    userId,
  } = action
  storage.set('token', token)
  storage.set('email', email)
  yield put(
    signinSucceed({
      user: { email, role, id: userId },
      info: {
        demo,
        directStart,
        subscriptionDaysRemaining,
        subscriptionEnded,
        initialized,
      },
    })
  )
  let url = '/'
  if (redirect && redirect.startsWith('/')) {
    url = redirect
  }
  yield put(gotoUrl({ url }))
}

export function* signinSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { ok, badRequest, unauthorized, data } = yield http.post(
    '/auth/signin',
    values
  )
  if (ok) {
    yield call(resolve)
    yield call(signinOkSaga, {
      ...data,
      email: values.email,
      redirect: values.redirect,
    })
  } else {
    yield call(signinResultSaga, {
      data,
      reject,
      resolve,
      unauthorized,
      badRequest,
    })
  }
}

export function* signinResultSaga(values) {
  const { data, reject, resolve, unauthorized, badRequest } = values
  if (unauthorized) {
    let msg = t('auth.signin.error.signin.invalid')
    switch (data.error) {
      case 'Ended':
        msg = (
          <Typography component="div">
            <FormattedHTMLMessage id="auth.signin.error.signin.ended" />
          </Typography>
        )
        break
      case 'Blocked':
        msg = (
          <Typography component="div">
            <FormattedHTMLMessage id="auth.signin.error.signin.blocked" />
          </Typography>
        )
        break
      case 'SsoEmail':
        msg = t('auth.signin.error.signin.ssoEmail')
        break
      case 'SsoUnauthorized':
        msg = t('auth.signin.error.signin.ssoUnauthorized', {
          email: data.email,
        })
        break
      case 'OnlySso':
        msg = t('auth.signin.error.signin.onlySso')
        break
      case 'SsoError':
        msg = t('auth.signin.error.signin.ssoError', {
          provider: data.provider,
        })
        break
    }
    yield put(showMessage(msg, messageLevel.error, 10000))
    if (reject) {
      yield call(reject)
    }
  } else if (badRequest) {
    if (
      data.error === 'Invalid company' ||
      data.error === 'Invalid companyId format' ||
      data.error === 'Invalid tenant'
    ) {
      yield put(
        showMessage(
          t('auth.signin.error.companyId.invalid'),
          messageLevel.warning
        )
      )
    } else if (data.error === 'Code not valid') {
      yield put(
        showMessage(t('auth.signin.error.code.invalid'), messageLevel.warning)
      )
    } else if (data.error === '2fa code missing') {
      if (resolve) {
        return yield call(resolve, true)
      }
    } else if (data.error === 'Password too long (max. 200 characters)') {
      yield put(
        showMessage(t('auth.signin.error.signin.invalid'), messageLevel.warning)
      )
    } else {
      yield put(
        showMessage(
          t('auth.signin.error.signin.unknown', { error: data.error }),
          messageLevel.error
        )
      )
    }
    if (reject) {
      yield call(reject)
    }
  } else {
    yield call(globalError, data, reject)
  }
}

export function* signoutSaga(action = {}) {
  if (!action.skipCall) {
    yield http.post('/auth/signout')
  }
  storage.remove('token')
  yield put(signoutSucceed())
  yield put(gotoUrl({ url: '/' }))
}

export function* signupSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { ok, data } = yield http.post('/auth/signup', values)
  if (ok) {
    yield call(resolve)
  } else if (data.error === 'Invalid previewcode') {
    yield put(showMessage(t('auth.signup.error.code.invalid'), 'error'))
    yield call(
      reject,
      new SubmissionError({
        code: t('auth.signup.error.email.invalid'),
      })
    )
  } else if (data.error === 'Invalid domain') {
    yield put(showMessage(noBusinessEmail, messageLevel.error, 10000))
    yield call(
      reject,
      new SubmissionError({
        email: t('auth.signup.error.email.invalid'),
      })
    )
  } else if (
    data.error === 'Invalid Captcha' ||
    data.error === 'Captcha is required'
  ) {
    yield put(showMessage(t('auth.signup.error.captcha.invalid'), 'error'))
    yield call(reject)
  } else if (data.error === 'Invalid Email address') {
    yield put(showMessage(t('auth.signup.error.email.invalid'), 'error'))
    yield call(
      reject,
      new SubmissionError({
        email: t('auth.signup.error.email.invalid'),
      })
    )
  } else if (data.error === 'Not a mobile number') {
    yield put(showMessage(t('auth.signup.error.phone.invalid'), 'error'))
    yield call(
      reject,
      new SubmissionError({
        phone: t('auth.signup.error.phone.invalid'),
      })
    )
  } else if (data.error === 'Partner not found') {
    yield put(showMessage(t('auth.signup.error.partner.invalid'), 'error'))
    yield call(
      reject,
      new SubmissionError({
        phone: t('auth.signup.error.partner.invalid'),
      })
    )
  } else {
    yield call(globalError, data, reject)
  }
}

export function* ssoSigninSaga({ payload }) {
  const { provider, redirect } = payload
  // remove token
  storage.remove('token')
  const email = payload.email
    ? `&email=${encodeURIComponent(payload.email)}`
    : ''
  const domain = payload.domain ? `&domain=${payload.domain}` : ''
  const { ok, data, badRequest } = yield http.get(
    `/auth/sso/${provider}?redirect=${redirect || ''}${email}${domain}`
  )
  if (ok) {
    const { url } = data
    window.location.href = url
  } else if (badRequest) {
    if (data.error === 'Unknown email') {
      yield put(showMessage(t('auth.sso.error.unknownEmail'), 'error'))
    } else if (data.error === 'Unknown domain') {
      yield put(showMessage(t(''), 'error'))
    }
  } else {
    yield call(globalError, data)
  }
}

export function* ssoSiginGetRedirect(state) {
  const { ok, data } = yield http.get(`/auth/sso/redirect/${state}`)
  if (ok) {
    const redirect = data.redirect
    if (redirect !== '/' && redirect.startsWith('/')) {
      return `?redirect=${redirect}`
    }
  }
  return ''
}

export function* ssoSigninFailedSaga(action) {
  const { provider = '', error, description, state } = action
  const redirect = yield call(ssoSiginGetRedirect, state)
  switch (error) {
    case 'user_cancelled_login':
      yield put(
        showMessage(
          t('auth.sso.error.cancelled', {
            provider: capitalize(provider),
          }),
          messageLevel.info
        )
      )
      break
    default:
      yield put(
        showMessage(
          t('auth.sso.error.unknown', {
            provider: capitalize(provider),
            description,
          }),
          messageLevel.info
        )
      )
      break
  }
  if (['', 'google', 'linkedin'].includes(provider)) {
    yield put(gotoUrl({ url: `/signin${redirect}` }))
  } else {
    yield put(gotoUrl({ url: `/sso${redirect}` }))
  }
}

export function* ssoSigninSucceedSaga(action) {
  const { code, provider, state } = action
  const redirect = yield call(ssoSiginGetRedirect, state)
  const { ok, data, unauthorized, badRequest } = yield http.post(
    `/auth/sso/${provider}`,
    {
      code,
      provider,
      state,
    }
  )
  if (ok) {
    return yield call(signinOkSaga, {
      ...data,
      email: data.email,
      redirect: redirect,
    })
  } else {
    yield call(signinResultSaga, { data, unauthorized, badRequest })
  }
  yield put(gotoUrl({ url: `/signin${redirect}` }))
}

export function* stopSubscriptionSaga() {
  const date = moment().endOf('month')
  const yes = yield call(confirmSaga, {
    title: t('auth.company.tab.subscription.confirm.stop.title'),
    content: t('auth.company.tab.subscription.confirm.stop.content', {
      date: moment(date).format('D-MM-YYYY'),
    }),
  })
  if (!yes) {
    return
  }
  const { ok, data } = yield http.post('/auth/stop-subscription')
  if (ok) {
    yield put(getSubscription())
  } else {
    yield call(globalError, data)
  }
}

export function* sourcesLoadSaga(action) {
  const { entity } = action
  const { ok, data } = yield http.get(`/sources/${entity}`)
  yield put(sourcesSetLoading(true))
  if (ok) {
    yield put(sourcesSetData(entity, data))
  } else {
    yield call(globalError, data)
  }
  yield put(sourcesSetLoading(false))
}

export function* sourcesRemoveSaga({ entity, source }) {
  const ok = yield call(confirmSaga, {
    title: t('auth.sources.confirm.remove.title'),
    content: t('auth.sources.confirm.remove.content', { source }),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.post(
    `/sources/remove/${entity}`,
    {
      source,
    }
  )
  if (noContent) {
    // ok
    yield put(sourcesLoad(entity))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* sourcesRenameSaga({ entity, from, to }) {
  const { ok, error, data } = yield http.put(`/sources/${entity}`, {
    from,
    to,
  })
  if (ok) {
    // ok
    yield put(sourcesLoad(entity))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* tagsLoadSaga(action) {
  const { entity } = action
  const { ok, data } = yield http.get(`/tags/${entity}`)
  yield put(tagsSetLoading(true))
  if (ok) {
    yield put(tagsSetData(entity, data))
  } else {
    yield call(globalError, data)
  }
  yield put(tagsSetLoading(false))
}

export function* tagsRemoveSaga({ entity, tag }) {
  const ok = yield call(confirmSaga, {
    title: t('auth.tags.confirm.remove.title'),
    content: t('auth.tags.confirm.remove.content', { tag }),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.post(`/tags/remove/${entity}`, {
    tag,
  })
  if (noContent) {
    // ok
    yield put(tagsLoad(entity))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* tagsRenameSaga({ entity, from, to }) {
  const { ok, error, data } = yield http.put(`/tags/${entity}`, {
    from,
    to,
  })
  if (ok) {
    // ok
    yield put(tagsLoad(entity))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* twoFactorEnableSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { ok, data } = yield http.post('/auth/twofactor', values)
  if (ok) {
    yield put(setTwoFactorEnabled(values.enabled))
    yield call(resolve)
  } else if (data.error === 'Invalid code') {
    yield put(showMessage(t('auth.twoFactor.error.code.invalid'), 'error'))
    yield call(reject)
  } else {
    yield call(globalError, data, reject)
  }
}

export function* uploadPhotoSaga(action) {
  const { entity, file } = action
  const ext = ((file || {}).name || '').toLowerCase().split('.').pop()
  const types = ['jpg', 'jpeg', 'png', 'bmp', 'webp', 'heic', 'heif', 'svg']
  if (!types.includes(ext)) {
    // message
    yield put(
      showMessage(
        t('common.form.upload.error.file', { types: types.join(', ') }),
        messageLevel.error
      )
    )
    return
  }
  const base = entity === 'user' ? 'users/profile' : 'companies/my'
  const { ok, error, data } = yield http.post(`/${base}/photo`, file)
  if (ok) {
    // yield put(uploadPhotoSucceed(entity, data.url))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* updateAccountSaga(action) {
  const { accountId, data: accountData } = action
  const { ok, error, data } = yield http.put(
    `/nylas/accounts/${accountId}`,
    accountData
  )
  if (ok) {
    // do nothing
    yield put(getMy())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* validateEmailSaga(action) {
  const { token } = action
  const { ok, badRequest, data } = yield http.post('/auth/validate-email', {
    token,
  })
  if (ok) {
    if (data.emailChanged) {
      const state = yield select()
      if (data.companyId && state.auth.companyId !== data.companyId) {
        yield put(changeCompany(data.companyId))
      }
      yield put(getMy())
      yield put(gotoUrl({ url: '/profile' }))
    } else {
      yield call(messageSaga, {
        title: t('auth.changeEmail.message.succeed.title'),
        content: t('auth.changeEmail.message.succeed.content', {
          email: data.email,
        }),
      })
      yield call(signinOkSaga, data)
    }
  } else if (badRequest) {
    yield call(messageSaga, {
      title: t('auth.changeEmail.message.invalidLink.title'),
      content: t('auth.changeEmail.message.invalidLink.content'),
    })
    yield put(gotoUrl({ url: '/profile' }))
  } else {
    yield call(globalError, data)
  }
}

export function* validateTokenSaga() {
  const token = storage.get('token')
  if (token) {
    const { data } = yield http.post('/auth/tokenvalid', { token })
    if (data && data.valid) {
      return yield put(
        signinSucceed({
          user: {
            id: data.userId,
            email: data.email,
            role: data.role,
          },
          info: {
            demo: data.demo,
            directStart: data.directStart,
            subscriptionEnded: data.subscriptionEnded,
            subscriptionDaysRemaining: data.subscriptionDaysRemaining,
            initialized: data.initialized,
          },
        })
      )
    }
  }
  storage.remove('token')
  yield put(signoutSucceed())
}
