import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const candidatesReducer = createReducer(initialState.relation.candidates, {
  [types.RELATION_GET_CANDIDATES]: (state, action) => ({
    ...state,
    list: action.data,
    loaded: true,
  }),
  [types.RELATION_SET_CANDIDATES_FILTER]: (state, { filter }) => ({
    ...state,
    filter,
  }),
  [types.RELATION_SET_CANDIDATES_LOADED]: (state, { value }) => ({
    ...state,
    loaded: value,
  }),
  [types.RELATION_GET_SHORTLIST]: (state, action) => ({
    ...state,
    shortlist: action.data,
    loaded: true,
  }),
  [types.RELATION_SHORTLIST_CLEAR_SELECT]: (state) => ({
    ...state,
    selected: [],
  }),
  [types.RELATION_SHORTLIST_DESELECT]: (state, { id }) => {
    const selection = [...state.selected].filter((x) => x !== id)
    return {
      ...state,
      selected: selection,
    }
  },
  [types.RELATION_SHORTLIST_INVERSE_SELECTION]: (state, { ids = [] }) => {
    const selected = ids.reduce((tot, item) => {
      if (!state.selected.includes(item.id)) {
        tot.push(item.id)
      }
      return tot
    }, [])
    return {
      ...state,
      selected,
    }
  },
  [types.RELATION_SHORTLIST_SELECT]: (state, { id }) => {
    const selection = [...state.selected]
    if (!selection.includes(id)) {
      selection.push(id)
    }
    return {
      ...state,
      selected: selection,
    }
  },
  [types.ENTITY_DESELECT_ITEMS]: (state) => ({
    ...state,
    selected: [],
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.relation.candidates,
})

const claimsReducer = createReducer(initialState.relation.claims, {
  [types.RELATION_SEARCH_CLAIMS_SUCCEED]: (state, action) => ({
    ...state,
    list: action.data,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.relation.claims,
})

const cocReducer = createReducer(initialState.relation.coc, {
  [types.RELATION_SEARCH_COC_SUCCEED]: (state, action) => ({
    ...state,
    list: action.data,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.relation.coc,
})

const jobsReducer = createReducer(initialState.relation.jobs, {
  [types.RELATION_GET_JOBS]: (state, action) => ({
    ...state,
    list: action.data,
    loaded: true,
  }),
  [types.RELATION_SET_JOBS_FILTER]: (state, { filter }) => ({
    ...state,
    filter,
  }),
  [types.RELATION_SET_JOBS_LOADED]: (state, { value }) => ({
    ...state,
    loaded: value,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.relation.jobs,
})

const sourcesReducer = createReducer(initialState.relation.sources, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.relation.sources,
  [types.AUTH_CHANGE_COMPANY_SUCCEED]: () => initialState.relation.sources,
  [types.RELATION_ADD_SOURCES]: (state, { data }) => ({
    ...state,
    data,
  }),
  [types.RELATION_SET_SOURCES_DIRTY]: (state, { value }) => ({
    ...state,
    dirty: value,
  }),
})

export default combineReducers({
  candidates: candidatesReducer,
  claims: claimsReducer,
  coc: cocReducer,
  jobs: jobsReducer,
  sources: sourcesReducer,
})
