import * as types from './types'
import { createAction } from 'Utils/redux'

export const add = createAction(types.NOTIFICATION_ADD, 'values')
export const getNew = createAction(types.NOTIFICATION_GET_NEW)
export const getNewSucceed = createAction(
  types.NOTIFICATION_GET_NEW_SUCCEED,
  'data'
)
export const getSubscriptions = createAction(
  types.NOTIFICATION_GET_SUBSCRIPTIONS,
  'role'
)
export const getSubscriptionsSucceed = createAction(
  types.NOTIFICATION_GET_SUBSCRIPTIONS_SUCCEED,
  'data',
  'role'
)
export const loadAll = createAction(types.NOTIFICATION_LOAD_ALL)
export const loadAllSucceed = createAction(
  types.NOTIFICATION_LOAD_ALL_SUCCEED,
  'data'
)
export const removeRead = createAction(types.NOTIFICATION_REMOVE_READ)
export const receiveMessage = createAction(
  types.NOTIFICATION_RECEIVE_MESSAGE,
  'payload'
)
export const setLoading = createAction(types.NOTIFICATION_SET_LOADING, 'value')
export const setRead = createAction(types.NOTIFICATION_SET_READ, 'id')
export const setReadAll = createAction(types.NOTIFICATION_SET_READ_ALL)
export const updateSubscription = createAction(
  types.NOTIFICATION_UPDATE_SUBSCRIPTION,
  'payload'
)
