import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Sorry = (props) => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <path d="M238 16c-51.1 7-93.1 36.9-116.2 82.4-10.9 21.7-15.3 41.2-15.2 67.6.1 18.2 1.6 28.8 6.4 44.1 2.1 7 2 7.3-3.9 8.4-10.5 2-24.6 13.2-29.2 23.2l-2.4 5.3H0v250h512V247h-77.5l-2.5-5.3c-4.8-10.3-18.5-21.2-29.1-23.2-5.9-1.1-6-1.4-3.9-8.4 5-15.9 6.4-25.8 6.4-45.1 0-15.4-.4-20.3-2.3-29-12.9-60.5-57.7-105.4-117.6-118-11.4-2.4-36.9-3.5-47.5-2zm41.8 31.4c36.7 8 66.9 31.3 83.6 64.3 15.1 30 16.5 67.5 3.7 98.3l-2.7 6.5-5.9.7c-16.2 1.8-30.6 11.6-36.8 25l-2.2 4.8h-127l-2.4-5c-6.5-13.7-20.3-23-36.6-24.8l-6-.7-3.3-8.3c-5.7-14.2-7.5-24.6-7.6-42.7-.1-12.1.4-18.1 1.8-24.5 5.1-23.6 16.1-43.7 33-60.6 19.8-19.6 41.6-30.3 71.1-34.8 6.1-.9 30.2.3 37.3 1.8zM157 249c4.5 2.3 8 8 8 13.1 0 4.6-3.9 10.8-8.2 13-3.2 1.7-6 1.9-21.9 1.9-16.9 0-18.4-.1-22.1-2.2-10.1-5.7-10.2-19.4-.2-25.4 3.7-2.3 4.7-2.4 22.2-2.4 16.4 0 18.7.2 22.2 2zm242 0c4.5 2.3 8 8 8 13.1 0 4.6-3.9 10.8-8.2 13-3.2 1.7-6 1.9-21.9 1.9-16.9 0-18.4-.1-22.1-2.2-10.1-5.7-10.2-19.4-.2-25.4 3.7-2.3 4.7-2.4 22.2-2.4 16.4 0 18.7.2 22.2 2zM79.9 282.2c3 6.5 12.8 16.4 19.4 19.7 9.1 4.4 13.9 5.1 35.7 5.1 21.6 0 26.7-.7 35.5-5 7-3.4 14.8-11.2 18.6-18.5l3.4-6.5h127l2.4 5.2c3 6.5 12.8 16.4 19.4 19.7 9.1 4.4 13.9 5.1 35.7 5.1 21.6 0 26.7-.7 35.5-5 7-3.4 14.8-11.2 18.6-18.5l3.4-6.5H482v190H30V277h47.5l2.4 5.2z" />
    <path d="M181 142v15h30v-30h-30v15zM301 142v15h30v-30h-30v15zM239.5 183.6c-8.5 2.1-19.4 7.7-25.4 13.2l-5.2 4.7 10.4 10.3 10.4 10.4 4.9-3.5c6.6-4.7 13-6.7 21.4-6.7 8.4 0 14.8 2 21.4 6.7l4.9 3.5 10.3-10.3c5.7-5.7 10.4-10.6 10.4-10.9-.1-.3-2.8-2.6-6.1-5.2-7.3-5.8-16.4-10.2-25-12.3-8-1.8-24.4-1.8-32.4.1zM91.5 324c-18.8 6-26.3 28-14.3 42.4 3.4 4.1 9.7 7.4 26.2 13.7 11 4.2 16.2 8.5 15.1 12.6-2.5 10.3-17.1 12.4-29.9 4.3l-4.8-3.1-5.7 5c-9.3 8.1-9.2 7.8-4.6 11.8 2.2 1.9 6.7 4.7 10 6.3 16.6 7.8 33.5 6.3 44.8-4 7.2-6.5 10-12.5 10.1-21 0-8.1-2.7-14.2-8.9-19.9-5.1-4.7-9.9-7.2-25.4-13.2-12.4-4.8-14.4-6.7-12.3-11.5 2.7-5.8 13.7-6.9 23.1-2.2l5.1 2.5 6.1-7.4c6.9-8.4 6.9-8.4-1.5-12.7-11.4-5.8-22.4-7-33.1-3.6zM174.2 323.4c-7.1 2.3-13.4 7.7-17.5 15.1-2.1 3.9-2.2 4.9-2.2 33.5 0 33.9 0 33.7 9.6 42.4 15.6 14 41.2 6.8 47.1-13.3 1.8-6.5 1.8-51.7 0-58.2-4.4-14.9-22.2-24.3-37-19.5zm15 21.5 3.3 2.9v48.4l-3.3 2.9c-4.3 3.9-8.4 3.9-12.3 0l-2.9-2.9v-48.4l2.9-2.9c3.9-3.9 8-3.9 12.3 0zM228 371.9V422h20l.2-16.4.3-16.4 12 15.5c6.5 8.4 12.5 15.9 13.2 16.4 1.1 1 7-2.9 15.6-10.2.5-.4-3.7-6.5-9.2-13.5-10-12.8-10.1-12.9-7.9-14.4 5.4-3.6 8.9-7.5 11.5-12.8 2.5-5.1 2.8-6.8 2.8-15.7 0-8.9-.3-10.6-2.8-15.7-3-6.1-7.4-10.5-14-14-3.8-2.1-5.8-2.3-22.9-2.6l-18.8-.4v50.1zm33.9-28.5c3.4 1.8 5.1 5.4 5.1 11.1 0 8.9-3.9 12.5-13.5 12.5H248v-25h5.5c3.1 0 6.8.6 8.4 1.4zM302 372v50h20l.2-16.6.3-16.6 12.9 16.6c7.1 9.1 13.1 16.6 13.4 16.6 1.1 0 15.2-11.4 15-12.1-.2-.5-4.5-6.3-9.7-13l-9.3-12 5.6-5.1c8.9-7.9 10.1-11 10.1-25.3 0-14.2-1.2-17.4-9.5-24.9-7.4-6.6-11.7-7.6-32.2-7.6H302v50zm36.1-27.1c2.6 2.7 2.9 3.6 2.9 9.6s-.3 6.9-2.9 9.6c-2.7 2.6-3.6 2.9-9.5 2.9H322v-25h6.6c5.9 0 6.8.3 9.5 2.9zM376.2 340.7c.3 16.3.7 19.4 2.4 23.4 2.8 6.1 7.4 11.1 12.9 14l4.5 2.4V422h20v-41.5l3.6-2c5.1-2.8 12-10.7 13.8-16.2 1.2-3.6 1.6-8.8 1.6-22.5V322h-20v34.2l-2.9 2.9c-3.1 3.1-6.4 3.6-10.5 1.8-4.6-2.1-5.6-6.2-5.6-23.3V322h-20.2l.4 18.7z" />
  </SvgIcon>
)

export default React.memo(Sorry)
