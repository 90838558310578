import { call, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { globalError } from './shell'
import {
  getItem,
  getItemSucceed,
  getListSucceed,
  loading,
  setHighlight,
  setSelected,
} from 'Actions/quick'

export function* getItemSaga(action) {
  const { entity, id } = action
  const state = yield select()
  const isNew = state.quick.selected.id !== id
  if (isNew) {
    yield put(setSelected({ entity, id }))
    yield put(loading(true))
  }
  const { ok, error, notFound, data } = yield http.get(
    `quick/item/${entity}/${id}`
  )
  if (ok) {
    yield put(getItemSucceed(data))
  } else if (notFound) {
    yield put(getItemSucceed({ notFound: true, entity }))
  } else if (error) {
    yield call(globalError, data)
  }
  if (isNew) {
    yield put(loading(false))
  }
}

export function* getListSaga(action) {
  const { tab, text = '' } = action
  yield put(loading(true))
  yield put(setHighlight(''))
  yield put(setSelected({}))
  const { ok, error, data } = yield text.length > 1
    ? http.post('quick/search', { tab, text })
    : http.get(`quick/recent/${tab}`)
  if (ok) {
    yield put(getListSucceed(data))
    if (data.length > 0) {
      yield put(setHighlight(text))
      yield put(getItem(data[0].entity, data[0].id))
    } else {
      yield put(setSelected({}))
    }
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(loading(false))
}

export function* pinItemSaga(action) {
  const { entity, id } = action
  const { ok, error } = yield http.post(`quick/item/${entity}/${id}/pin`)
  if (ok) {
    // do n othing
  } else if (error) {
    yield call(globalError)
  }
}

export function* unpinItemSaga(action) {
  const { entity, id } = action
  const { ok, error } = yield http.post(`quick/item/${entity}/${id}/unpin`)
  if (ok) {
    // do n othing
  } else if (error) {
    yield call(globalError)
  }
}
