import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Robin = (props) => (
  <SvgIcon viewBox="0 0 187.28 187.28" {...props}>
    <path
      d="M91.155 11.512v80.493h80.493c0-44.445-36.031-80.493-80.493-80.493Z"
      style={{
        fill: '#077d55',
      }}
    />
    <path
      d="M10.661 92.005V11.512h80.494c0 44.446-36.032 80.493-80.494 80.493Z"
      style={{
        fill: '#f688d0',
      }}
    />
    <path
      d="M10.661 92.005v80.494h80.494c0-44.444-36.032-80.494-80.494-80.494Z"
      style={{
        fill: '#7a58d0',
      }}
    />
  </SvgIcon>
)

export default React.memo(Robin)
