import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ChartTimeline = (props) => (
  <SvgIcon {...props}>
    <path d="M2 2h2v18h18v2H2V2m5 8h10v3H7v-3m4 5h10v3H11v-3M6 4h16v4h-2V6H8v2H6V4z" />
  </SvgIcon>
)

export default React.memo(ChartTimeline)
