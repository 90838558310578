import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ForceFlow = ({
  colorForce = '#e91e63',
  colorFlow = '#2e3d4f',
  ...props
}) => (
  <SvgIcon viewBox="55 51.9 432.3 70.507" {...props}>
    <path
      d="M95.1 92h-28v29.4H55V51.9h44.1v9.7h-32v20.7h28V92zM104.4 95.1c0-5.1 1-9.6 3-13.7s4.8-7.2 8.4-9.4c3.6-2.2 7.8-3.3 12.5-3.3 7 0 12.6 2.2 17 6.7 4.3 4.5 6.7 10.4 7 17.8v2.7c0 5.1-1 9.6-2.9 13.6-2 4-4.8 7.1-8.4 9.3-3.6 2.2-7.8 3.3-12.6 3.3-7.3 0-13.1-2.4-17.5-7.3s-6.6-11.3-6.6-19.4v-.3zm11.6 1c0 5.3 1.1 9.5 3.3 12.5 2.2 3 5.2 4.5 9.2 4.5 3.9 0 7-1.5 9.1-4.6 2.2-3.1 3.3-7.5 3.3-13.4 0-5.2-1.1-9.4-3.4-12.4-2.2-3.1-5.3-4.6-9.1-4.6s-6.8 1.5-9 4.5c-2.3 3-3.4 7.5-3.4 13.5zM189.2 80.4c-1.5-.3-3.1-.4-4.7-.4-5.3 0-8.9 2-10.7 6.1v35.3h-11.6V69.8h11.1l.3 5.8c2.8-4.5 6.7-6.7 11.6-6.7 1.7 0 3 .2 4.1.7l-.1 10.8zM216.9 113.1c2.9 0 5.3-.8 7.2-2.5 1.9-1.7 2.9-3.8 3.1-6.2H238c-.1 3.2-1.1 6.2-3 9s-4.4 5-7.6 6.6c-3.2 1.6-6.7 2.4-10.4 2.4-7.2 0-12.9-2.3-17.2-7-4.2-4.7-6.3-11.1-6.3-19.4v-1.2c0-7.9 2.1-14.1 6.3-18.9 4.2-4.7 9.9-7.1 17.2-7.1 6.1 0 11.1 1.8 15 5.4 3.9 3.6 5.9 8.3 6.1 14.1h-10.9c-.1-3-1.1-5.4-3-7.3-1.9-1.9-4.3-2.9-7.2-2.9-3.8 0-6.6 1.4-8.7 4.1-2 2.7-3.1 6.8-3.1 12.4v1.9c0 5.6 1 9.8 3 12.5 1.9 2.7 4.8 4.1 8.7 4.1zM269.5 122.3c-7.3 0-13.3-2.3-17.9-6.9-4.6-4.6-6.8-10.8-6.8-18.5v-1.4c0-5.2 1-9.8 3-13.8 2-4.1 4.8-7.2 8.4-9.5 3.6-2.3 7.6-3.4 12-3.4 7 0 12.5 2.2 16.3 6.7 3.8 4.5 5.8 10.8 5.8 19v4.7h-33.7c.4 4.3 1.8 7.6 4.3 10.1s5.6 3.7 9.4 3.7c5.3 0 9.6-2.1 13-6.4l6.2 6c-2.1 3.1-4.8 5.5-8.3 7.2-3.5 1.7-7.4 2.5-11.7 2.5zm-1.4-44.2c-3.2 0-5.7 1.1-7.7 3.3-2 2.2-3.2 5.3-3.7 9.3h22.1v-.9c-.3-3.9-1.3-6.8-3.1-8.8-1.8-1.9-4.3-2.9-7.6-2.9z"
      fill={colorForce}
    />
    <path
      d="M338.7 90.7h-28.2l-5.3 30.7h-9l12.1-69.5H350l-1.3 7.5h-32.8l-4.1 23.8H340l-1.3 7.5zM355 121.4h-8.6l12.7-69.5h8.6L355 121.4zM394.8 68.8c4.3.1 8 1.2 11.1 3.5 3.1 2.3 5.4 5.4 6.8 9.5s2 8.4 1.5 13.2l-.1 1.1c-.6 5-2.1 9.6-4.5 13.6-2.4 4.1-5.5 7.2-9.3 9.5-3.8 2.3-7.9 3.3-12.5 3.2-4.3-.1-8-1.2-11.1-3.5-3.1-2.3-5.4-5.4-6.8-9.3-1.4-3.9-1.9-8.3-1.5-13 .5-5.3 1.9-10.1 4.3-14.4 2.4-4.3 5.5-7.6 9.3-9.9s8.2-3.6 12.8-3.5zm-17.6 27.7c-.2 2-.2 4.1 0 6.2.3 3.8 1.4 6.7 3.4 9 1.9 2.2 4.5 3.4 7.7 3.5 2.9.1 5.5-.6 7.9-2.1 2.4-1.5 4.5-3.8 6.1-6.8 1.7-3.1 2.7-6.4 3.2-10.2.3-2.9.3-5.4.1-7.4-.4-3.8-1.5-6.9-3.5-9.1-1.9-2.2-4.5-3.4-7.7-3.5-4.5-.1-8.2 1.7-11.4 5.2-3.1 3.6-5 8.3-5.8 14.1v1.1zM461.9 109.2l16.5-39.4h8.9l-23.5 51.6h-7l-5.3-39-18.6 39h-7l-5.6-51.6h8.3l3.1 38.5L450 69.8h7l4.9 39.4z"
      fill={colorFlow}
    />
  </SvgIcon>
)

export default React.memo(ForceFlow)
