import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const CertificateOutlineAdd = (props) => (
  <SvgIcon {...props}>
    <path d="M15.725 21l1.394-.697 1.395.697v-4.881h-2.789m2.789-3.486v-1.395l-1.395.698-1.394-.698v1.395l-1.395.697 1.395.697v1.395l1.394-.697 1.395.697v-1.395l1.394-.697m.697-4.881H9.449c-.77 0-1.394.625-1.394 1.395v6.972c0 .771.624 1.395 1.394 1.395h4.881v-1.395H9.449V9.844h11.156v6.972h-.697v1.395h.697c.771 0 1.395-.624 1.395-1.395V9.844c0-.77-.624-1.395-1.395-1.395m-6.275 3.487h-4.183v-1.395h4.183m-1.394 3.486h-2.789v-1.394h2.789m1.394 3.486h-4.183v-1.394h4.183z" />
    <path
      d="M4.174.5h1.822v3.643h3.643v1.822H5.996v3.643H4.174V5.965H.531V4.143h3.643z"
      shape="cross 0.531 0.5 9.108 9.108 1.822 1.822 0.5 1@ab8ed6e9"
    />
  </SvgIcon>
)

export default React.memo(CertificateOutlineAdd)
