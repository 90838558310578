import React from 'react'
import useWidth from './useWidth'

const withWidth = (Component) => {
  function WithWidth(props) {
    const width = useWidth()
    return <Component width={width} {...props} />
  }
  return WithWidth
}

export default withWidth
