import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const exportReducer = createReducer(initialState.export, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.export,
  [types.EXPORT_ADD_EXPORTS]: (state, action) => ({
    ...state,
    list: action.data,
  }),
  [types.EXPORT_SET_LOADING]: (state, { value }) => ({
    ...state,
    loading: value,
  }),
})

export default exportReducer
