import * as types from 'Actions/types'
import { createAction } from 'Utils/redux'

export const addCertificate = createAction(
  types.CERTIFICATE_ADD_CERTIFICATE,
  'payload'
)
export const addCertificates = createAction(types.CERTIFICATE_ADD_CERTIFICATES)
export const deleteCertificate = createAction(
  types.CERTIFICATE_DELETE_CERTIFICATE,
  'value'
)
export const reset = createAction(types.CERTIFICATE_RESET)
export const setBusy = createAction(types.CERTIFICATE_SET_BUSY, 'value')
export const setValues = createAction(types.CERTIFICATE_SET_VALUES, 'values')
export const setSelectedIndex = createAction(
  types.CERTIFICATE_SET_SELECTED_INDEX,
  'index'
)
