import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Nubos = (props) => (
  <SvgIcon viewBox="0.9738 0.0004 99.0262 60.9985" {...props}>
    <path
      //fill="#009FE3"
      fillRule="evenodd"
      d="M37.695 36.37c.258-1.473.516-2.95.73-4.43l.264-1.732.002-.01c.594-3.886 1.21-7.905.293-12.069-1.28-5.828-4.548-9.963-9.55-12.142a40.02 40.02 0 0 1 3.462-1.881C36.858 2.193 40.735.97 44.754.37c3.583-.539 6.55-.456 9.367.262C60.306 2.2 64.244 7.143 64.4 13.54c.194 7.893-.88 15.616-2.201 24.18l-.095.718a2.503 2.503 0 0 0 .573 1.892c.438.5 1.123.806 1.784.806 1.189 0 2.731-1.097 4.597-3.256 1.035-1.202 1.964-2.431 2.753-3.488 3.79-5.073 7.985-11.09 9.784-18.318 1.453-5.846.158-10.369-3.847-13.442a10.21 10.21 0 0 0-2.202-1.287C80.981-.31 85.72-.43 90.501.945c5.609 1.612 9.337 6.611 9.488 12.735.19 7.253-2.124 14.299-7.082 21.54C84.5 47.514 74.253 55.44 61.58 59.447c-5.458 1.728-10.212 2.008-14.734.87-6.04-1.512-10.087-6.59-10.312-12.935-.132-3.653.495-7.218 1.156-10.987l.003-.016.002-.008ZM25.39 59.64c-4.235 1.263-8.24 1.6-12.247 1.047C5.986 59.69 1.215 54.359.988 47.106.77 40.048 3.03 33.31 8.32 25.267c2.053-3.121 4.692-6.238 8.073-9.526 1.657-1.608 3.916-2.532 6.204-2.532 1.882 0 3.656.63 4.993 1.775 3.577 3.063 4.231 7.822 1.587 11.568l-.229.322c-2.746 3.891-5.584 7.914-7.605 12.495-1.25 2.832-2.044 5.599-2.425 8.456-.439 3.292.499 6.466 2.64 8.939a11.386 11.386 0 0 0 3.833 2.875Z"
      clipRule="evenodd"
    />
  </SvgIcon>
)

export default React.memo(Nubos)
