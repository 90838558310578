import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagSpain = (props) => (
  <SvgIcon viewBox="0 0 640 480" {...props}>
    <rect width="750" height="500" fill="#c60b1e" />
    <rect width="750" height="250" fill="#ffc400" y="125" />
  </SvgIcon>
)

export default React.memo(FlagSpain)
