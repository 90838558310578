import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Autocomplete from '../Autocomplete'

import { updateField } from 'Actions/form'

const styles = () => ({
  formControl: {
    width: '100%',
  },
  input: {
    height: 29,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
})

class AutocompleteField extends Component {
  handleShouldRenderSuggestions = (value) => value.trim().length > 0

  onChange = (event, { newValue }) => {
    const { meta, input } = this.props
    if (meta.form && input.name) {
      this.props.updateField(
        { form: meta.form, field: input.name, touch: true },
        newValue
      )
    }
  }

  render() {
    const {
      classes,
      onSuggestionsRequest,
      onSuggestionSelected,
      suggestions,
      input: { onChange, ...inputProps },
      label,
      meta: { touched, error, active },
      updateField,
      ...other
    } = this.props
    return (
      <FormControl className={classes.formControl} error={touched && !!error}>
        <Autocomplete
          {...inputProps}
          label={label}
          onChange={this.onChange}
          className={classes.formControl}
          inputClassName={classes.formControl}
          onSuggestionsRequest={onSuggestionsRequest}
          onSuggestionSelected={onSuggestionSelected}
          shouldRenderSuggestions={this.handleShouldRenderSuggestions}
          suggestions={suggestions}
          {...other}
        />
        <FormHelperText>{!active && touched && error}</FormHelperText>
      </FormControl>
    )
  }
}

export default compose(
  withStyles(styles),
  connect(null, { updateField })
)(AutocompleteField)
