import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const SelectCompare = (props) => (
  <SvgIcon {...props}>
    <path d="M13 23h-2V1h2v22m-4-4H5V5h4V3H5c-1.11 0-2 .89-2 2v14a2 2 0 002 2h4v-2M19 7v2h2V7h-2m0-2h2a2 2 0 00-2-2v2m2 10h-2v2h2v-2m-2-4v2h2v-2h-2m-2-8h-2v2h2V3m2 18c1.11 0 2-.89 2-2h-2v2m-2-2h-2v2h2v-2z" />
  </SvgIcon>
)

export default React.memo(SelectCompare)
