import React, { Component } from 'react'
import isEmail from 'validator/es/lib/isEmail'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { FormattedMessage } from 'react-intl'
import { reduxForm, Field, Form } from 'redux-form'
import GridContainer from 'Components/common/GridContainer'
import GridItem from 'Components/common/GridItem'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Base from 'Components/shell/PublicBase'
import TextField from 'Components/common/form/TextField'
import { sendPasswordLink } from 'Actions/auth'
import withTranslate from 'Root/app/withTranslate'

const styles = () => ({
  marginBottom: {
    marginBottom: 30,
  },
  submit: {
    marginTop: 20,
  },
})

const validate = ({ email }, { t }) => {
  const errors = {}
  if (!email) {
    errors.email = t('auth.passwordForgotten.error.email.required')
  }
  if (email && !isEmail(email)) {
    errors.email = t('auth.passwordForgotten.error.email.invalid')
  }
  return errors
}

class PasswordForgotten extends Component {
  sendPasswordLink = (values) =>
    new Promise((resolve, reject) => {
      this.props.sendPasswordLink({
        values,
        resolve,
        reject,
      })
    })

  handleSubmit = async (values) => {
    try {
      await this.sendPasswordLink(values)
    } catch (e) {}
  }

  render() {
    const { classes, hasEmail, handleSubmit, invalid, submitting, t } =
      this.props
    return (
      <Base title={t('auth.passwordForgotten.title')}>
        <Form
          onSubmit={handleSubmit(this.handleSubmit)}
          autoComplete="off"
          noValidate
        >
          <GridContainer direction="column">
            <GridItem errorHeight xs={12}>
              <Field
                name="email"
                required
                lower
                type="email"
                component={TextField}
                placeholder={t('auth.passwordForgotten.email.placeholder')}
                label={t('auth.passwordForgotten.email.label')}
                autoFocus={!hasEmail}
              />
            </GridItem>
            <GridItem className={classes.marginBottom} xs={12}>
              <GridContainer justifyContent="center">
                <Button
                  variant="contained"
                  className={classes.submit}
                  type="submit"
                  color="primary"
                  disabled={invalid || submitting}
                >
                  <FormattedMessage id="auth.passwordForgotten.button.submit" />
                </Button>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer justifyContent="center">
                <Typography component="div" variant="subtitle1">
                  <FormattedMessage id="auth.passwordForgotten.siginin.info" />
                </Typography>
                <Typography variant="subtitle1">
                  <Link to={'/signin'}>
                    &nbsp;
                    <FormattedMessage id="auth.passwordForgotten.siginin.text" />
                  </Link>
                </Typography>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Form>
      </Base>
    )
  }
}

export default compose(
  withTranslate,
  connect(
    (state) => ({
      hasEmail: !!state.auth.user.email,
      initialValues: {
        email: state.auth.user.email,
      },
    }),
    { sendPasswordLink }
  ),
  withStyles(styles),
  reduxForm({
    form: 'passwordForgotten',
    validate,
  })
)(PasswordForgotten)
