import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagNorway = (props) => (
  <SvgIcon viewBox="0 0 640 480" {...props}>
    <defs>
      <clipPath id="prefix__a" clipPathUnits="userSpaceOnUse">
        <path fillOpacity={0.67} d="M-32 6h640v480H-32z" />
      </clipPath>
    </defs>
    <g clipPath="url(#prefix__a)" transform="translate(32 -6)">
      <path fill="#fff" d="M0-20h512v512H0z" />
      <path
        fillRule="evenodd"
        strokeWidth="1pt"
        fill="#fff"
        d="M-70-19.842h699.74v511.84H-70z"
      />
      <path
        fillRule="evenodd"
        strokeWidth="1pt"
        fill="#d72828"
        d="M-99.213-43.039h222.94v221.47h-222.94zM247.42-43.039h431.36v221.47H247.42zM-99.213 301.67h220v225.76h-220zM250 303.79h419.68v223.65H250z"
      />
      <path
        fillRule="evenodd"
        strokeWidth="1pt"
        fill="#003897"
        d="M154.65-43.039h64.425v545.67H154.65z"
      />
      <path
        fillRule="evenodd"
        strokeWidth="1pt"
        fill="#003897"
        d="M-99.213 204.84h763.01v63.444h-763.01z"
      />
    </g>
  </SvgIcon>
)

export default React.memo(FlagNorway)
