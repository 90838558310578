import * as types from './types'
import { createAction } from 'Utils/redux'

export const addShortlist = createAction(
  types.RELATION_ADD_SHORTLIST,
  'id',
  'candidate'
)
export const addSources = createAction(types.RELATION_ADD_SOURCES, 'data')
export const deletePhoto = createAction(types.RELATION_DELETE_PHOTO, 'id')
export const deletePhotoSucceed = createAction(
  types.RELATION_DELETE_PHOTO_SUCCEED
)
export const getCandidates = createAction(
  types.RELATION_GET_CANDIDATES_INIT,
  'id'
)
export const getCandidatesSucceed = createAction(
  types.RELATION_GET_CANDIDATES,
  'id',
  'data'
)
export const getJobs = createAction(types.RELATION_GET_JOBS_INIT, 'id')
export const getJobsSucceed = createAction(
  types.RELATION_GET_JOBS,
  'id',
  'data'
)
export const getPrimaryContact = createAction(
  types.RELATION_GET_PRIMARY_CONTACT,
  'payload'
)
export const getShortlist = createAction(
  types.RELATION_GET_SHORTLIST_INIT,
  'id'
)
export const getShortlistSucceed = createAction(
  types.RELATION_GET_SHORTLIST,
  'id',
  'data'
)
export const loadSources = createAction(types.RELATION_LOAD_SOURCES)
export const removeShortlist = createAction(
  types.RELATION_REMOVE_SHORTLIST,
  'id',
  'candidateIds'
)
export const searchClaims = createAction(types.RELATION_SEARCH_CLAIMS, 'value')
export const searchClaimsSucceed = createAction(
  types.RELATION_SEARCH_CLAIMS_SUCCEED,
  'data'
)
export const searchCoc = createAction(types.RELATION_SEARCH_COC, 'value')
export const searchCocSucceed = createAction(
  types.RELATION_SEARCH_COC_SUCCEED,
  'data'
)
export const selectContacts = createAction(
  types.RELATION_SELECT_CONTACTS,
  'relationType',
  'selected'
)
export const setCandidatesFilter = createAction(
  types.RELATION_SET_CANDIDATES_FILTER,
  'filter'
)
export const setCandidatesLoaded = createAction(
  types.RELATION_SET_CANDIDATES_LOADED,
  'value'
)
export const setJobsFilter = createAction(
  types.RELATION_SET_JOBS_FILTER,
  'filter'
)
export const setJobsLoaded = createAction(
  types.RELATION_SET_JOBS_LOADED,
  'value'
)
export const setSourcesDirty = createAction(
  types.RELATION_SET_SOURCES_DIRTY,
  'value'
)
export const shortlistClearSelect = createAction(
  types.RELATION_SHORTLIST_CLEAR_SELECT
)
export const shortlistDeselect = createAction(
  types.RELATION_SHORTLIST_DESELECT,
  'id'
)
export const shortlistInverseSelection = createAction(
  types.RELATION_SHORTLIST_INVERSE_SELECTION,
  'ids'
)
export const shortlistSelect = createAction(
  types.RELATION_SHORTLIST_SELECT,
  'id'
)
export const updateClaimed = createAction(
  types.RELATION_UPDATE_CLAIMED,
  'id',
  'values'
)
export const uploadPhoto = createAction(
  types.RELATION_UPLOAD_PHOTO,
  'id',
  'file'
)
export const uploadPhotoSucceed = createAction(
  types.RELATION_UPLOAD_PHOTO_SUCCEED,
  'id',
  'url'
)
