import React, { Component, Suspense } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import PageFocus from 'Components/common/PageFocus'
import storage from 'Utils/storage'

import history from 'Utils/history'
const Root = React.lazy(() => import('Components/shell/Root'))
const Shares = React.lazy(() => import('Components/share/Shares'))
const Apply = React.lazy(() => import('Components/apply/index'))

//import Root from 'Components/shell/Root'
import PrivateRoute from './PrivateRoute'
import Signin from 'Components/auth/Signin'
import Signout from 'Components/auth/Signout'
import Signup from 'Components/auth/Signup'
import Sso from 'Components/auth/Sso'
import Request from 'Components/candidate/gdpr/Request'
import PasswordForgotten from 'Components/auth/PasswordForgotten'
import ResetPassword from 'Components/auth/ResetPassword'
import Activate from 'Components/auth/Activate'
import { changeLanguage } from 'Actions/app'

const styles = (theme) => {
  return {
    '@global': {
      html: {
        background: theme.palette.background.default,
        WebkitFontSmoothing: 'antialiased', // Antialiasing.
        MozOsxFontSmoothing: 'grayscale', // Antialiasing.
        boxSizing: 'border-box',
        '@media print': {
          background: theme.palette.common.white,
        },
      },
      '*, *:before, *:after': {
        boxSizing: 'inherit',
      },
      '.public-DraftEditorPlaceholder-root': {
        color: 'currentColor',
        opacity: theme.palette.type === 'light' ? 0.42 : 0.5,
      },
      body: {
        margin: 0,
        listStyle: 'none',
        overscrollBehaviorY: 'contain',
      },
      a: {
        textDecoration: 'none',
        color:
          theme.palette.type === 'light'
            ? theme.palette.primary.main
            : theme.palette.primary.light,
        '&:hover': {
          textDecoration: 'underline',
        },
        cursor: 'pointer',
      },
      ul: {
        padding: 0,
        paddingLeft: 20,
        margin: 0,
      },
    },
  }
}

const handleFocus = () => {
  storage.sync()
}

const RootApp = () => {
  return (
    <PageFocus onFocus={handleFocus}>
      <Suspense fallback={<div />}>
        <Root />
      </Suspense>
    </PageFocus>
  )
}

const SharesApp = () => {
  return (
    <Suspense fallback={<div />}>
      <Shares />
    </Suspense>
  )
}

const ApplyApp = () => {
  return (
    <Suspense fallback={<div />}>
      <Apply />
    </Suspense>
  )
}

class AppRouter extends Component {
  componentDidMount() {
    const { changeLanguage, language } = this.props
    changeLanguage(language)
  }
  render() {
    return (
      <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
        <div>
          <Switch>
            <Route
              path="/apply/:companySlug?/:jobSlug?"
              render={() => <ApplyApp />}
            />
            <Route path="/shares/:user?/:id?" render={() => <SharesApp />} />
            <Route
              path="/gdpr/:token/removal"
              render={(props) => <Request {...props} type="removal" />}
            />
            <Route
              path="/gdpr/:token/change"
              render={(props) => <Request {...props} type="change" />}
            />
            <Route
              path="/gdpr/:token/consent"
              render={(props) => <Request {...props} type="consent" />}
            />
            <Route path="/signin/:companyId" component={Signin} />
            <Route path="/signin" component={Signin} />
            <Route path="/signout" component={Signout} />
            <Route path="/signup" component={Signup} />
            <Route path="/sso/:provider?" component={Sso} />
            <Route path="/activate/:token" component={Activate} />
            <Route path="/password-forgotten" component={PasswordForgotten} />
            <Route
              path="/reset-password/:userid/:token"
              component={ResetPassword}
            />
            <Route
              path="/activate-user/:userid/:token"
              component={ResetPassword}
            />
            <PrivateRoute path="/" component={RootApp} />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default compose(
  injectIntl,
  withStyles(styles),
  connect((state) => ({ language: state.app.language }), { changeLanguage })
)(AppRouter)
