import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ForceFlow = ({
  color = '#2e3d4f',
  colorCircle = '#e91e63',
  ...props
}) => (
  <SvgIcon viewBox="0 0 112 112" {...props}>
    <path
      d="M90.3 84c-.7 0-1.4-.2-2-.7-1.5-1.1-1.8-3.2-.7-4.8 5-6.8 7.6-14.8 7.6-23.2 0-21.6-17.6-39.2-39.2-39.2S16.8 33.8 16.8 55.4c0 8.4 2.6 16.4 7.6 23.2 1.1 1.5.8 3.6-.7 4.8-1.5 1.1-3.6.8-4.8-.7-5.8-8-8.9-17.4-8.9-27.3 0-25.4 20.6-46 46-46s46 20.6 46 46c0 9.9-3.1 19.3-8.9 27.2-.7.9-1.7 1.4-2.8 1.4z"
      fill={color}
    />
    <path
      d="M76.6 70.3c-.6 0-1.1-.1-1.7-.4-1.6-.9-2.2-3-1.3-4.6 1.7-3 2.6-6.4 2.6-9.8 0-11.1-9.1-20.2-20.2-20.2s-20.2 9.1-20.2 20.2c0 3.4.9 6.8 2.6 9.8.9 1.6.3 3.7-1.3 4.6-1.6.9-3.7.3-4.6-1.3-2.2-4-3.4-8.6-3.4-13.1 0-14.9 12.1-27 27-27s27 12.1 27 27c0 4.6-1.2 9.1-3.4 13.1-.8 1.1-1.9 1.7-3.1 1.7z"
      fill={color}
    />
    <circle
      cx={56}
      cy={58.1}
      r={11.4}
      style={{
        fill: colorCircle,
      }}
    />
    <path
      d="M81.6 90C77 75.4 67.2 73.8 67.2 73.8c-2.1-.7-3.1.8-3.1.8-2.7 3.1-7 3.2-8 3.1h-.2c-1 0-5.3 0-8-3.1 0 0-1-1.5-3.1-.8 0 0-9.8 1.7-14.4 16.3 0 0-1 2.1 1 3.6 1.9 1.5 9.5 8.7 25.2 9h.7c12.5-.3 21.3-7.4 23.3-9 2-1.6 1-3.7 1-3.7z"
      fill={color}
    />
  </SvgIcon>
)

export default React.memo(ForceFlow)
