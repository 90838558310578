import { call, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { confirmSaga, globalError } from './shell'
import { loadTemplates } from 'Actions/cache'
import { t } from 'Root/app/IntlProvider'
import { show } from 'redux-modal'
import { showMessage } from 'Actions/shell'
import {
  addAttachmentSucceed,
  getSucceed,
  mediaGetSucceed,
  mediaLoad,
  mediaLoadSucceed,
  setData,
  setUploading,
} from 'Actions/template'
import { messageLevel } from 'Utils/constants'

export function* activateSaga(action) {
  const {
    payload: { id, name },
  } = action
  const yes = yield call(confirmSaga, {
    title: t('template.message.activate.title'),
    content: t('template.message.activate.content', {
      template: name,
    }),
  })
  if (!yes) {
    return
  }
  const { ok, error, data } = yield http.post(`/templates/${id}/activate`)
  if (ok) {
    yield put(loadTemplates())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* createAttachmentSaga(action) {
  const { file } = action
  yield put(setUploading(true))
  const { created, tooLarge, error, data } = yield http.post(
    `/templates/attachments`,
    file
  )
  yield put(setUploading(false))
  if (created) {
    yield put(addAttachmentSucceed(data))
  } else if (tooLarge) {
    yield put(
      showMessage(
        t('template.template.error.attachment.tooLarge'),
        messageLevel.error
      )
    )
  } else if (data.error === 'Extension is forbidden') {
    yield put(
      showMessage(
        t('template.template.error.attachment.extension', {
          extension: data.extension,
          file: data.file,
        }),
        messageLevel.error
      )
    )
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* createSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { created, data } = yield http.post(`/templates`, values)
  if (!created) {
    return yield call(globalError, data, reject)
  }
  yield put(loadTemplates())
  yield call(resolve)
}

export function* deactivateSaga(action) {
  const {
    payload: { id, name },
  } = action
  const yes = yield call(confirmSaga, {
    title: t('template.message.deactivate.title'),
    content: t('template.message.deactivate.content', {
      template: name,
    }),
  })
  if (!yes) {
    return
  }
  const { noContent, error, data } = yield http.delete(`/templates/${id}`)
  if (noContent) {
    yield put(loadTemplates())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getNewSaga() {
  const state = yield select()
  yield put(
    getSucceed({
      attachments: [],
      context: null,
      to: null,
      type: 'email',
      visible: true,
      locale: [
        {
          language: state.auth.company.settings.defaultCommunicationIn,
          content: '',
          filename: '',
          subject: '',
          name: '',
        },
      ],
    })
  )
  yield put(show('template'))
}

export function* getSaga(action) {
  const { id } = action
  const { ok, data } = yield http.get(`/templates/${id}`)
  if (ok) {
    const { uploadedAttachments, ...rest } = data
    yield put(setData({ uploadedAttachments }))
    yield put(getSucceed({ ...rest, canCopy: true }))
    yield put(show('template', { id }))
  } else {
    yield call(globalError, data)
  }
}

export function* mediaCreateSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { created, data } = yield http.post(`/templates/media`, values)
  if (!created) {
    return yield call(globalError, data, reject)
  }
  yield put(mediaLoad())
  yield call(resolve)
}

export function* mediaGetSaga(action) {
  const { id } = action
  const { ok, data } = yield http.get(`/templates/media/${id}`)
  if (ok) {
    yield put(mediaGetSucceed(data))
    yield put(show('media', { id }))
  } else {
    yield call(globalError, data)
  }
}

export function* mediaLoadSaga() {
  const { ok, data } = yield http.get(`/templates/media`)
  if (ok) {
    yield put(mediaLoadSucceed(data))
  } else {
    yield call(globalError, data)
  }
}

export function* mediaRemoveSaga(action) {
  const {
    payload: { id, name },
  } = action
  const yes = yield call(confirmSaga, {
    title: t('template.message.media.remove.title'),
    content: t('template.message.media.remove.content', {
      file: name,
    }),
  })
  if (!yes) {
    return
  }
  const { noContent, error, data } = yield http.delete(`/templates/media/${id}`)
  if (noContent) {
    yield put(mediaLoad())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* mediaUpdateSaga(action) {
  const {
    payload: { id, values, resolve, reject },
  } = action
  const { ok, data } = yield http.put(`/templates/media/${id}`, values)
  if (!ok) {
    return yield call(globalError, data, reject)
  }
  yield put(mediaLoad())
  yield call(resolve)
}

export function* updateSaga(action) {
  const {
    payload: { id, values, resolve, reject },
  } = action
  const { ok, data } = yield http.put(`/templates/${id}`, values)
  if (!ok) {
    return yield call(globalError, data, reject)
  }
  yield put(loadTemplates())
  yield call(resolve)
}
