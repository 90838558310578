import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const New = React.memo(
  React.forwardRef(({ color = '#e74c3c' }, ref) => {
    return (
      <SvgIcon data-mui-test={'NewIcon'} ref={ref}>
        <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
          <rect
            x={41.378}
            y={84.001}
            width={413.244}
            height={291.623}
            rx={40.709}
            ry={40.709}
            fill={color}
          />
          <text
            style={{
              whiteSpace: 'pre',
            }}
            transform="matrix(8.10293 0 0 10.00534 -765.933 -1642.576)"
            x={104.745}
            y={193.017}
            fill="#fff"
            fontFamily="Arial,sans-serif"
            fontWeight={600}
            fontSize={17.4}
          >
            {'NEW'}
          </text>
        </svg>
      </SvgIcon>
    )
  })
)

New.displayName = 'NewIcon'

export default New
