import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const BriefcaseOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M20 6c.58 0 1.05.2 1.42.59.38.41.58.86.58 1.41v11c0 .55-.2 1-.58 1.41-.37.39-.84.59-1.42.59H4c-.58 0-1.05-.2-1.42-.59C2.2 20 2 19.55 2 19V8c0-.55.2-1 .58-1.41C2.95 6.2 3.42 6 4 6h4V4c0-.58.2-1.05.58-1.42C8.95 2.2 9.42 2 10 2h4c.58 0 1.05.2 1.42.58.38.37.58.84.58 1.42v2h4M4 8v11h16V8H4m10-2V4h-4v2h4z" />
  </SvgIcon>
)

export default React.memo(BriefcaseOutline)
