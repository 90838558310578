import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagCheckered = (props) => (
  <SvgIcon {...props}>
    <path d="M14.4 6H20v10h-7l-.4-2H7v7H5V4h9l.4 2m-.4 8h2v-2h2v-2h-2V8h-2v2l-1-2V6h-2v2H9V6H7v2h2v2H7v2h2v-2h2v2h2v-2l1 2v2m-3-4V8h2v2h-2m3 0h2v2h-2v-2z" />
  </SvgIcon>
)

export default React.memo(FlagCheckered)
