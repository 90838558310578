import React, { Component } from 'react'
import clsx from 'clsx'
import compose from 'recompose/compose'
import Picker from 'emoji-picker-react'
import moment from 'moment'
import Hidden from 'Root/app/Hidden'
import debounce from 'lodash.debounce'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ContentEditable from 'react-contenteditable'
import { hotkeys, hotkey_display } from 'react-keyboard-shortcuts'
import { withStyles, withTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import FormatBold from '@material-ui/icons/FormatBold'
import FormatItalic from '@material-ui/icons/FormatItalic'
import FormatUnderlined from '@material-ui/icons/FormatUnderlined'
import StrikethroughS from '@material-ui/icons/StrikethroughS'
import FormatListBulleted from '@material-ui/icons/FormatListBulleted'
import FormatListNumbered from '@material-ui/icons/FormatListNumbered'
import FormatIndentDecrease from '@material-ui/icons/FormatIndentDecrease'
import FormatIndentIncrease from '@material-ui/icons/FormatIndentIncrease'
import sanitizeHtml from 'sanitize-html'
import CalendarPlus from 'Icons/CalendarPlus'
import EmoticonHappyOutlineIcon from 'Icons/EmoticonHappyOutline'
import TextBoxPlusOutlineIcon from 'Icons/TextBoxPlusOutline'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import SelectButton from './SelectButton'
import Tooltip from 'Components/common/Tooltip'
import withTranslate from 'Root/app/withTranslate'

const styles = (theme) => ({
  '@global': {
    '.emoji-picker-react': {
      background: `${theme.palette.background.default} !important`,
    },
    '.emoji-group': {
      '&:before': {
        background: `${theme.palette.background.default} !important`,
      },
    },
    '.emoji-categories button': {
      backgroundColor: `white !important`,
      borderRadius: 20,
      marginTop: 5,
      width: '40px !important',
    },
  },
  base: {
    color: theme.palette.type === 'light' ? theme.palette.grey[800] : 'white',
    fontFamily: 'Roboto',
    fontSize: 16,
    overflowX: 'hidden',
    overflowY: 'auto',
    outline: 'none',
    paddingBottom: 5,
    paddingTop: 5,
    resize: 'none',
    width: '100%',
  },
  button: {
    minWidth: 30,
    maxWidth: 30,
    marginRight: 2,
  },
  buttons: {
    display: 'flex',
    marginTop: 5,
    width: '100%',
  },
  divider: {
    marginTop: 5,
  },
  iconSmall: {
    fontSize: 20,
  },
  label: {
    marginBottom: 3,
    marginTop: 1,
  },
  space: {
    flex: 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

// const sanitizeConf = {
//   allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h1', 'div', 'br', 'u'],
//   allowedAttributes: { a: ['href'] }
// }

class RichEditField extends Component {
  constructor(props) {
    super(props)
    this.contentEditable = React.createRef()
    if (props.globalRef) {
      window.__FF_RICHEDIT_REF__ = this.contentEditable
    }
  }
  state = {
    document: null,
    oldValue: '',
    value: this.props.input ? this.props.input.value : this.props.value,
    noChangeFocus: false,
    focused: false,
    picker: false,
  }
  static getDerivedStateFromProps(props, state) {
    if (typeof props.value !== 'undefined' && props.value !== state.value) {
      return {
        value: props.value,
      }
    }
    if (typeof props.input !== 'undefined' && !props.input.value) {
      return {
        value: '',
      }
    }

    if (
      typeof props.input !== 'undefined' &&
      props.input.value !== state.oldValue
    ) {
      return {
        oldValue: '',
        value: props.input.value,
      }
    }
    return null
  }
  componentDidMount() {
    if (this.props.autoFocus) {
      this.contentEditable.current.focus()
    }
  }
  componentWillUnmount() {
    if (this.debouncer) {
      this.debouncer.cancel()
    }
  }

  handleActionClick = (action) => (e) => {
    e.preventDefault()
    this.setState({ noChangeFocus: true })
    document.execCommand(action, false)
    return false
  }

  handleChange = (e) => {
    const value = e.target.value
    if (value !== this.state.value) {
      this.setState({ oldValue: this.state.value, value }, () => {})
      if (this.props.input) {
        this.props.input.onChange(value)
      }
      this.props.onChange && this.props.onChange(value)
    }
  }
  handlePaste = (e) => {
    e.preventDefault()
    const types = e.clipboardData.types || []
    let html = ''
    if (types.includes('text/html')) {
      let text = e.clipboardData.getData('text/html')
      text = (text || '').replace(/<\/p>/g, '</p><br />')
      html = sanitizeHtml(text, {
        allowedTags: [
          'a',
          'b',
          'br',
          'em',
          'i',
          'li',
          'ol',
          'strong',
          'u',
          'ul',
        ],
        allowedAttributes: {
          a: ['href', 'rel'],
        },
      })
        .replace(/\n/g, ' ')
        .trim()
      //console.log(html)
      html = html
        .replace(/href/g, "rel='noopener noreferrer' target='_blank' href")
        .trim()
    } else if (types.includes('text/plain')) {
      html = (e.clipboardData.getData('text/plain') || '').replace(
        /\n/g,
        '<br>'
      )
    }
    if (html) {
      document.execCommand('insertHTML', false, html)
    }
  }

  handleInsertDateClick = (e) => {
    e.preventDefault()
    this.setState({ noChangeFocus: true })
    document.execCommand('insertHTML', false, moment().format('l') + ':&nbsp;')
  }

  handleFocus = (e) => {
    this.props.onFocus && this.props.onFocus(e)
    this.setState({ focused: true, document })
  }

  handleBlur = (e) => {
    this.props.onBlur && this.props.onBlur(e)
    this.debouncer = debounce(() => {
      if (!this.state.noChangeFocus) {
        this.setState({ focused: false })
      }
      this.setState({ noChangeFocus: false })
    }, 200)
    this.debouncer()
  }

  handleShowEmojiClick = () => this.setState({ picker: !this.state.picker })
  handleEmojiClick = (event, emojiObject) => {
    this.state.document.execCommand('insertHTML', false, emojiObject.emoji)
    event.preventDefault()
    event.stopPropagation()
    this.setState({ noChangeFocus: true, picker: false })
  }
  handleClickAway = () => {
    this.setState({ picker: false })
  }

  insertNodeAtCaret(node) {
    if (typeof window.getSelection != 'undefined') {
      var sel = window.getSelection()
      if (sel.rangeCount) {
        var range = sel.getRangeAt(0)
        range.collapse(false)
        range.insertNode(node)
        range = range.cloneRange()
        range.selectNodeContents(node)
        range.collapse(false)
        sel.removeAllRanges()
        sel.addRange(range)
      }
    } else if (
      typeof document.selection != 'undefined' &&
      document.selection.type != 'Control'
    ) {
      var html = node.nodeType == 1 ? node.outerHTML : node.data
      var id = 'marker_' + ('' + Math.random()).slice(2)
      html += '<span id="' + id + '"></span>'
      var textRange = document.selection.createRange()
      textRange.collapse(false)
      textRange.pasteHTML(html)
      var markerSpan = document.getElementById(id)
      textRange.moveToElementText(markerSpan)
      textRange.select()
      markerSpan.parentNode.removeChild(markerSpan)
    }
  }

  handleUserClick = (id, name) => {
    const sel = window.getSelection()
    const range = sel.getRangeAt(0)
    range.deleteContents()
    const newElement = document.createElement('span')
    newElement.setAttribute('contenteditable', false)
    newElement.setAttribute('data-id', id)
    newElement.setAttribute('title', name)
    newElement.innerText = `@${name}`
    newElement.style.backgroundColor = '#bdc3c7'
    newElement.style.borderRadius = '15px'
    newElement.style.color = 'white'
    newElement.style.fontWeight = 'bold'
    newElement.style.marginLeft = '3px'
    newElement.style.marginRight = '3px'
    newElement.style.paddingLeft = '5px'
    newElement.style.paddingRight = '5px'
    newElement.style.paddingBottom = '2px'
    newElement.style.paddingTop = '2px'
    newElement.style.fontSize = 'small'
    range.insertNode(newElement)
    this.insertNodeAtCaret(document.createTextNode('\u00A0'))
    const value = this.contentEditable.current.innerHTML
    this.setState({ oldValue: this.state.value, value }, () => {})
    if (this.props.input) {
      this.props.input.onChange(value)
    }
    this.props.onChange && this.props.onChange(value)
    setTimeout(() => {
      this.contentEditable.current.focus()
    }, 200)
  }

  handleUsersClick = (e) => {
    this.props.onUsersClick && this.props.onUsersClick(e)
  }

  hot_keys = {
    'meta+b': {
      priority: 1,
      handler: () => this.handleActionClick('bold'),
    },
    'ctrl+b': {
      priority: 2,
      handler: () => this.handleActionClick('bold'),
    },
    'meta+i': {
      priority: 1,
      handler: () => this.handleActionClick('italic'),
    },
    'ctrl+i': {
      priority: 2,
      handler: () => this.handleActionClick('italic'),
    },
    'meta+u': {
      priority: 1,
      handler: () => this.handleActionClick('underline'),
    },
    'ctrl+u': {
      priority: 2,
      handler: () => this.handleActionClick('underline'),
    },
  }

  render() {
    let {
      autoFocus = true,
      backgroundColor,
      bottomDivider = false,
      classes,
      disabled,
      height = 'unset',
      input,
      label,
      maxHeight = 'none',
      maxLength = -1,
      noteTemplates = [],
      onTemplateClick,
      placeholder,
      select: Select,
      showBar = true,
      showEmoji,
      t,
      theme,
      users = [],
      value = '<p />',
    } = this.props
    const { focused } = this.state
    if (input) {
      value = this.state.value || input.value
    }
    return (
      <div style={{ width: input ? '100%' : 'initial' }}>
        {label && (
          <Typography
            variant="caption"
            color="textSecondary"
            component="div"
            className={classes.label}
          >
            {label}
          </Typography>
        )}
        <ContentEditable
          autoFocus={autoFocus}
          className={clsx(classes.base, 'wn-note')}
          placeholder={placeholder}
          innerRef={this.contentEditable}
          html={value}
          style={{
            backgroundColor: backgroundColor || theme.palette.background.paper,
            cursor: !disabled ? 'text' : 'auto',
            display: 'inline-block',
            height,
            maxHeight,
          }}
          disabled={disabled}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onPaste={this.handlePaste}
          data-max-length={maxLength}
        />

        {showBar && (
          <div>
            <Divider className={classes.divider} />
            <div className={classes.toolbar}>
              <div className={classes.buttons}>
                <Tooltip
                  title={
                    focused
                      ? t('common.form.richEditField.bold.tooltip', {
                          item: hotkey_display('meta+b'),
                        })
                      : ''
                  }
                >
                  <Button
                    disabled={!focused}
                    size="small"
                    onClick={this.handleActionClick('bold')}
                    className={classes.button}
                    tabIndex={-1}
                  >
                    <FormatBold
                      className={classes.iconSmall}
                      color={!focused ? 'disabled' : 'action'}
                    />
                  </Button>
                </Tooltip>
                <Tooltip
                  title={
                    focused
                      ? t('common.form.richEditField.italic.tooltip', {
                          item: hotkey_display('meta+i'),
                        })
                      : ''
                  }
                >
                  <Button
                    disabled={!focused}
                    size="small"
                    onClick={this.handleActionClick('italic')}
                    className={classes.button}
                    tabIndex={-1}
                  >
                    <FormatItalic
                      className={classes.iconSmall}
                      color={!focused ? 'disabled' : 'action'}
                    />
                  </Button>
                </Tooltip>
                <Tooltip
                  title={
                    focused
                      ? t('common.form.richEditField.underline.tooltip', {
                          item: hotkey_display('meta+u'),
                        })
                      : ''
                  }
                >
                  <Button
                    disabled={!focused}
                    size="small"
                    onClick={this.handleActionClick('underline')}
                    className={classes.button}
                    tabIndex={-1}
                  >
                    <FormatUnderlined
                      className={classes.iconSmall}
                      color={!focused ? 'disabled' : 'action'}
                    />
                  </Button>
                </Tooltip>
                <Hidden xsDown>
                  <Tooltip
                    title={
                      focused
                        ? t('common.form.richEditField.strikethrough.tooltip', {
                            item: hotkey_display('meta+s'),
                          })
                        : ''
                    }
                  >
                    <Button
                      disabled={!focused}
                      size="small"
                      onClick={this.handleActionClick('strikeThrough')}
                      className={classes.button}
                      tabIndex={-1}
                    >
                      <StrikethroughS
                        className={classes.iconSmall}
                        color={!focused ? 'disabled' : 'action'}
                      />
                    </Button>
                  </Tooltip>
                  <Divider orientation="vertical" />
                  <Tooltip
                    title={
                      focused
                        ? t('common.form.richEditField.listBulleted.tooltip')
                        : ''
                    }
                  >
                    <Button
                      disabled={!focused}
                      size="small"
                      onClick={this.handleActionClick('insertUnorderedList')}
                      className={classes.button}
                      tabIndex={-1}
                    >
                      <FormatListBulleted
                        className={classes.iconSmall}
                        color={!focused ? 'disabled' : 'action'}
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={
                      focused
                        ? t('common.form.richEditField.listNumbered.tooltip')
                        : ''
                    }
                  >
                    <Button
                      disabled={!focused}
                      size="small"
                      onClick={this.handleActionClick('insertOrderedList')}
                      className={classes.button}
                      tabIndex={-1}
                    >
                      <FormatListNumbered
                        className={classes.iconSmall}
                        color={!focused ? 'disabled' : 'action'}
                      />
                    </Button>
                  </Tooltip>
                  <Divider orientation="vertical" />
                  <Tooltip
                    title={
                      focused
                        ? t('common.form.richEditField.outdent.tooltip')
                        : ''
                    }
                  >
                    <Button
                      disabled={!focused}
                      size="small"
                      onClick={this.handleActionClick('outdent')}
                      className={classes.button}
                      tabIndex={-1}
                    >
                      <FormatIndentDecrease
                        className={classes.iconSmall}
                        color={!focused ? 'disabled' : 'action'}
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={
                      focused
                        ? t('common.form.richEditField.indent.tooltip')
                        : ''
                    }
                  >
                    <Button
                      disabled={!focused}
                      size="small"
                      onClick={this.handleActionClick('indent')}
                      className={classes.button}
                      tabIndex={-1}
                    >
                      <FormatIndentIncrease
                        className={classes.iconSmall}
                        color={!focused ? 'disabled' : 'action'}
                      />
                    </Button>
                  </Tooltip>
                </Hidden>
                <Divider orientation="vertical" />
                {users.length > 0 && (
                  <SelectButton
                    items={users}
                    focused={focused}
                    tooltip={t('common.form.richEditField.mention.tooltip')}
                    onClick={this.handleUsersClick}
                    onItemClick={this.handleUserClick}
                    Icon={AlternateEmailIcon}
                    withIcon
                  />
                )}
                {showEmoji && (
                  <Tooltip
                    title={
                      focused
                        ? t('common.form.richEditField.emoji.tooltip')
                        : ''
                    }
                  >
                    <Button
                      disabled={!focused}
                      size="small"
                      onClick={this.handleShowEmojiClick}
                      className={classes.button}
                      tabIndex={-1}
                    >
                      <EmoticonHappyOutlineIcon
                        className={classes.iconSmall}
                        color={!focused ? 'disabled' : 'action'}
                      />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip
                  title={
                    focused
                      ? t('common.form.richEditField.currentDate.tooltip')
                      : ''
                  }
                >
                  <Button
                    disabled={!focused}
                    size="small"
                    onClick={this.handleInsertDateClick}
                    className={classes.button}
                    tabIndex={-1}
                  >
                    <CalendarPlus
                      className={classes.iconSmall}
                      color={!focused ? 'disabled' : 'action'}
                    />
                  </Button>
                </Tooltip>
                {noteTemplates.length > 0 && (
                  <SelectButton
                    items={noteTemplates}
                    focused={focused}
                    noDisable
                    tooltip={t('common.form.richEditField.template.tooltip')}
                    onItemClick={onTemplateClick}
                    Icon={TextBoxPlusOutlineIcon}
                  />
                )}
              </div>
              <Hidden xsDown>{Select ? <Select /> : null}</Hidden>
            </div>
            {this.state.picker && (
              <ClickAwayListener onClickAway={this.handleClickAway}>
                <div>
                  <Picker
                    disableAutoFocus
                    disableSearchBar
                    pickerStyle={{ width: '100%', height: 250 }}
                    onEmojiClick={this.handleEmojiClick}
                    native
                    groupNames={{
                      smileys_people: t(
                        'common.form.richEditField.emoji.smileysPeople'
                      ),
                      animals_nature: t(
                        'common.form.richEditField.emoji.animalsNature'
                      ),
                      food_drink: t(
                        'common.form.richEditField.emoji.foodDrink'
                      ),
                      travel_places: t(
                        'common.form.richEditField.emoji.travelPlaces'
                      ),
                      activities: t(
                        'common.form.richEditField.emoji.activities'
                      ),
                      objects: t('common.form.richEditField.emoji.objects'),
                      symbols: t('common.form.richEditField.emoji.symbols'),
                      flags: t('common.form.richEditField.emoji.flags'),
                      recently_used: t(
                        'common.form.richEditField.emoji.recentlyUsed'
                      ),
                    }}
                  />
                </div>
              </ClickAwayListener>
            )}
            {bottomDivider && <Divider className={classes.divider} />}
          </div>
        )}
      </div>
    )
  }
}

export default compose(
  withTranslate,
  withStyles(styles),
  withTheme,
  hotkeys
)(RichEditField)
