import React from 'react'
import clsx from 'clsx'
import { Field } from 'redux-form'
import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import TextField from './TextField'
import IconButton from '@material-ui/core/IconButton'
import TableRowIcon from 'Icons/TableRow'
import TableRowMergeIcon from 'Icons/TableRowMerge'
import GridContainer from 'Components/common/GridContainer'
import GridItem from 'Components/common/GridItem'
import Tooltip from 'Components/common/Tooltip'
import { t } from 'Root/app/IntlProvider'

const styles = {
  button: {
    display: 'flex',
  },
  root: {
    display: 'flex',
    marginBottom: 4,
    marginTop: 4,
  },
}

const NameField = (props) => {
  const { value, onChange } = props.input
  const handleButtonClick = () => {
    onChange(!value)
  }
  const { canSwitch, classes, required = true, autoFocus = false } = props

  const Button = () => {
    if (canSwitch) {
      return (
        <div>
          <Tooltip
            title={t(
              `common.form.nameField.tooltip.${value ? 'nodetails' : 'details'}`
            )}
          >
            <IconButton onClick={handleButtonClick} tabIndex="-1">
              {value ? <TableRowMergeIcon /> : <TableRowIcon />}
            </IconButton>
          </Tooltip>
        </div>
      )
    }
    return null
  }

  return (
    <div className={clsx(classes.button, value && classes.root)}>
      {!value && (
        <>
          <Field
            required={required}
            autoFocus={autoFocus}
            name="name"
            component={TextField}
            placeholder={t('common.form.nameField.name.placeholder')}
            label={t('common.form.nameField.name.label')}
            spellCheck={false}
          />
          <Button />
        </>
      )}
      {value && (
        <GridContainer>
          <GridItem xs={3}>
            <Field
              autoFocus={autoFocus}
              name="nameDetails.first"
              component={TextField}
              spellCheck={false}
              label={t('common.form.nameField.first.label')}
              inputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={2}>
            <Field
              name="nameDetails.initials"
              component={TextField}
              spellCheck={false}
              label={t('common.form.nameField.initials.label')}
              inputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={2}>
            <Field
              name="nameDetails.middle"
              component={TextField}
              spellCheck={false}
              label={t('common.form.nameField.middle.label')}
              inputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={5} className={classes.button}>
            <Field
              required={required}
              name="nameDetails.last"
              component={TextField}
              spellCheck={false}
              label={t('common.form.nameField.last.label')}
              inputLabelProps={{
                shrink: true,
              }}
            />
            <Button />
          </GridItem>
        </GridContainer>
      )}
    </div>
  )
}

export default compose(
  withStyles(styles),
  connect(
    (state) => ({
      canSwitch: state.auth.company.settings.canSwitchNameDetails,
    }),
    null
  )
)(NameField)
