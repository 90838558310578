import React from 'react'
import { TimePicker } from '@material-ui/pickers'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import withTranslate from 'Root/app/withTranslate'

const styles = () => ({
  input: {
    paddingTop: 3,
  },
  root: {
    height: 29,
  },
})

const TimeField = (props) => {
  const { classes, disabled = false, input, label, meta, t } = props
  const { touched = true, error, active } = meta || {}
  const { value, onChange, name } = input

  return (
    <FormControl error={touched && !!error} fullWidth>
      <TimePicker
        name={name}
        autoOk
        disabled={disabled}
        fullWidth
        label={label}
        InputProps={{ classes: { input: classes.input, root: classes.root } }}
        value={value || null}
        onChange={onChange}
        ampm={false}
        cancelLabel={t('common.form.dateTimeField.cancelLabel')}
        clearLabel={t('common.form.dateTimeField.clearLabel')}
      />
      <FormHelperText error>{error}</FormHelperText>
    </FormControl>
  )
}

export default compose(withTranslate, withStyles(styles))(TimeField)
