import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { signout } from 'Actions/auth'

const Signout = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(signout())
  }, [dispatch])
  return <div />
}

export default Signout
