import React, { useEffect } from 'react'
import { putMyTheme } from 'Actions/user'
import { useDispatch } from 'react-redux'

const DarkModus = ({ children }) => {
  const hasWindow = typeof window !== 'undefined'
  const isSupported = hasWindow && Boolean(window.matchMedia)
  const dispatch = useDispatch()

  useEffect(() => {
    function handleChange(e) {
      console.log('CHANGE')
      const isDark = e.matches
      dispatch(putMyTheme(isDark ? 'dark' : 'light', true))
    }
    if (isSupported) {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', handleChange)
    }
    return () => {
      if (isSupported) {
        window
          .matchMedia('(prefers-color-scheme: dark)')
          .removeEventListener('change', handleChange)
      }
    }
  }, [dispatch, isSupported])

  return React.Children.only(children)
}

DarkModus.displayName = 'DarkModus'

export default DarkModus
