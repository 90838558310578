import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Rocket = (props) => (
  <SvgIcon {...props}>
    <path d="M20 22l-3.86-1.55c.7-1.53 1.2-3.11 1.51-4.72L20 22M7.86 20.45L4 22l2.35-6.27c.31 1.61.81 3.19 1.51 4.72M12 2s5 2 5 10c0 3.1-.75 5.75-1.67 7.83A2 2 0 0113.5 21h-3a2 2 0 01-1.83-1.17C7.76 17.75 7 15.1 7 12c0-8 5-10 5-10m0 10c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z" />
  </SvgIcon>
)

export default React.memo(Rocket)
