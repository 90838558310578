import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'
import { getNewPositions } from 'Utils/lookup'

const lookupReducer = createReducer(initialState.lookup, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.lookup,
  [types.LOOKUP_CLEAR_LOOKUP]: (state) => ({
    ...initialState.lookup,
    language: state.language,
  }),
  [types.LOOKUP_MOVE_ITEM_INTERN]: (state, { oldPosition, newPosition }) => {
    const currentList = state.list
    const positions = getNewPositions(currentList, oldPosition, newPosition)
    const newList = currentList.map((item) => {
      const index = positions.indexOf(item.id) + 1
      return { ...item, order: index }
    })
    return {
      ...state,
      list: newList.sort((a, b) => {
        if (a.order < b.order) return -1
        if (a.order > b.order) return 1
        return 0
      }),
    }
  },
  [types.LOOKUP_SET_LANGUAGE]: (state, { language }) => ({
    ...state,
    language,
  }),
  [types.LOOKUP_SET_LOOKUP]: (state, { list, child }) => ({
    ...state,
    list,
    child,
    loaded: true,
  }),
})

export default lookupReducer
