import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ChartStackedBarH = (props) => (
  <SvgIcon viewBox="0 0 1280 1280" {...props}>
    <path d="M160 640v534h960v-108H267V960h107V746H267V640h160V426H267V320h107V106H160v534z" />
    <path d="M426 213v107h108V106H426v107zM480 533v107h267V426H480v107zM800 533v107h160V426H800v107zM426 853v107h161V746H426v107zM640 853v107h107V746H640v107z" />
  </SvgIcon>
)

export default React.memo(ChartStackedBarH)
