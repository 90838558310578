import { call, put, take, race } from 'redux-saga/effects'
import { show } from 'redux-modal'
import { showMessage } from 'Actions/shell'
import * as types from 'Actions/types'
import { t } from 'Root/app/IntlProvider'
import { messageLevel } from 'Utils/constants'

export function* confirmSaga(action) {
  const { title, content, options, resolve } = action
  yield put(show('confirm', { title, content, ...options }))
  const { no, yes } = yield race({
    cancel: take(types.SHELL_CONFIRM_CANCEL),
    yes: take(types.SHELL_CONFIRM_YES),
    no: take(types.SHELL_CONFIRM_NO),
  })
  const result = (options || {}).cancelVisible
    ? yes
      ? true
      : no
      ? false
      : null
    : !!yes
  if (resolve) {
    yield call(resolve, result)
  } else {
    return result
  }
}

export function* messageSaga(action) {
  const { title, content } = action
  yield put(
    show('confirm', { title, content, noVisible: false, yesCaption: 'OK' })
  )
  yield race({
    yes: take(types.SHELL_CONFIRM_YES),
  })
}

export function* globalError(data, reject) {
  if (reject) {
    yield call(reject)
  }
  console.log(data)
  if (data && data.error === 'Unauthorized') {
    yield put(showMessage(t('global.error.unauthorized'), messageLevel.error))
  } else if (data && data.error === 'Edit not allowed') {
    yield put(showMessage(t('global.error.notAllowed'), messageLevel.error))
  } else if (data && data.error === 'No access') {
    yield put(showMessage(t('global.error.noAccess'), messageLevel.error))
  } else if (data && ((data.error || {}).message || '') === 'Network Error') {
    yield put(showMessage(t('global.error.noNetwork'), messageLevel.error))
  } else if (data && (data.error || '').toLowerCase() === 'invalid id format') {
    yield put(showMessage(t('global.error.invalidId'), messageLevel.error))
  } else {
    yield put(showMessage(t('global.error.unknown'), messageLevel.error))
  }
  if (data) {
    console.error(data.error)
  }
}
