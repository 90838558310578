import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TextBoxOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M5 3c-1.11 0-2 .89-2 2v14c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2V5c0-1.11-.89-2-2-2H5m0 2h14v14H5V5m2 2v2h10V7H7m0 4v2h10v-2H7m0 4v2h7v-2H7z" />
  </SvgIcon>
)

export default React.memo(TextBoxOutline)
