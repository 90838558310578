import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { reduxForm, Field, Form } from 'redux-form'
import GridContainer from 'Components/common/GridContainer'
import GridItem from 'Components/common/GridItem'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Base from 'Components/shell/PublicBase'
import MessageField from 'Components/common/form/MessageField'
import withTranslate from 'Root/app/withTranslate'
import { getGdprInfo, sendGdprRequest } from 'Actions/candidate'

const styles = () => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  consent: {
    display: 'flex',
  },
  decline: {
    marginRight: 5,
  },
  marginBottom: {
    marginTop: 40,
    marginBottom: 10,
  },
  privacy: {
    display: 'flex',
    alignItems: 'flex-end',
  },
})

class Request extends Component {
  state = {
    submitted: false,
    token: this.props.match.params.token,
  }

  componentDidMount() {
    const { type } = this.props
    const { token } = this.state
    this.props.getGdprInfo(token, type)
  }
  sendGdprRequest = (values) =>
    new Promise((resolve, reject) => {
      this.props.sendGdprRequest({
        values,
        resolve,
        reject,
      })
    })

  handleDecline = async () => {
    const { token } = this.state
    const { id, type } = this.props
    const data = { id, token, type, decline: true }
    try {
      await this.sendGdprRequest(data)
      this.setState({ submitted: true })
    } catch (e) {}
  }

  handleSubmit = async (values) => {
    const { token } = this.state
    const { id, type } = this.props
    const data = { id, token, type }
    if (type !== 'consent') {
      data.message = values.message
    }
    try {
      await this.sendGdprRequest(data)
      this.setState({ submitted: true })
    } catch (e) {}
  }

  handlePrivacyClick = (e) => {
    const { privacyUrl } = this.props
    e.stopPropagation()
    window.open(privacyUrl, '_blank')
  }

  render() {
    const {
      classes,
      company,
      handleSubmit,
      loaded,
      notFound,
      privacyUrl,
      submitting,
      t,
      type,
    } = this.props
    if (!loaded) {
      return <div />
    }
    const { submitted } = this.state
    const consent = type === 'consent'
    return (
      <Base
        title={t(`candidate.gdpr.request.${type}.title`)}
        width={550}
        maxWidth={550}
        company={company}
      >
        <Form
          onSubmit={handleSubmit(this.handleSubmit)}
          autoComplete="off"
          noValidate
        >
          {submitted && (
            <Typography>
              <FormattedMessage id={`candidate.gdpr.request.submitted`} />
            </Typography>
          )}
          {!submitted && notFound && (
            <Typography>
              <FormattedMessage id={`candidate.gdpr.request.notFound`} />
            </Typography>
          )}
          {!submitted && !notFound && (
            <>
              <GridContainer direction="column">
                <GridItem errorHeight xs={12}>
                  <Typography>
                    <FormattedHTMLMessage
                      id={`candidate.gdpr.request.${type}.info`}
                      values={{ company }}
                    />
                  </Typography>
                </GridItem>
                <GridItem errorHeight xs={12}>
                  <Typography>
                    <FormattedHTMLMessage
                      id={`candidate.gdpr.request.${
                        consent ? 'info' : 'company'
                      }`}
                      values={{ company }}
                    />
                  </Typography>
                </GridItem>
                {consent && privacyUrl && (
                  <GridItem errorHeight xs={12}>
                    <Typography>
                      <FormattedHTMLMessage
                        id="candidate.gdpr.request.privacy"
                        values={{ company }}
                      />
                    </Typography>
                  </GridItem>
                )}
                {!consent && (
                  <GridItem xs={12}>
                    <Field
                      name="message"
                      component={MessageField}
                      fullWidth
                      placeholder={t(
                        `candidate.gdpr.request.${type}.message.placeholder`
                      )}
                      label={t(`candidate.gdpr.request.${type}.message.label`)}
                    />
                  </GridItem>
                )}
                <GridItem className={classes.marginBottom} xs={12}>
                  {consent ? (
                    <div className={classes.buttons}>
                      <div className={classes.privacy}>
                        {privacyUrl && (
                          <a
                            href={privacyUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FormattedMessage id="candidate.gdpr.button.privacy" />
                          </a>
                        )}
                      </div>
                      <div className={classes.consent}>
                        <Button
                          className={classes.decline}
                          onClick={this.handleDecline}
                        >
                          <FormattedMessage id="candidate.gdpr.button.decline" />
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          disabled={submitting}
                        >
                          <FormattedMessage id="candidate.gdpr.button.yes" />
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <GridContainer justifyContent="center">
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={submitting}
                      >
                        <FormattedMessage id="candidate.gdpr.button.submit" />
                      </Button>
                    </GridContainer>
                  )}
                </GridItem>
              </GridContainer>
            </>
          )}
        </Form>
      </Base>
    )
  }
}

export default compose(
  withTranslate,
  connect(
    (state, { t, type }) => ({
      company: state.candidate.gdpr.company,
      id: state.candidate.gdpr.id,
      initialValues: {
        message:
          state.candidate.gdpr.message ||
          t(`candidate.gdpr.request.${type}.message.default`),
      },
      loaded: state.candidate.gdpr.loaded,
      notFound: state.candidate.gdpr.notFound,
      privacyUrl: state.candidate.gdpr.privacyUrl,
    }),
    { getGdprInfo, sendGdprRequest }
  ),
  withStyles(styles),
  reduxForm({
    form: 'request',
    enableReinitialize: true,
  })
)(Request)
