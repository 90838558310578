import { http } from 'Utils/http'
import { call, put, select } from 'redux-saga/effects'
import { globalError } from './shell'
import { getActivitiesSucceed } from 'Actions/activity'

export function* getActivitiesSaga(action) {
  const { user, more } = action
  const state = yield select()
  const page = more ? state.activity.info.page + 1 : 1
  const { ok, error, data } = yield http.get(`/activities/${user}?page=${page}`)
  if (ok) {
    const { list, hasMore } = data
    const listData = !more ? list : [...state.activity.list, ...list]
    yield put(getActivitiesSucceed({ list: listData, hasMore, page }))
  } else if (error) {
    yield put(getActivitiesSucceed({ list: [], hasMore: false, page: 1 }))
    yield call(globalError, data)
  }
}
