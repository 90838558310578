import { http } from 'Utils/http'
import { call, put } from 'redux-saga/effects'
import { setData } from 'Actions/apply'

export function* getDataSaga(action) {
  const { companySlug, jobSlug, search } = action
  if (!companySlug) {
    return yield put(setData({ notFoundCompany: true }))
  }
  const { ok, error, data } = yield http.get(
    `/apply/${companySlug}${jobSlug ? '/' + jobSlug : ''}${search || ''}`
  )
  if (ok) {
    yield put(setData(data))
  } else if (error) {
    if ((data || {}).error === 'Job not found') {
      yield put(setData({ notFoundJob: true }))
    } else {
      yield put(setData({ notFoundCompany: true }))
    }
  }
}

export function* postDataSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { companySlug, jobSlug, data } = values

  const { ok } = yield http.post(`/apply/${companySlug}/${jobSlug || ''}`, data)
  if (ok) {
    yield call(resolve)
  } else {
    yield call(reject)
  }
}
