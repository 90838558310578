import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagTurkeys = (props) => (
  <SvgIcon viewBox="0 0 450 300" {...props}>
    <path fill="#d80027" d="M0 150V0h450v300H0V150z" />
    <path
      fill="#FFF"
      d="M247.3 118.3l35.2 5.8L307.4 99l5.1 32.8L344 150l-31.5 18.2-4.9 33.5-25.1-25.9-35.2 5.8L263 150z"
    />
    <path
      fill="#FFF"
      d="M240.6 228.6c-43.3 0-78.3-35.1-78.3-78.3S197.4 72 240.6 72c8.1 0 15.8 1.2 23.2 3.5-17.3-15-39.8-24-64.5-24-54.4 0-98.6 44.1-98.6 98.6s44.1 98.6 98.6 98.6c24.2 0 46.3-8.7 63.4-23.1-7 1.9-14.4 3-22.1 3z"
    />
  </SvgIcon>
)

export default React.memo(FlagTurkeys)
