import * as types from './types'
import { createAction } from 'Utils/redux'

export const getDefinition = createAction(types.DASHBOARD_GET_DEFINITION)
export const gotoTab = createAction(types.DASHBOARD_GOTO_TAB, 'tab')
export const setDefinition = createAction(
  types.DASHBOARD_SET_DEFINITION,
  'data'
)
export const setDefinitionLoaded = createAction(
  types.DASHBOARD_SET_DEFINITION_LOADED
)
export const setLoading = createAction(types.DASHBOARD_SET_LOADING, 'value')
export const setPositionTop = createAction(
  types.DASHBOARD_SET_POSITION_TOP,
  'tab',
  'value'
)
export const setTabName = createAction(types.DASHBOARD_SET_TAB_NAME, 'payload')
export const widgetAdd = createAction(types.DASHBOARD_WIDGET_ADD, 'payload')
export const widgetGet = createAction(types.DASHBOARD_WIDGET_GET, 'payload')
export const widgetMaximize = createAction(
  types.DASHBOARD_WIDGET_MAXIMIZE,
  'tab',
  'widget'
)
export const widgetMove = createAction(types.DASHBOARD_WIDGET_MOVE, 'payload')
export const widgetRemove = createAction(
  types.DASHBOARD_WIDGET_REMOVE,
  'payload'
)
export const widgetSet = createAction(types.DASHBOARD_WIDGET_SET, 'data')
export const widgetLegendVisible = createAction(
  types.DASHBOARD_WIDGET_LEGEND_VISIBLE,
  'widget',
  'id'
)
export const widgetLegendInvisible = createAction(
  types.DASHBOARD_WIDGET_LEGEND_INVISIBLE,
  'widget',
  'id'
)
export const widgetSetSetting = createAction(
  types.DASHBOARD_WIDGET_SET_SETTING,
  'payload'
)
export const widgetSize = createAction(types.DASHBOARD_WIDGET_SIZE, 'payload')
