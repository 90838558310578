import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagJapan = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <path fill="#FFF" d="M0 85.331h512v341.337H0z" />
    <circle fill="#D80027" cx={256} cy={255.994} r={96} />
  </SvgIcon>
)

export default React.memo(FlagJapan)
