import { call, delay, put, select, take } from 'redux-saga/effects'
import { show } from 'redux-modal'
import { http } from 'Utils/http'
import { showMessage } from 'Actions/shell'
import { hide } from 'redux-modal'
import { addTag, removeTag } from 'Actions/cache'
import {
  addTagSucceed,
  created as entityCreated,
  deleteItem,
  expandNote,
  get,
  getActivitiesSucceed,
  getAttachmentsSucceed,
  getCertificates,
  getCertificatesSucceed,
  getCertificateUrlSucceed,
  getEmailsSucceed,
  getEventsSucceed,
  getFiles,
  getLastNoteSucceed,
  getNotes,
  getNotesSucceed,
  getNotesUsersSucceed,
  getSucceed,
  getTasksSucceed,
  notesCollapseAll,
  noteSetViewers,
  removeTagSucceed,
  setData,
  setDataItem,
  setEditMode,
  setFilesLoading,
  setHasNotes,
  setId,
  setInsights,
  setLoading,
  setNoteSecuredSucceed,
  setNotFound,
  setSaving,
  setUnsavedNote,
  updateData,
  uploadNoteAttachmentEnd,
  uploadNoteAttachmentStart,
  uploadNoteAttachmentSucceed,
} from 'Actions/entity'
import { setData as setCandidateData, getViewers } from 'Actions/candidate'
import { getCandidateViewers } from 'Actions/job'
import { getFunnel } from 'Actions/funnel'
import { load, setSelected } from 'Actions/table'
import { gotoUrl } from 'Actions/app'
import { getNumber } from 'Actions/deletion'
import { confirmSaga, globalError } from './shell'
import { getEntity, getEntityCaption, t } from 'Root/app/IntlProvider'
import { messageLevel } from 'Utils/constants'
import * as types from 'Actions/types'
import storage from 'Utils/storage'
import { isRoleHiring, plugins, isPluginAvailable } from 'Selectors/auth'

export function* addNoteSaga(action) {
  const { backUrl, id, content, noteType, rating, ref, replyId, secured } =
    action
  const state = yield select()
  const entity = state.entity.info.entityType
  const entities = yield call(getEntity, entity, true)
  const companyId = state.auth.company.id
  let phase = null
  let refEntity = null
  let refId = ref
  if (entity === 'job' && ref) {
    // get phase
    const found = state.job.board.phases.find((x) =>
      x.candidates.find((candidate) => candidate.id === ref)
    )
    if (found) {
      phase = found.id
    }
    refEntity = 'candidate'
  } else if (entity === 'candidate' && isRoleHiring(state)) {
    const { ok, data } = yield http.get(`/candidates/${id}/jobs`)
    const handleIdChange = (id) => {
      refId = id
    }
    if (ok) {
      const jobs = data
        .filter((x) => x.companyId === companyId)
        .map((item) => ({
          id: item.jobId,
          description: item.name,
        }))
      if (jobs.length === 1) {
        refEntity = 'job'
        refId = jobs[0].id
      } else if (jobs.length > 1) {
        refEntity = 'job'
        refId = jobs[0].id
        yield put(
          show('selectJob', {
            jobs,
            onIdChange: handleIdChange,
            refId,
          })
        )
        yield take(types.SHELL_CONFIRM_YES)
      }
    }
  }
  if (!refEntity) {
    refId = null
  }
  const { created, error, data } = yield http.post(`/${entities}/${id}/notes`, {
    content,
    phase,
    refEntity,
    refId,
    replyId,
    type: noteType,
    rating,
    secured,
  })
  if (created) {
    if (backUrl && backUrl.includes(id)) {
      yield put(gotoUrl({ url: backUrl }))
    } else {
      yield put(getNotes(id, true))
    }
    yield put(setHasNotes(true))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* addTagSaga(action) {
  const { tag } = action
  yield put(addTagSucceed(tag))
  const state = yield select()
  yield put(addTag(tag, state.entity.info.entityType))
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, error, notFound, data } = yield http.post(
    `/${entity}/${state.entity.info.id}/tags/${encodeURIComponent(tag)}`
  )
  if (ok) {
    // ok
  } else if (notFound) {
    const item = yield call(getEntityCaption, state.entity.info.entityType)
    yield put(
      showMessage(
        t('entity.message.tagNotFound', { item }),
        messageLevel.warning
      )
    )
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* anonymizeItemSaga(action) {
  const { id, confirm = true } = action
  const state = yield select()
  const item = yield call(getEntityCaption, state.entity.info.entityType)
  if (confirm) {
    const ok = yield call(confirmSaga, {
      title: t('entity.message.anonymize.title', { item }),
      content: t('entity.message.anonymize.content', {
        item: item.toLowerCase(),
      }),
      options: { delay: 3000 },
    })
    if (!ok) {
      return
    }
  }
  // const entity = yield call(getEntity, state.entity.info.entityType, true)
  // const { data, ok, error } = yield http.put(`/${entity}/${id}/anonymize`)
  // if (ok) {
  // delete item
  yield put(deleteItem(id, false, true))
  // } else if (error) {
  //   yield call(globalError, data)
  // }
}

const autoSaveKey = (entity, id) => `note:${entity}:${id}`

export function* changeCompanyIdSaga(action) {
  const { companyId } = action
  if (!companyId) {
    return
  }
  const state = yield select()
  const { id, entityType: entity } = state.entity.info
  const item = yield call(getEntity, entity, true)
  const { ok, error, data } = yield http.put(
    `/${item}/${id}/company/${companyId}`
  )
  if (ok) {
    yield put(hide('changeCompanyId'))
    yield put(get(id))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* createSaga(action) {
  const {
    payload: { entityType, open, values, resolve, reject },
  } = action
  const state = yield select()
  const item = yield call(getEntity, entityType, true)
  const editmode = state.auth.user.settings.openNewEntityInEditmode
  const { created, error, data, tooLarge, tooManyRequests } = yield http.post(
    `/${item}`,
    values
  )
  if (created && !!data && !!data.id) {
    yield call(resolve, data.id)
    if (
      state.table.info.entityType === entityType &&
      !open &&
      entityType !== 'mail'
    ) {
      yield delay(2500)
      yield put(load(0, true))
    }
    if (open && data.id) {
      yield put(gotoUrl({ url: `/${item}/${data.id}`, prevLocationEntity: '' }))
      yield put(setEditMode(editmode))
    } else {
      const entity = yield call(getEntityCaption, entityType)
      yield put(
        showMessage(
          t('entity.create.message.succeed', { entity }),
          messageLevel.success
        )
      )
    }
    yield put(entityCreated(entityType, open))
    if (entityType === 'relation') {
      yield put(getFunnel())
    }
  } else if (tooManyRequests) {
    yield put(
      showMessage(t('global.error.tooManyRequests'), messageLevel.error)
    )
    yield call(reject)
  } else if (tooLarge) {
    yield put(
      showMessage(t('entity.create.error.tooLarge'), messageLevel.error)
    )
    yield call(reject)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* deleteCertificateSaga(action) {
  const { id } = action
  const state = yield select()
  const item = yield call(getEntityCaption, state.entity.info.entityType)
  const ok = yield call(confirmSaga, {
    title: t('entity.message.deleteCertificate.title'),
    content: t('entity.message.deleteCertificate.content', {
      item: item.toLowerCase(),
    }),
  })
  if (!ok) {
    return
  }
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const entityId = state.entity.info.id
  const { noContent, error, data } = yield http.delete(
    `/${entity}/${entityId}/certificates/${id}`
  )
  if (noContent) {
    yield put(getCertificates(entityId))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* deleteItemSaga(action) {
  try {
    const { id, confirm = true, anonymize = false } = action
    const state = yield select()
    const item = yield call(getEntityCaption, state.entity.info.entityType)
    if (confirm) {
      const ok = yield call(confirmSaga, {
        title: t('entity.message.delete.title', { item }),
        content: t('entity.message.delete.content', {
          item: item.toLowerCase(),
        }),
        options: { delay: 2000 },
      })
      if (!ok) {
        return
      }
    }
    const entity = yield call(getEntity, state.entity.info.entityType, true)
    const { data, noContent, forbidden, notFound } = yield http.delete(
      `/${entity}/${id}${anonymize ? '/anonymize' : ''}`
    )
    if (forbidden) {
      yield put(
        showMessage(
          t('entity.delete.error.forbidden', { name: data.name }),
          messageLevel.error
        )
      )
    } else if (noContent || notFound) {
      yield delay(2000)
      yield put(gotoUrl({ url: `/${entity}/` }))
      yield put(setSelected([]))
      yield put(load(state.table.info.skip))
      yield put(getNumber())
    }
  } catch (err) {
    console.log('Delete error', err)
  }
}

export function* deleteNoteSaga(action) {
  const { id, noteId } = action
  const state = yield select()
  const item = yield call(getEntityCaption, state.entity.info.entityType)
  const ok = yield call(confirmSaga, {
    title: t('entity.message.deleteNote.title'),
    content: t('entity.message.deleteNote.content', {
      item: item.toLowerCase(),
    }),
  })
  if (!ok) {
    return
  }
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { noContent, error, data } = yield http.delete(
    `/${entity}/${id}/notes/${noteId}`
  )
  if (noContent) {
    const state = yield select()
    yield put(setHasNotes(state.entity.notes.length > 1))
    yield put(getNotes(id))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* deleteUnsavedNoteSaga(action) {
  const { entity, id } = action
  const key = autoSaveKey(entity, id)
  storage.remove(key)
  yield delay(20)
  // yield http.delete(`/autosave/${entity}/${id}/note`)
}

export function* expandNoteSaga(action) {
  const { id, noteId, value } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, data } = yield http.post(
    `/${entity}/${id}/notes/${noteId}/expand`,
    { value }
  )
  if (!ok) {
    yield call(globalError, data)
  }
}

export function* getActivitiesSaga(action) {
  const { id, more } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const page = more ? state.entity.activities.info.page + 1 : 1
  const { ok, error, data } = yield http.get(
    `/${entity}/${id}/activities?page=${page}`
  )
  if (ok) {
    const { list, hasMore } = data
    const listData = !more ? list : [...state.entity.activities.list, ...list]
    yield put(getActivitiesSucceed(id, { list: listData, hasMore, page }))
  } else if (error) {
    yield put(getActivitiesSucceed(id, { list: [], hasMore: false, page: 1 }))
    yield call(globalError, data)
  }
}

export function* getAttachmentsSaga(action) {
  const { id } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, notFound, error, data } = yield http.get(
    `/${entity}/${id}/attachments`
  )
  if (ok) {
    yield put(getAttachmentsSucceed(id, data))
  } else if (notFound) {
    yield put(getAttachmentsSucceed(id, []))
  } else if (error) {
    yield put(getAttachmentsSucceed(id, []))
    yield call(globalError, data)
  }
}

export function* getCertificateUrlSaga(action) {
  const { id } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const entityId = state.entity.info.id
  const { ok, error, data } = yield http.get(
    `/${entity}/${entityId}/certificates/${id}/url`
  )
  if (ok) {
    yield put(getCertificateUrlSucceed(id, data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getCertificatesSaga(action) {
  const { id } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, error, data } = yield http.get(`/${entity}/${id}/certificates`)
  if (ok) {
    yield put(getCertificatesSucceed(id, data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getEmailsSaga(action) {
  const { id } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, error, data } = yield http.get(`/${entity}/${id}/emails`)
  if (ok) {
    yield put(getEmailsSucceed(id, data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getEventsSaga(action) {
  const { id, limit } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, error, data } = yield http.get(
    `/${entity}/${id}/events?limit=${limit}`
  )
  if (ok) {
    yield put(getEventsSucceed(data, limit))
  } else if (error) {
    yield put(getEventsSucceed([], limit))
    yield call(globalError, data)
  }
}

export function* getFilesSaga(action) {
  const { entity, id } = action
  if (entity === 'candidate' || entity === 'job') {
    yield put(setFilesLoading(true))
    const entities = yield call(getEntity, entity, true)
    const { ok, error, data } = yield http.get(`/${entities}/${id}/files`)
    if (ok) {
      yield put(updateData(data || {}))
    } else if (error) {
      yield call(globalError, data)
    }
    yield put(setFilesLoading(false))
  }
}

export function* getInsightsUrlSaga(action) {
  const { id, tab } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, error, data } = yield http.get(
    `/${entity}/${id}/insights${tab ? '/' + tab : ''}`
  )
  if (ok) {
    yield put(setInsights(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getLastNoteSaga(action) {
  const { id } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, error, data, notFound } = yield http.get(
    `/${entity}/${id}/notes/last`
  )
  if (ok) {
    yield put(getLastNoteSucceed(id, data))
  } else if (notFound) {
    yield put(getLastNoteSucceed(id, {}))
  } else if (error) {
    yield put(getLastNoteSucceed(id, {}))
    yield call(globalError, data)
  }
}

export function* getNotesSaga(action) {
  const { expandId, id, openFirst } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, error, data } = yield http.get(`/${entity}/${id}/notes`)
  if (ok) {
    yield put(getNotesSucceed(id, data, openFirst))
    if (state.entity.info.entityType === 'candidate') {
      // update note ratings
      const noteRatings = data
        .filter((note) => note.type === 'evaluation')
        .map((note) => note.rating)
      yield put(setDataItem({ noteRatings }))
    }
    if (expandId) {
      yield put(notesCollapseAll())
      yield put(expandNote(id, expandId, true))
    }
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getNotesUsersSaga(action) {
  const { id } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, data } = yield http.get(`/${entity}/${id}/notes/users`)
  if (ok) {
    yield put(getNotesUsersSucceed(data))
  } else {
    yield call(globalError, data)
  }
}

export function* getSaga(action) {
  const { id } = action
  yield put(setId(id))
  try {
    yield put(setLoading(true))
    const state = yield select()
    const realEntity = state.entity.info.entityType
    if (!realEntity) {
      return
    }
    const entity = yield call(getEntity, realEntity, true)
    const { ok, error, notFound, data } = yield http.get(`/${entity}/${id}`)
    if (ok) {
      if (realEntity === 'candidate') {
        const extra = {
          educations: data.educations,
          employments: data.employments,
          skills: data.skills,
        }
        delete data.skills
        delete data.educations
        delete data.employments
        yield put(setCandidateData(extra))
      }
      yield put(setData(data))
      yield put(getFiles(realEntity, id))
      yield put(getSucceed(id, realEntity))
    } else if (notFound) {
      if (data && data.error && !data.error.includes('not found')) {
        console.log(data.error)
        yield put(setNotFound(data.error))
      }
      yield put(setData({}))
    } else if (error) {
      yield call(globalError, data)
    }
  } finally {
    yield put(setLoading(false))
  }
}

export function* getTasksSaga(action) {
  const { id, limit } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, error, data } = yield http.get(`/${entity}/${id}/tasks`)
  if (ok) {
    yield put(getTasksSucceed(data))
  } else if (error) {
    yield put(getTasksSucceed([], limit))
    yield call(globalError, data)
  }
}

export function* getUnsavedNoteSaga(action) {
  const { entity, id } = action
  const key = autoSaveKey(entity, id)
  const data = storage.get(key)
  let values = { id, content: '', ref: null, secured: false }
  if (data) {
    values = JSON.parse(data)
  }
  yield put(setUnsavedNote(values))
}

export function* markSaga(action) {
  const { id } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, error, data } = yield http.post(`/${entity}/${id}/mark`)
  if (ok) {
    // ok
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* refreshSaga() {
  const state = yield select()
  const { id } = state.entity.info
  yield put(get(id))
}

export function* noteGetViewersSaga(action) {
  const { ref } = action
  const state = yield select()
  yield put(noteSetViewers([]))
  const hasViewer = isPluginAvailable(state, plugins.viewer)

  const shares = state.entity.data.shares || 0
  const role = state.auth.user.role
  if (
    !hasViewer ||
    shares === 0 ||
    !['admin', 'user', 'hiring'].includes(role)
  ) {
    return
  }
  const type = state.entity.info.entityType
  const id = state.entity.data.id
  if (type === 'candidate') {
    yield put(getViewers(id))
  } else if (type === 'job' && ref) {
    yield put(getCandidateViewers(id, ref))
  }
}

export function* removeTagSaga(action) {
  const { tag } = action
  yield put(removeTagSucceed(tag))
  const state = yield select()
  yield put(removeTag(tag, state.entity.info.entityType))
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { noContent, error, notFound, data } = yield http.delete(
    `/${entity}/${state.entity.info.id}/tags/${encodeURIComponent(tag)}`
  )
  if (noContent) {
    // ok
  } else if (notFound) {
    const item = yield call(getEntityCaption, state.entity.info.entityType)
    yield put(
      showMessage(t('entity.message.notFound', { item }), messageLevel.warning)
    )
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* restoreSaga(action) {
  const { id } = action
  const state = yield select()
  const item = yield call(getEntityCaption, state.entity.info.entityType)
  const yes = yield call(confirmSaga, {
    title: t('entity.message.restore.title', {
      item,
    }),
    content: t('entity.message.restore.content', {
      item: item.toLowerCase(),
    }),
  })
  if (!yes) {
    return
  }
  const entities = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, error, notFound, data } = yield http.post(
    `/${entities}/${id}/restore`
  )
  if (ok) {
    yield put(get(id))
    yield delay(2000)
    yield put(getNumber())
  } else if (notFound) {
    yield put(
      showMessage(t('entity.message.notFound', { item }), messageLevel.warning)
    )
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* saveUnsavedNoteSaga(action) {
  const { entity, id, values } = action
  if (!id) {
    return
  }
  const key = autoSaveKey(entity, id)
  const data = JSON.stringify({ id, ...values })
  storage.set(key, data)
  yield delay(20)
  // yield http.post(`/autosave/${entity}/${id}/note`, { values })
}

export function* setNoteSecuredSaga(action) {
  const { id, noteId, value } = action
  const yes = yield call(confirmSaga, {
    title: t(`entity.message.note.secure.${value}.title`),
    content: t(`entity.message.note.secure.${value}.content`),
  })
  if (!yes) {
    return
  }

  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  yield put(setNoteSecuredSucceed(id, noteId, value))
  yield http.put(`/${entity}/${id}/notes/${noteId}`, { secured: value })
}

export function* unMarkSaga(action) {
  const { id } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, error, data } = yield http.delete(`/${entity}/${id}/mark`)
  if (ok) {
    // ok
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* updateSaga(action) {
  const { id, values } = action
  if (typeof id === 'undefined') {
    return
  }
  yield put(setSaving(true))
  const state = yield select()
  const realEntity = state.entity.info.entityType
  const entity = yield call(getEntity, realEntity, true)
  const { ok, error, notFound, notAllowed, data, tooManyRequests } =
    yield http.put(`/${entity}/${id}`, values)
  if (ok) {
    yield put(setData(data))
    yield put(getFiles(realEntity, id))
    yield put(setEditMode(false))
  } else if (tooManyRequests) {
    yield put(
      showMessage(t('global.error.tooManyRequests'), messageLevel.error)
    )
  } else if (notFound) {
    const item = yield call(getEntityCaption, state.entity.info.entityType)
    yield put(
      showMessage(t('entity.message.notFound', { item }), messageLevel.warning)
    )
  } else if (notAllowed) {
    yield put(showMessage(t('entity.message.notAllowed'), messageLevel.warning))
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setSaving(false))
}

export function* updateCertificateSaga(action) {
  const { id, values } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const entityId = state.entity.info.id
  const { ok, data } = yield http.put(
    `/${entity}/${entityId}/certificates/${id}`,
    values
  )
  if (ok) {
    yield put(getCertificates(entityId))
  } else {
    yield call(globalError, data)
  }
}

export function* updateNoteSaga(action) {
  const { id, noteId, content, rating } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, data } = yield http.put(`/${entity}/${id}/notes/${noteId}`, {
    content,
    rating,
  })
  yield put(getNotes(id))
  if (!ok) {
    yield call(globalError, data)
  }
}

export function* updateNoteTypeSaga(action) {
  const { id, noteId, noteType } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, data } = yield http.put(`/${entity}/${id}/notes/${noteId}`, {
    type: noteType,
  })
  yield put(getNotes(id))
  if (!ok) {
    yield call(globalError, data)
  }
}

export function* uploadNoteAttachmentSaga(action) {
  const { id, noteId, file, ref, secured } = action
  const state = yield select()
  const entity = state.entity.info.entityType
  const entities = yield call(getEntity, entity, true)
  yield put(uploadNoteAttachmentStart(noteId))
  if (noteId === '0') {
    let phase = null
    let refEntity = null
    if (entity === 'job' && ref) {
      // get phase
      const found = state.job.board.phases.find((x) =>
        x.candidates.find((candidate) => candidate.id === ref)
      )
      if (found) {
        phase = found.id
      }
      refEntity = 'candidate'
    }
    const { created, error, data, tooLarge } = yield http.post(
      `/${entities}/${id}/attachment`,
      {
        ...file,
        phase,
        refEntity: refEntity || undefined,
        refId: refEntity ? ref : undefined,
        secured,
      }
    )
    if (created) {
      yield put(uploadNoteAttachmentSucceed(noteId, data))
      yield put(getNotes(id))
      yield put(setHasNotes(true))
    } else if (tooLarge) {
      yield put(
        showMessage(
          t('entity.notes.error.attachment.tooLarge'),
          messageLevel.error
        )
      )
    } else if (error) {
      yield call(globalError, data)
    }
  } else {
    const { created, error, data } = yield http.post(
      `/${entities}/${id}/notes/${noteId}/attachment`,
      file
    )
    if (created) {
      yield put(uploadNoteAttachmentSucceed(noteId, data))
    } else if (error) {
      yield call(globalError, data)
    }
  }
  yield put(uploadNoteAttachmentEnd(noteId))
}
