import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'
import moment from 'moment'

const calendarReducer = createReducer(initialState.event.calendar, {
  [types.EVENT_CALENDAR_CHANGE_VIEW]: (state, { view }) => ({
    ...state,
    currentView: view,
  }),
  [types.EVENT_CALENDAR_SET_DATE]: (state, { date }) => {
    return {
      ...state,
      date,
    }
  },
  [types.EVENT_GET_RANGE_SUCCEED]: (state, { endAt, startAt }) => ({
    ...state,
    endAt,
    startAt,
  }),
  [types.EVENT_GET_SHARED_AGENDAS_SUCCEED]: (state, { users }) => {
    return {
      ...state,
      sharedBy: users,
    }
  },
  [types.EVENT_SET_FILTER]: (state, { filter }) => ({
    ...state,
    filter,
  }),
  [types.EVENT_SET_SHARED_USERS]: (state, { users }) => ({
    ...state,
    users,
  }),

  [types.EVENT_TOGGLE_SHOW_USERS]: (state) => {
    return {
      ...state,
      showUsers: !state.showUsers,
    }
  },
})

const infoReducer = createReducer(initialState.event.info, {
  [types.EVENT_SET_FORM_OPEN]: (state, { value }) => ({
    ...state,
    formOpen: value,
  }),

  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.event.info,
})

const itemListReducer = createReducer(initialState.event.itemList, {
  [types.EVENT_CLEAR_NEW_ITEM]: () => initialState.event.itemList,
  [types.EVENT_GET_ITEM_RANGE_SUCCEED]: (state, { data = [], id }) => {
    const find = state.find((x) => x.id === id)
    let items = data
    if (find) {
      items = data.filter((x) => x.id !== find.id)
    }
    items = items.map(({ startAt, endAt, ...rest }) => ({
      startAt: moment(startAt).toDate(),
      endAt: moment(endAt).toDate(),
      backgroundColor: rest.id !== id && '#f0f0f0',
      color: rest.id !== id && 'black',
      ...rest,
      isDraggable: rest.id === id,
    }))
    if (find) {
      const { backgroundColor, color, ...findRest } = find
      return [...items, { ...findRest, plan: true }]
    } else {
      return [...items]
    }
  },
  [types.EVENT_SET_ITEM]: (state, { id, values }) => {
    const find = state.find((x) => x.id === id)
    const items = [...state.filter((x) => x.id !== id)]
    if (find) {
      return [...items, { ...find, ...values }]
    } else {
      return items
    }
  },
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.event.itemList,
})

const itemReducer = createReducer(initialState.event.item, {
  [types.EVENT_GET_ITEM_SUCCEED]: (state, { data }) => ({ ...(data || {}) }),
  [types.EVENT_SET_NEW_ITEM]: (state, { values }) => ({
    id: 'new',
    type: 'onsite',
    ...values,
  }),
  [types.EVENT_UPDATE_ITEM]: (state, { values }) => {
    return { ...state, ...values }
  },
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.event.item,
})

const locationsReducer = createReducer(initialState.event.locations, {
  [types.EVENT_GET_LOCATIONS_SUCCEED]: (state, action) => action.data,
})

const listReducer = createReducer(initialState.event.list, {
  [types.EVENT_CLEAR_NEW_ITEM]: (state) => state.filter((x) => x.id !== 'new'),
  [types.EVENT_CREATE_SUCCEED]: (state, action) => [...state, action.data],
  [types.EVENT_GET_RANGE_SUCCEED]: (state, action) =>
    (action.data || []).map(({ startAt, endAt, ...rest }) => ({
      startAt: moment(startAt).toDate(),
      endAt: moment(endAt).toDate(),
      ...rest,
    })),
  [types.EVENT_RANGE_ADD]: (state, { values: { startAt, endAt, ...rest } }) => [
    ...state,
    {
      startAt: moment(startAt).toDate(),
      endAt: moment(endAt).toDate(),
      ...rest,
    },
  ],
  [types.EVENT_REMOVE_SUCCEED]: (state, { id }) =>
    state.filter((x) => x.id !== id),
  [types.EVENT_SET_NEW_ITEM]: (state, { values }) => [
    ...state,
    { id: 'new', ...values },
  ],
  [types.EVENT_UPDATE_ITEM]: (state, { values }) => {
    let items = state.filter((x) => x.id !== 'new')
    if (values) {
      const { id, startAt, endAt, allDay } = values
      if (id && (startAt || endAt || typeof allDay !== 'undefined')) {
        const item = items.find((x) => x.id === id)
        if (item) {
          items = items.filter((x) => x.id !== id)
          return [
            ...items,
            {
              ...item,
              startAt: moment(startAt || item.startAt).toDate(),
              endAt: moment(endAt || item.endAt).toDate(),
              allDay: typeof allDay !== 'undefined' ? allDay : item.allDay,
            },
          ]
        }
      }
    }
    return [...items]
  },
  [types.EVENT_UPDATE_SUCCEED]: (state, action) => {
    const { values } = action
    const { id } = values
    const items = state.filter((x) => x.id !== id)
    const { startAt, endAt, ...rest } = values
    return [
      ...items,
      {
        startAt: moment(startAt).toDate(),
        endAt: moment(endAt).toDate(),
        ...rest,
      },
    ]
  },
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.event.list,
})

export default combineReducers({
  calendar: calendarReducer,
  info: infoReducer,
  item: itemReducer,
  itemList: itemListReducer,
  locations: locationsReducer,
  list: listReducer,
})
