import * as types from './types'
import { createAction } from 'Utils/redux'

export const changeLanguage = createAction(
  types.APP_CHANGE_LANGUAGE,
  'language'
)
export const dockNavbar = createAction(types.APP_DOCK_NAVBAR, 'navbarDocked')
export const foldPanels = createAction(types.APP_FOLD_PANELS, 'entity')
export const gotoUrl = createAction(types.APP_GOTO_URL, 'data')

export const openNavbar = createAction(types.APP_OPEN_NAVBAR, 'navbarOpen')
export const registerPanel = createAction(
  types.APP_REGISTER_PANEL,
  'id',
  'expanded'
)
export const sendError = createAction(
  types.APP_SEND_ERROR,
  'error',
  'url',
  'stack'
)
export const setDelimiters = createAction(
  types.APP_SET_DELIMITERS,
  'decimal',
  'thousands'
)
export const setLanguage = createAction(types.APP_SET_LANGUAGE, 'language')
export const setOnline = createAction(types.APP_SET_ONLINE, 'online')
export const setPanelExpanded = createAction(
  types.APP_SET_PANEL_EXPANDED,
  'id',
  'value'
)
export const setVersion = createAction(types.APP_SET_VERSION, 'version')
export const startVersionCheck = createAction(types.APP_START_VERSION_CHECK)
export const showNewVersion = createAction(types.APP_SHOW_NEW_VERSION, 'value')
export const unfoldPanels = createAction(types.APP_UNFOLD_PANELS, 'entity')
