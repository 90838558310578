import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagThailand = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <path fill="#FFF" d="M0 85.334h512V426.66H0z" />
    <path fill="#0052B4" d="M0 194.056h512v123.882H0z" />
    <path d="M0 85.334h512v54.522H0zm0 286.809h512v54.522H0z" fill="#D80027" />
  </SvgIcon>
)

export default React.memo(FlagThailand)
