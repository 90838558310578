import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ContactsOutlineAdd = (props) => (
  <SvgIcon {...props}>
    <path d="M21.904 7.814H8.356c-.931 0-1.693.718-1.693 1.596v9.573c0 .878.762 1.596 1.693 1.596h13.548c.931 0 1.693-.718 1.693-1.596V9.41c0-.878-.762-1.596-1.693-1.596m0 11.169H8.356V9.41h13.548v9.573M8.356 4.623h13.548v1.596H8.356m0 15.955h13.548v1.596H8.356m6.774-9.574c1.168 0 2.117-.893 2.117-1.994s-.949-1.994-2.117-1.994c-1.168 0-2.117.893-2.117 1.994s.949 1.994 2.117 1.994m0-2.792c.466 0 .847.359.847.798 0 .439-.381.798-.847.798-.466 0-.847-.359-.847-.798 0-.439.381-.798.847-.798m4.234 5.984c0-1.676-2.803-2.394-4.234-2.394s-4.233.718-4.233 2.394v.797h8.467v-.797m-6.935-.399c.517-.399 1.719-.798 2.701-.798.991 0 2.193.399 2.709.798h-5.41zM6.94 4.065H4.129v2.811h-.938V4.065H.38v-.938h2.811V.316h.938v2.811H6.94v.938z" />
  </SvgIcon>
)

export default React.memo(ContactsOutlineAdd)
