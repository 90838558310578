import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const DvrAdd = (props) => (
  <SvgIcon {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M21.715 8.756H8.93c-.781 0-1.42.687-1.42 1.527v9.16c0 .839.639 1.526 1.42 1.526h3.551v1.527h5.682v-1.527h3.552c.781 0 1.413-.687 1.413-1.526l.007-9.16c0-.84-.639-1.527-1.42-1.527m0 10.687H8.93v-9.16h12.785v9.16m-1.421-6.87h-7.813V14.1h7.813v-1.527m0 3.053h-7.813v1.527h7.813v-1.527m-8.523-3.053h-1.42V14.1h1.42v-1.527m0 3.053h-1.42v1.527h1.42v-1.527" />
    <path
      d="M4.174.5h1.822v3.643h3.643v1.822H5.996v3.643H4.174V5.965H.531V4.143h3.643z"
      shape="cross 0.531 0.5 9.108 9.108 1.822 1.822 0.5 1@ab8ed6e9"
    />
  </SvgIcon>
)

export default React.memo(DvrAdd)
