import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const UploadCircleOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M8 17v-2h8v2H8m8-7-4-4-4 4h2.5v4h3v-4H16m-4-8c5.5 0 10 4.5 10 10s-4.5 10-10 10S2 17.5 2 12 6.5 2 12 2m0 2c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8Z" />
  </SvgIcon>
)

export default React.memo(UploadCircleOutline)
