import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ShieldOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4m0 4a3 3 0 013 3 3 3 0 01-3 3 3 3 0 01-3-3 3 3 0 013-3m5.13 12A9.69 9.69 0 0112 20.92 9.69 9.69 0 016.87 17c-.34-.5-.63-1-.87-1.53 0-1.65 2.71-3 6-3s6 1.32 6 3c-.24.53-.53 1.03-.87 1.53z" />
  </SvgIcon>
)

export default React.memo(ShieldOutline)
