import { http } from 'Utils/http'
import { call, delay, put, select } from 'redux-saga/effects'
import { hide } from 'redux-modal'
import { submit } from 'redux-form'
import { globalError } from './shell'
import { getEntity } from 'Root/app/IntlProvider'
import { setBusy } from 'Actions/certificate'
import { getCertificates } from 'Actions/entity'
import { messageLevel } from 'Utils/constants'
import { showMessage } from 'Actions/shell'
import { t } from 'Root/app/IntlProvider'

export function* addCertificatesSaga() {
  yield put(submit('addCertificate'))
  yield put(setBusy(true))
  yield delay(200)
  const state = yield select()
  const entity = state.entity.info.entityType
  const entities = yield call(getEntity, entity, true)
  const id = state.entity.info.id
  const certificates = state.certificate.list
  // loop because of file size
  for (const certificate of certificates) {
    const { created, error, data, tooLarge } = yield http.post(
      `/${entities}/${id}/certificates`,
      certificate
    )
    if (created) {
      // update entity.data.certificates
      yield put(getCertificates(id))
    } else if (tooLarge) {
      yield put(
        showMessage(
          t('certificate.add.error.tooLarge', { file: certificate.file.name }),
          messageLevel.error
        )
      )
    } else if (error) {
      yield call(globalError, data)
    }
  }

  yield put(setBusy(false))
  yield put(hide('addCertificate'))
}
