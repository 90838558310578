import React, { useRef, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import Tooltip from 'Components/common/Tooltip'
import { useDebouncedCallback } from 'use-debounce'
import { makeStyles } from '@material-ui/core/styles'
import * as Icons from 'Icons'

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 30,
    maxWidth: 30,
    marginRight: 2,
  },
}))

const SelectButton = ({
  Icon,
  focused,
  items = [],
  noDisable,
  onClick,
  onItemClick,
  tooltip,
  withIcon,
}) => {
  const classes = useStyles()
  const elementRef = useRef()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(false)

  const handleClick = (e) => {
    onClick && onClick(e)
    debounced(e)
  }
  const handleClose = () => setOpen(false)
  const debounced = useDebouncedCallback((e) => {
    setAnchorEl(elementRef.current)
    setOpen(true)
  }, 300)

  const handleMenuClick = (id, description) => () => {
    if (onItemClick) {
      onItemClick(id, description)
    }
    setOpen(false)
  }

  return (
    <Tooltip title={focused || noDisable ? tooltip : ''}>
      <div>
        <Button
          disabled={!focused && !noDisable}
          size="small"
          onClick={handleClick}
          className={classes.button}
          tabIndex={-1}
          ref={elementRef}
        >
          <Icon
            className={classes.iconSmall}
            color={!focused && !noDisable ? 'disabled' : 'action'}
          />
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {items.map((item) => {
            let Icon
            if (withIcon && item.icon) {
              Icon = Icons[item.icon]
            }
            return (
              <MenuItem
                key={item.id}
                onClick={handleMenuClick(item.id, item.description)}
              >
                {Icon && (
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                )}
                <ListItemText>{item.description}</ListItemText>
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    </Tooltip>
  )
}

export default SelectButton
