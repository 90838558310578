import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ContactsOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 14H4V6h16v12M4 0h16v2H4m0 20h16v2H4m8-12a2.5 2.5 0 000-5 2.5 2.5 0 000 5m0-3.5c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1m5 7.5c0-2.1-3.31-3-5-3s-5 .9-5 3v1h10v-1m-8.19-.5c.61-.5 2.03-1 3.19-1 1.17 0 2.59.5 3.2 1H8.81z" />
  </SvgIcon>
)

export default React.memo(ContactsOutline)
