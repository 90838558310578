import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Timelapse = (props) => (
  <SvgIcon {...props}>
    <path d="M12 20a8 8 0 01-8-8 8 8 0 018-8 8 8 0 018 8 8 8 0 01-8 8m0-18A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2m4.24 5.76A5.95 5.95 0 0012 6v6l-4.24 4.24a6 6 0 008.48 0 5.981 5.981 0 000-8.48z" />
  </SvgIcon>
)

export default React.memo(Timelapse)
