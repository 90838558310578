import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const SortNumericDescending = (props) => (
  <SvgIcon {...props}>
    <path d="M19 7h3l-4-4-4 4h3v14h2M9 21H5v-2h4v-1H7a2 2 0 01-2-2v-1c0-1.1.9-2 2-2h2a2 2 0 012 2v4c0 1.11-.89 2-2 2m0-6H7v1h2M7 3h2a2 2 0 012 2v4c0 1.11-.89 2-2 2H7a2 2 0 01-2-2V5c0-1.1.9-2 2-2m0 6h2V5H7z" />
  </SvgIcon>
)

export default React.memo(SortNumericDescending)
