import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagItaly = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <path fill="#FFF" d="M341.334 85.33H0v341.332h512V85.33z" />
    <path fill="#6DA544" d="M0 85.333h170.663V426.67H0z" />
    <path fill="#D80027" d="M341.337 85.333H512V426.67H341.337z" />
  </SvgIcon>
)

export default React.memo(FlagItaly)
