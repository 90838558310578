import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const infoReducer = createReducer(initialState.deletion.info, {
  [types.DELETION_SET_NUMBER]: (state, action) => ({
    ...state,
    number: action.number,
  }),
})

export default combineReducers({
  info: infoReducer,
})
