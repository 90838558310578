import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Nixz = (props) => (
  <SvgIcon viewBox="0 0 256 271" fill="none" {...props}>
    <path
      fill="#01A19A"
      d="M188.479 41.164v190.361h-34.763L70.897 111.713h-1.394v119.812H29.256V41.165h35.32l82.168 119.718h1.673V41.164h40.062ZM245.62 211.646c0 10.979-8.9 19.879-19.879 19.879-10.98 0-19.88-8.9-19.88-19.879 0-10.98 8.9-19.88 19.88-19.88 10.979 0 19.879 8.9 19.879 19.88Z"
    />
  </SvgIcon>
)

export default React.memo(Nixz)
