import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Airplane = (props) => (
  <SvgIcon {...props}>
    <path d="M21,16V14L13,9V3.5A1.5,1.5 0 0,0 11.5,2A1.5,1.5 0 0,0 10,3.5V9L2,14V16L10,13.5V19L8,20.5V22L11.5,21L15,22V20.5L13,19V13.5L21,16Z" />
  </SvgIcon>
)

export default React.memo(Airplane)
