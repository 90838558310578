import * as types from './types'
import { createAction } from 'Utils/redux'

export const getByLine = createAction(
  types.ADDRESS_GET_BY_LINE,
  'address',
  'form',
  'prefix'
)

export const getByZip = createAction(
  types.ADDRESS_GET_BY_ZIP,
  'zipcode',
  'number',
  'country',
  'form',
  'prefix'
)
