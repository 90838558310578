import React, { Component } from 'react'
import isEmail from 'validator/es/lib/isEmail'
import { connect } from 'react-redux'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import compose from 'recompose/compose'
import clsx from 'clsx'
import { reduxForm, Field, Form, formValueSelector } from 'redux-form'
import queryString from 'query-string'
import GridContainer from 'Components/common/GridContainer'
import GridItem from 'Components/common/GridItem'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import LinkedInIcon from 'Icons/LinkedIn'
import GoogleIcon from 'Icons/Google'
import KeyIcon from 'Icons/Key'
import RefreshIcon from '@material-ui/icons/Refresh'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Base from 'Components/shell/PublicBase'
import Tooltip from 'Components/common/Tooltip'
import TextField from 'Components/common/form/TextField'
import {
  sendRecoveryCode,
  setAuthenticated,
  setEmail,
  signin,
  signoutSucceed,
  ssoSignin,
} from 'Actions/auth'
import withTranslate from 'Root/app/withTranslate'
import storage from 'Utils/storage'
import { gotoUrl } from 'Actions/app'

const styles = (theme) => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  codeLink: {
    marginTop: 26,
  },
  divider: {
    marginBottom: 10,
    marginTop: 10,
  },
  hidden: {
    display: 'none',
  },
  marginBottom: {
    marginBottom: 30,
  },
  rightButton: {
    marginLeft: theme.spacing(0),
  },
  ssoLabel: {
    marginLeft: 20,
    marginRight: 20,
  },
  ssoButton: {
    '&:hover': {
      filter: 'grayscale(0%)',
    },
    filter: 'grayscale(100%)',
    transition: 'filter 0.3s ease-in-out',
  },
  ssoButtonLinkedIn: {
    fill: '#007EBB',
  },
  ssoButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ssoLine: {
    alignItems: 'center',
    display: 'flex',
    '&:before, &:after': {
      content: '""',
      height: 1,
      backgroundColor: theme.palette.divider,
      flex: 'auto',
    },
    width: '100%',
  },
  ssoWrapper: {
    paddingTop: 20,
  },
  submit: {
    marginTop: 20,
  },
  twoFactorEmail: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

const validate = ({ email, password }, { t }) => {
  const errors = {}
  if (!email) {
    errors.email = t('auth.signin.error.email.required')
  }
  if (!password) {
    errors.password = t('auth.signin.error.password.required')
  }
  if (email && !isEmail(email)) {
    errors.email = t('auth.signin.error.email.invalid')
  }
  return errors
}

export class Signin extends Component {
  state = {
    emailCode: false,
    companyId: this.props.match.params.companyId,
    twoFactor: false,
    wait: false,
  }

  componentDidMount() {
    this.props.setAuthenticated(false)
    this.props.signoutSucceed()
    this._ismounted = true
  }

  componentWillUnmount() {
    this._ismounted = false
  }

  signin = (values) =>
    new Promise((resolve, reject) => {
      this.props.signin({
        values,
        resolve,
        reject,
      })
    })

  handleEmailBlur = (e) => {
    const email = e.target.value
    storage.set('email', email)
    this.props.setEmail(email)
  }

  handleSubmit = async (values) => {
    this.setState({ wait: true })
    const redirect = queryString.parse(this.props.location.search).redirect
    const data = { ...values, companyId: this.state.companyId, redirect }
    try {
      const twoFactor = await this.signin(data)
      if (twoFactor) {
        this.setState({ twoFactor: true })
      }
    } catch (e) {}
    if (this._ismounted) {
      this.setState({ wait: false })
    }
  }

  handleInputCode = (e) => {
    e.target.value = e.target.value.toString().slice(0, 12)
  }

  handleGotoLoginClick = () => {
    this.props.change('password', '')
    this.setState({ twoFactor: false })
  }

  handleSsoClick = (provider) => () => {
    const redirect = queryString.parse(this.props.location.search).redirect
    if (provider) {
      this.props.ssoSignin({ provider, redirect })
    } else {
      const url = '/sso' + (redirect ? `?redirect=${redirect}` : '')
      this.props.gotoUrl({
        url,
      })
    }
  }
  handleRecoveryClick = () => {
    const { email, password, sendRecoveryCode } = this.props
    this.setState({ emailCode: true })
    sendRecoveryCode({ email, password })
  }

  render() {
    const {
      classes,
      email,
      hasEmail,
      handleSubmit,
      invalid,
      pristine,
      t,
      token,
      submitting,
    } = this.props
    const { twoFactor } = this.state
    return (
      <Base title={t('auth.signin.title')}>
        <Form
          onSubmit={handleSubmit(this.handleSubmit)}
          autoComplete="off"
          noValidate
        >
          <GridContainer>
            <GridItem
              errorHeight
              className={clsx(twoFactor && classes.hidden)}
              xs={12}
            >
              <Field
                name="email"
                required
                lower
                type="email"
                component={TextField}
                placeholder={t('auth.signin.email.placeholder')}
                label={t('auth.signin.email.label')}
                autoFocus={!hasEmail}
                onBlur={this.handleEmailBlur}
                ignoreLp={false}
              />
            </GridItem>
            <GridItem
              errorHeight
              className={clsx(twoFactor && classes.hidden)}
              xs={12}
            >
              <Field
                password
                required
                name="password"
                component={TextField}
                placeholder={t('auth.signin.password.placeholder')}
                label={t('auth.signin.password.label')}
                autoFocus={hasEmail}
                ignoreLp={false}
              />
            </GridItem>
            <GridItem className={clsx(!twoFactor && classes.hidden)} xs={12}>
              <Typography variant="h6">
                <FormattedMessage id="auth.signin.twoFactor.title" />
              </Typography>
              <Typography>
                <FormattedMessage id="auth.signin.twoFactor.subtitle1" />
              </Typography>
              <div className={classes.twoFactorEmail}>
                <Typography noWrap>{email}</Typography>
                <Tooltip title={t('auth.signin.twoFactor.email.tooltip')}>
                  <IconButton onClick={this.handleGotoLoginClick}>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </GridItem>
            <GridItem className={clsx(!twoFactor && classes.hidden)} xs={12}>
              <Typography>
                <FormattedHTMLMessage id="auth.signin.twoFactor.subtitle2" />
              </Typography>
            </GridItem>
            {twoFactor && (
              <GridItem
                errorHeight
                className={clsx(!twoFactor && classes.hidden)}
                xs={12}
              >
                <Field
                  type="number"
                  onInput={this.handleInputCode}
                  name="token"
                  component={TextField}
                  label={t('auth.signin.code.label')}
                  autoFocus
                  fullWidth={false}
                  upper
                  inputProps={{ maxLength: 12 }}
                />
              </GridItem>
            )}
            <GridItem xs={12} className={!twoFactor && classes.marginBottom}>
              <GridContainer>
                <GridItem xs={12} className={classes.center}>
                  <Button
                    variant="contained"
                    className={classes.submit}
                    type="submit"
                    color="primary"
                    disabled={pristine || invalid || submitting}
                  >
                    <FormattedMessage id="auth.signin.button.submit" />
                    {this.state.wait && (
                      <RefreshIcon
                        className={clsx('wn-spin', classes.rightButton)}
                      />
                    )}
                  </Button>
                </GridItem>
                {twoFactor && (
                  <GridItem xs={12} className={classes.center}>
                    <Typography
                      variant="subtitle1"
                      align="center"
                      className={classes.codeLink}
                    >
                      {!token && !this.state.emailCode ? (
                        <a onClick={this.handleRecoveryClick}>
                          <FormattedMessage id="auth.signin.button.recovery" />
                        </a>
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </Typography>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
            <GridItem className={clsx(twoFactor && classes.hidden)} xs={12}>
              <GridContainer>
                <GridItem xs={12}>
                  <Typography variant="subtitle1" align="center">
                    <Link to={'/password-forgotten'}>
                      <FormattedMessage id="auth.signin.button.passwordForgotten" />
                    </Link>
                  </Typography>
                </GridItem>
                {/* <Typography
                  variant="subtitle1"
                  className={clsx(twoFactor && classes.hidden)}
                >
                  <FormattedMessage id="auth.signin.button.register.info" />
                  &nbsp;
                  <Link to={'/signup'}>
                    <FormattedMessage id="auth.signin.button.register.text" />
                  </Link>
                </Typography> */}
                <GridItem xs={12}>
                  <div className={classes.ssoWrapper}>
                    <div className={classes.ssoLine}>
                      <Typography
                        variant="subtitle1"
                        component="span"
                        className={classes.ssoLabel}
                      >
                        {t('auth.signin.or.label')}
                      </Typography>
                    </div>
                    <div className={classes.ssoButtons}>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.ssoButton}
                        startIcon={<GoogleIcon color="action" />}
                        onClick={this.handleSsoClick('google')}
                      >
                        Google
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.ssoButton}
                        startIcon={
                          <LinkedInIcon className={classes.ssoButtonLinkedIn} />
                        }
                        onClick={this.handleSsoClick('linkedIn')}
                      >
                        LinkedIn
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.ssoButton}
                        startIcon={<KeyIcon color="secondary" />}
                        onClick={this.handleSsoClick('')}
                      >
                        Sso
                      </Button>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Form>
      </Base>
    )
  }
}

const selector = formValueSelector('signin')

export default compose(
  withTranslate,
  connect(
    (state) => ({
      email: selector(state, 'email'),
      hasEmail: !!state.auth.user.email,
      initialValues: {
        email: state.auth.user.email,
      },
      password: selector(state, 'password'),
      token: selector(state, 'token'),
    }),
    {
      gotoUrl,
      sendRecoveryCode,
      setAuthenticated,
      setEmail,
      signin,
      signoutSucceed,
      ssoSignin,
    }
  ),
  withStyles(styles),
  reduxForm({
    enableReinitialize: false,
    form: 'signin',
    shouldError: () => true,
    validate,
  })
)(Signin)
