import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagIndonesia = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <path fill="#FFF" d="M0 85.337h512v341.326H0z" />
    <path fill="#A2001D" d="M0 85.337h512V256H0z" />
  </SvgIcon>
)

export default React.memo(FlagIndonesia)
