import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const EmoticonSadOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M20 12a8 8 0 00-8-8 8 8 0 00-8 8 8 8 0 008 8 8 8 0 008-8m2 0a10 10 0 01-10 10A10 10 0 012 12 10 10 0 0112 2a10 10 0 0110 10m-6.5-4c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.7-1.5 1.5-1.5M10 9.5c0 .8-.7 1.5-1.5 1.5S7 10.3 7 9.5 7.7 8 8.5 8s1.5.7 1.5 1.5m2 4.5c1.75 0 3.29.72 4.19 1.81l-1.42 1.42C14.32 16.5 13.25 16 12 16s-2.32.5-2.77 1.23l-1.42-1.42C8.71 14.72 10.25 14 12 14z" />
  </SvgIcon>
)

export default React.memo(EmoticonSadOutline)
