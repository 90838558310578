import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagPhilippines = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <path fill="#FFF" d="M0 85.337h512v341.326H0z" />
    <path fill="#0052B4" d="M512 85.337V256H256L0 85.337z" />
    <path fill="#D80027" d="M512 256v170.663H0L256 256z" />
    <path
      d="M161.908 256l-27.288-12.835 14.532-26.428-29.632 5.668-3.755-29.933-20.64 22.015-20.639-22.015-3.755 29.933-29.631-5.669 14.531 26.428L28.343 256l27.288 12.835L41.1 295.263l29.633-5.668 3.753 29.933 20.639-22.015 20.64 22.015 3.755-29.933 29.631 5.669-14.532-26.427zM21.789 117.607l9.081 12.696 14.879-4.714-9.268 12.56 9.081 12.694-14.809-4.932-9.268 12.559.117-15.608-14.809-4.934 14.88-4.712zm0 235.923l9.081 12.696 14.879-4.714-9.268 12.56 9.081 12.695-14.809-4.933-9.268 12.558.117-15.607-14.809-4.934 14.88-4.712zm188.606-117.961l-9.081 12.695-14.879-4.713 9.268 12.559-9.081 12.696 14.809-4.933 9.268 12.558-.117-15.607 14.809-4.934-14.88-4.713z"
      fill="#FFDA44"
    />
  </SvgIcon>
)

export default React.memo(FlagPhilippines)
