import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Brockmeyer = (props) => (
  <SvgIcon viewBox="0 0 512 512" fill="none" {...props}>
    <path d="M310 150.5V283h53v-20l6.3 6.3c6.9 7.1 16.7 12.5 27.7 15.4 9.4 2.5 28.9 2.2 39.5-.6 43.1-11.4 68.5-52.2 64.4-103.7-3.5-43.7-28.1-74.3-65.9-82-10.2-2-31.2-1.5-40.5 1.1-9.4 2.6-19.7 8-25.3 13.3l-5.2 4.8V18h-54v132.5zm112.2-.3c18.5 8.6 27.4 30.1 22.4 53.9-4 18.8-17.7 31.2-35.8 32.6-29.3 2.2-49.3-21.6-44.8-53.2 2.5-17.6 11.9-29.7 26.9-34.7 8.6-2.8 23.7-2.1 31.3 1.4z" />
    <path
      fill="#437be4"
      d="M24 398.9c-8.5 2.6-16.4 9.3-20.2 17-2.2 4.3-2.3 6-2.6 28.9-.3
    23.8-.3 24.4 2.1 29.5 4.5 9.9 11.1 15.5 21.2 18.1 4.9 1.3 27.8.7 226.7-6.4
    121.7-4.3 224.5-8.1 228.3-8.4 3.9-.3 8.7-1 10.7-1.7 5.8-1.9 12.8-7.9
    16.2-13.8 2.5-4.7 3.1-6.8 3.4-14.2.6-12.1-1.3-17.6-8.7-24.9-9.7-9.6
    12.3-8.1-238.1-17-121.8-4.4-224.9-7.9-229-7.9-4.1 0-8.6.4-10 .8z"
    />
  </SvgIcon>
)

export default React.memo(Brockmeyer)
