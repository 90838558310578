import * as types from './types'
import { createAction } from 'Utils/redux'

export const addSources = createAction(types.PROJECT_ADD_SOURCES, 'data')
export const addTimeline = createAction(types.PROJECT_ADD_TIMELINE, 'payload')
export const checkTimeline = createAction(
  types.PROJECT_CHECK_TIMELINE,
  'payload'
)
export const deleteTimeline = createAction(
  types.PROJECT_DELETE_TIMELINE,
  'projectId',
  'id'
)
export const editTimeline = createAction(types.PROJECT_EDIT_TIMELINE, 'payload')
export const getCandidates = createAction(
  types.PROJECT_GET_CANDIDATES,
  'id',
  'jobId',
  'edit'
)
export const getCandidatesSucceed = createAction(
  types.PROJECT_GET_CANDIDATES_SUCCEED,
  'data',
  'edit'
)
export const getPlanning = createAction(types.PROJECT_GET_PLANNING, 'id')
export const getPlanningSucceed = createAction(
  types.PROJECT_GET_PLANNING_SUCCEED,
  'data'
)
export const getTimeline = createAction(
  types.PROJECT_GET_TIMELINE,
  'id',
  'callback'
)
export const getTimelines = createAction(
  types.PROJECT_GET_TIMELINES,
  'deployed',
  'start',
  'end'
)
export const getTimelinesSucceed = createAction(
  types.PROJECT_GET_TIMELINES_SUCCEED,
  'data'
)
export const gotoProject = createAction(
  types.PROJECT_GOTO_PROJECT,
  'id',
  'newTab'
)
export const jobMoveDown = createAction(
  types.PROJECT_JOB_MOVE_DOWN,
  'projectId',
  'jobId'
)
export const jobMoveUp = createAction(
  types.PROJECT_JOB_MOVE_UP,
  'projectId',
  'jobId'
)
export const loadSources = createAction(types.PROJECT_LOAD_SOURCES)

export const removeJob = createAction(types.PROJECT_REMOVE_JOB, 'id', 'jobId')
export const resetTimelineInterval = createAction(
  types.PROJECT_RESET_TIMELINE_INTERVAL,
  'start',
  'end'
)
export const setInterval = createAction(
  types.PROJECT_SET_INTERVAL,
  'start',
  'end'
)
export const setLoading = createAction(types.PROJECT_SET_LOADING, 'value')
export const setScrollPosition = createAction(
  types.PROJECT_SET_SCROLL_POSITION,
  'top',
  'left'
)
export const setSourcesDirty = createAction(
  types.PROJECT_SET_SOURCES_DIRTY,
  'value'
)
export const setTimelineInterval = createAction(
  types.PROJECT_SET_TIMELINE_INTERVAL,
  'start',
  'end'
)
