import { isWidthDown, isWidthUp } from '@material-ui/core/withWidth'
import { useTheme } from '@material-ui/core/styles'
import withWidth from './withWidth'

const Hidden = (props) => {
  const { children, width } = props
  const theme = useTheme()

  let visible = true
  try {
    for (let i = 0; i < theme.breakpoints.keys.length; i += 1) {
      const breakpoint = theme.breakpoints.keys[i]
      const breakpointUp = props[`${breakpoint}Up`]
      const breakpointDown = props[`${breakpoint}Down`]
      if (
        (breakpointUp && isWidthUp(breakpoint, width)) ||
        (breakpointDown && isWidthDown(breakpoint, width))
      ) {
        visible = false
        break
      }
    }
  } catch (e) {
    return null
  }
  if (!visible) {
    return null
  }

  return children || null
}

export default withWidth(Hidden)
