import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const CheckboxCircleChecked = (props) => (
  <SvgIcon {...props}>
    <path d="M12 5c3.87 0 7 3.13 7 7s-3.13 7-7 7-7-3.13-7-7 3.13-7 7-7m0-3c5.5 0 10 4.5 10 10s-4.5 10-10 10S2 17.5 2 12 6.5 2 12 2m0 2c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z" />
  </SvgIcon>
)

export default React.memo(CheckboxCircleChecked)
