import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagKorea = (props) => (
  <SvgIcon viewBox="0 0 900 600" {...props}>
    <path fill="#FFF" d="M0 0h900v600H0z" />
    <g transform="rotate(-56.31)">
      <path
        d="M-75 228.3H75m-150 37.5H75m-150 37.5H75m-150 475H75m-150 37.5H75m-150 37.5H75"
        stroke="#000"
        strokeWidth={25}
      />
      <path stroke="#FFF" strokeWidth={12.5} d="M0 753.3v125" />
      <circle fill="#ca163a" cy={540.8} r={150} />
      <path
        fill="#0e4896"
        d="M0 390.8c-41.4 0-75 33.6-75 75s33.6 75 75 75 75 33.6 75 75-33.6 75-75 75c-82.8 0-150-67.2-150-150s67.2-150 150-150z"
      />
    </g>
    <path
      d="M231.56 535.73l-83.205-124.808M262.76 514.928l-83.205-124.807m114.407 104.006l-83.205-124.808m478.43-138.675l-83.205-124.807M720.39 209.843L637.184 85.036m114.407 104.006L668.386 64.234"
      stroke="#000"
      strokeWidth={25}
    />
    <path
      stroke="#FFF"
      strokeWidth={12.5}
      d="M205.6 462.897l31.202-20.8m389.981-259.989l36.444-24.296m31.202-20.801l31.202-20.801"
    />
  </SvgIcon>
)

export default React.memo(FlagKorea)
