import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Pulse = (props) => (
  <SvgIcon {...props}>
    <path d="M3 13h2.79l4.31-8.21 1.18 8.96 3.22-4.09L17.83 13H21v2h-4l-2.33-2.33-4.75 6.06-.98-7.42L7 15H3v-2z" />
  </SvgIcon>
)

export default React.memo(Pulse)
