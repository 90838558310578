import * as types from 'Actions/types'
import { combineReducers } from 'redux'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const itemReducer = createReducer(initialState.campaign.item, {
  [types.CAMPAIGN_SET_FAVORITE]: (state, { id }) => {
    return {
      ...state,
      channels: {
        ...state.channels,
        list: state.channels.list.map((channel) => ({
          ...channel,
          favorite: id === channel.id ? true : channel.favorite || false,
        })),
      },
    }
  },
  [types.CAMPAIGN_SET_FILTER]: (state, { filter }) => {
    return {
      ...state,
      filter,
    }
  },
  [types.CAMPAIGN_SET_LOADING]: (state, { value }) => {
    return {
      ...state,
      loading: value,
    }
  },
  [types.CAMPAIGN_SET_ORDER]: (state, { order }) => {
    return {
      ...state,
      order,
    }
  },
  [types.CAMPAIGN_SET_SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    }
  },
  [types.CAMPAIGN_SET_SKIP]: (state, { skip }) => {
    return {
      ...state,
      skip,
    }
  },
  [types.CAMPAIGN_SET_TAB]: (state, { tab }) => {
    const skip = state.tab === tab ? state.skip : 0
    const list = state.tab === tab ? state.channels.list : null
    return {
      ...state,
      channels: {
        ...state.channels,
        list,
      },
      tab,
      skip,
    }
  },
  [types.CAMPAIGN_TOGGLE_CART]: (state) => {
    return {
      ...state,
      cart: !state.cart,
    }
  },
  [types.CAMPAIGN_SHOW_FILTER]: (state, { value }) => {
    return {
      ...state,
      allFilter: value,
    }
  },
  [types.CAMPAIGN_GET_CHANNELS_SUCCEED]: (state, { data }) => {
    return {
      ...state,
      channels: data,
    }
  },
  [types.CAMPAIGN_UNSET_FAVORITE]: (state, { id }) => {
    return {
      ...state,
      channels: {
        ...state.channels,
        list: state.channels.list.map((channel) => ({
          ...channel,
          favorite: id === channel.id ? false : channel.favorite || false,
        })),
      },
    }
  },
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.campaign.item,
})

const listsReducer = createReducer(initialState.campaign.lists, {
  [types.CAMPAIGN_LOAD_LISTS_SUCCEED]: (state, { data }) => {
    return data
  },
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.campaign.lists,
})

export default combineReducers({
  item: itemReducer,
  lists: listsReducer,
})
