import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const activityReducer = createReducer(initialState.activity, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.activity,
  [types.ACTIVITY_GET_ACTIVITIES_SUCCEED]: (state, { data }) => {
    const { list, hasMore, page } = data
    return {
      ...state,
      list,
      info: {
        ...state.info,
        hasMore,
        page,
      },
    }
  },
  [types.ACTIVITY_SET_FILTER]: (state, { filter }) => ({
    ...state,
    filter,
  }),
  [types.ACTIVITY_SET_ACTIVE_USER]: (state, { user }) => ({
    ...state,
    activeUser: user,
  }),
})

export default activityReducer
