import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Netive = (props) => {
  return (
    <SvgIcon viewBox="0 0 590.7 590.7" {...props}>
      <path
        d="m270.3 145.7 100.8 206.4 39.7-222.3zM344.7 441.6l-104.2-214L205 425.8zM421.6 450.2l.5.1c17 1.9 32-11.4 32-28.6V267.5l-32.5 182.7zM185.3 155.3l-23.2 2.6c-14.5 1.6-25.5 13.9-25.5 28.6v206c0 5.1 1.3 9.9 3.7 14l45-251.2z"
        //fill={'#CF0000'}
      />
      <rect
        width={442.233}
        height={458.717}
        x={74.469}
        y={63.05}
        rx={55.543}
        ry={55.543}
        style={{
          fill: 'none',
          paintOrder: 'stroke',
          stroke: '#bdc3c7',
          strokeWidth: 30,
        }}
      />
    </SvgIcon>
  )
}

export default React.memo(Netive)
