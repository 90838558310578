import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GenderMale = (props) => (
  <SvgIcon {...props}>
    <path d="M9,9C10.29,9 11.5,9.41 12.47,10.11L17.58,5H13V3H21V11H19V6.41L13.89,11.5C14.59,12.5 15,13.7 15,15A6,6 0 0,1 9,21A6,6 0 0,1 3,15A6,6 0 0,1 9,9M9,11A4,4 0 0,0 5,15A4,4 0 0,0 9,19A4,4 0 0,0 13,15A4,4 0 0,0 9,11Z" />
  </SvgIcon>
)

export default React.memo(GenderMale)
