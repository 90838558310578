import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const activitiesReducer = createReducer(initialState.entity.activities, {
  [types.ENTITY_RESET_DATA]: () => initialState.entity.activities,
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.entity.activities,
  [types.ENTITY_SET_ID]: (state) => ({
    ...state,
    filter: '',
  }),
  [types.ENTITY_GET_ACTIVITIES_SUCCEED]: (state, { data }) => {
    const { list, hasMore, page } = data
    return {
      ...state,
      list,
      info: {
        ...state.info,
        hasMore,
        page,
      },
    }
  },
  [types.ENTITY_SET_ACTIVITIES_FILTER]: (state, { filter }) => ({
    ...state,
    filter,
  }),
  [types.ENTITY_ACTIVITY_SET_LOADING]: (state, { value }) => ({
    ...state,
    info: {
      ...state.info,
      loading: value,
    },
  }),
})

const attachmentsReducer = createReducer(initialState.entity.attachments, {
  [types.ENTITY_RESET_DATA]: () => initialState.entity.attachments,
  [types.ENTITY_GET_ATTACHMENTS]: (_, action) => action.data,
})

const certificatesReducer = createReducer(initialState.entity.certificates, {
  [types.ENTITY_DESELECT_ITEMS]: (state) =>
    state.map((certificate) => ({ ...certificate, selected: false })),
  [types.ENTITY_GET_CERTIFICATES_SUCCEED]: (_, action) => action.data,
  [types.ENTITY_GET_CERTIFICATE_URL_SUCCEED]: (state, { id, data }) => {
    const { url } = data
    return state.map((certificate) => ({
      ...certificate,
      url: certificate.id === id ? url : certificate.url,
    }))
  },
  [types.ENTITY_SELECT_CERTIFICATE]: (state, { id }) => {
    return state.map((certificate) => ({
      ...certificate,
      selected:
        certificate.id === id ? !certificate.selected : !!certificate.selected,
    }))
  },
})

const dataReducer = createReducer(initialState.entity.data, {
  [types.ENTITY_RESET_DATA]: () => initialState.entity.data,
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.entity.data,

  [types.CANDIDATE_DELETE_ALTERNATIVE_SUCCEED]: (state) => ({
    ...state,
    alternative: undefined,
  }),
  [types.CANDIDATE_DELETE_COVER_SUCCEED]: (state) => ({
    ...state,
    cover: undefined,
    merge: undefined,
  }),

  [types.CANDIDATE_DELETE_PHOTO_SUCCEED]: (state) => ({
    ...state,
    photoUrl: undefined,
  }),
  [types.CANDIDATE_DELETE_RESUME_SUCCEED]: (state) => ({
    ...state,
    resume: undefined,
    merge: undefined,
  }),

  [types.CANDIDATE_UPDATE_RATING]: (state, action) => ({
    ...state,
    rating: action.value,
  }),
  [types.CANDIDATE_SET_UPLOADING]: (state, { doc, value = true }) => ({
    ...state,
    [`${doc}Converting`]: value,
  }),
  [types.CANDIDATE_UPLOAD_PHOTO_SUCCEED]: (state, action) => ({
    ...state,
    photoUrl: action.url,
  }),
  [types.CANDIDATE_TALENTPOOL_ADD]: (state) => ({
    ...state,
    talentpool: true,
  }),
  [types.CANDIDATE_TALENTPOOL_REMOVE]: (state) => ({
    ...state,
    talentpool: false,
  }),
  [types.EMAIL_SET_JOB_NAME]: (state, { jobName }) => ({
    ...state,
    jobName,
  }),
  [types.ENTITY_ADD_TAG]: (state, action) => ({
    ...state,
    tags: [...(state.tags || []), action.tag],
  }),
  [types.ENTITY_MARK]: (state) => ({ ...state, marked: true }),
  [types.ENTITY_REMOVE_TAG]: (state, action) => ({
    ...state,
    tags: [...(state.tags || []).filter((tag) => tag !== action.tag)],
  }),
  [types.ENTITY_SET_DATA]: (state, action) => action.data || state,
  [types.ENTITY_SET_HASNOTES]: (state, { value }) => ({
    ...state,
    hasNotes: value,
  }),
  [types.ENTITY_SET_ID]: (state, action) => ({ ...state, id: action.id }),
  [types.ENTITY_SET_DATA_ITEM]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [types.ENTITY_SET_PARTIAL_DATA]: (state, { id, data }) => {
    if (id === state.id) {
      return {
        ...state,
        ...data,
      }
    }
    return state
  },
  [types.ENTITY_UPDATE_DATA]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [types.ENTITY_UNMARK]: (state) => ({ ...state, marked: false }),
  [types.JOB_DELETE_ALTERNATIVE_SUCCEED]: (state) => ({
    ...state,
    alternative: undefined,
  }),
  [types.JOB_DELETE_VACANCY_SUCCEED]: (state) => ({
    ...state,
    vacancy: undefined,
  }),
  [types.JOB_SET_UPLOADING]: (state, { doc, value = true }) => ({
    ...state,
    [`${doc}Converting`]: value,
  }),
  [types.JOB_UPDATE_PUBLISH]: (state, { values }) => ({
    ...state,
    ...values,
  }),
  [types.PDF_GENERATE_SUCCEED]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [types.RELATION_DELETE_PHOTO_SUCCEED]: (state) => ({
    ...state,
    photoUrl: undefined,
  }),
  [types.RELATION_UPDATE_CLAIMED]: (state, { values }) => ({
    ...state,
    ...values,
  }),
  [types.RELATION_UPLOAD_PHOTO_SUCCEED]: (state, action) => ({
    ...state,
    photoUrl: action.url,
  }),
})

const emailsReducer = createReducer(initialState.entity.emails, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.entity.emails,
  [types.ENTITY_GET_EMAILS_SUCCEED]: (state, action) => ({
    ...state,
    list: action.data,
    loaded: true,
  }),
  [types.ENTITY_SET_EMAILS_FILTER]: (state, { filter }) => ({
    ...state,
    filter,
  }),
  [types.ENTITY_SET_EMAILS_FILTER_CANDIDATE]: (state, { filter }) => ({
    ...state,
    filterCandidate: filter,
  }),
  [types.ENTITY_SET_EMAILS_LOADED]: (state, { value }) => ({
    ...state,
    loaded: value,
  }),
})

const eventsReducer = createReducer(initialState.entity.events, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.entity.events,
  [types.ENTITY_RESET_DATA]: () => initialState.entity.events,
  [types.ENTITY_GET_EVENTS_SUCCEED]: (state, { data, limit }) => ({
    ...state,
    list: data,
    limit,
  }),
})

const infoReducer = createReducer(initialState.entity.info, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.entity.info,
  [types.AUTH_CHANGE_COMPANY_SUCCEED]: (state) => ({
    ...state,
    id: initialState.entity.info.id,
  }),
  [types.ENTITY_SET_CONTENTOVERFLOWX]: (state, action) => ({
    ...state,
    contentOverflowX: action.value,
  }),
  [types.ENTITY_SET_CONTENTOVERFLOWY]: (state, action) => ({
    ...state,
    contentOverflowY: action.value,
  }),
  [types.ENTITY_SET_EDITFORM]: (state, action) => ({
    ...state,
    editForm: action.editForm,
  }),
  [types.ENTITY_SET_EDITMODE]: (state, action) => ({
    ...state,
    editMode: action.editMode,
  }),
  [types.ENTITY_SET_FILES_LOADING]: (state, { loading }) => ({
    ...state,
    filesLoading: loading,
  }),
  [types.ENTITY_SET_ID]: (state, action) => ({ ...state, id: action.id }),
  [types.ENTITY_SET_LOADING]: (state, { loading }) => {
    let number = state.loadingNumber
    if (loading) {
      number++
    } else if (number > 0) {
      number--
    }
    return {
      ...state,
      loading: number > 0,
      loadingNumber: number,
    }
  },
  [types.ENTITY_SET_NOT_FOUND]: (state, action) => ({
    ...state,
    notFound: action.value,
  }),
  [types.ENTITY_SET_PREVLOCATION]: (state, action) => ({
    ...state,
    prevLocation: action.prevLocation,
  }),
  [types.ENTITY_SET_SAVING]: (state, { saving }) => ({
    ...state,
    saving,
  }),
  [types.ENTITY_SET_SELECTION]: (state, action) => ({
    ...state,
    selection: action.selection,
  }),
  [types.ENTITY_SET_TAB_CAPTION]: (state, action) => ({
    ...state,
    tabCaption: action.tabCaption,
  }),
  [types.ENTITY_SET_TAB_COUNT]: (state, action) => ({
    ...state,
    tabCount: action.tabCount,
  }),
  [types.ENTITY_SET_TYPE]: (state, action) => ({
    ...state,
    entityType: action.entityType,
  }),
  [types.ENTITY_SHOW_CONFETTI]: (state, action) => ({
    ...state,
    confetti: action.value,
  }),
})

const insightsReducer = createReducer(initialState.entity.insights, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.entity.insights,
  [types.ENTITY_RESET_DATA]: () => initialState.entity.insights,
  [types.ENTITY_SET_INSIGHTS]: (state, { data }) => ({
    ...state,
    ...data,
    error: data.status !== 'ok',
  }),
})

const lastNoteReducer = createReducer(initialState.entity.lastNote, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.entity.lastNote,
  [types.ENTITY_RESET_DATA]: () => initialState.entity.lastNote,
  [types.ENTITY_GET_LAST_NOTE]: (_, action) => action.data,
})

const notesReducer = createReducer(initialState.entity.notes, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.entity.notes,
  [types.ENTITY_RESET_DATA]: (state) => ({
    ...initialState.entity.notes,
    order: state.order,
  }),
  [types.ENTITY_NOTES_COLLAPSE_ALL]: (state) => ({
    ...state,
    list: state.list.map((item) => ({ ...item, expanded: false })),
  }),
  [types.ENTITY_NOTES_EXPAND_ALL]: (state) => ({
    ...state,
    list: state.list.map((item) => ({ ...item, expanded: true })),
  }),
  [types.ENTITY_EXPAND_NOTE]: (state, { noteId, value }) => ({
    ...state,
    list: state.list.map((item) => ({
      ...item,
      expanded: item.id === noteId ? value : item.expanded,
    })),
  }),
  [types.ENTITY_NOTES_SET_ORDER]: (state, { order }) => ({
    ...state,
    order,
  }),
  [types.ENTITY_SET_ID]: (state) => ({
    ...state,
    filter: '',
  }),
  [types.ENTITY_GET_NOTES]: (state, action) => {
    if (action.openFirst) {
      action.data[0].expanded = true
    }
    return { ...state, list: action.data }
  },
  [types.ENTITY_GET_NOTES_USERS_SUCCEED]: (state, { data }) => ({
    ...state,
    users: data,
  }),
  [types.ENTITY_SET_NOTE_SECURED_SUCCEED]: (state, action) => {
    const { noteId, value } = action
    const notes = [...state.list]
    const list = notes.map((note) => {
      if (noteId === note.id) {
        return { ...note, secured: value }
      } else {
        return note
      }
    })
    return { ...state, list }
  },
  [types.ENTITY_SET_NOTES_FILTER]: (state, { filter }) => ({
    ...state,
    filter,
  }),
  [types.ENTITY_SET_NOTES_FILTER_TYPE]: (state, { filter }) => ({
    ...state,
    filterType: filter,
  }),
  [types.ENTITY_UPLOAD_NOTE_ATTACHMENT_END]: (state, action) => {
    const uploadAttachment = (state.uploadAttachment || []).filter(
      (id) => id !== action.noteId
    )
    return { ...state, uploadAttachment }
  },
  [types.ENTITY_UPLOAD_NOTE_ATTACHMENT_START]: (state, action) => ({
    ...state,
    uploadAttachment: [...state.uploadAttachment, action.noteId],
  }),
  [types.ENTITY_UPLOAD_NOTE_ATTACHMENT_SUCCEED]: (state, action) => {
    const { noteId, data } = action
    const list = state.list.map((item) => {
      if (item.id === noteId) {
        return { ...item, ...data }
      }
      return item
    })
    return {
      ...state,
      list,
    }
  },
  [types.ENTITY_NOTES_SET_BACK_URL]: (state, { url }) => ({
    ...state,
    backUrl: url,
  }),
  [types.ENTITY_SET_NOTE_VIEWERS]: (state, { viewers }) => ({
    ...state,
    viewers,
  }),
})

const tasksReducer = createReducer(initialState.entity.tasks, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.entity.tasks,
  [types.ENTITY_RESET_DATA]: () => initialState.entity.tasks,
  [types.ENTITY_GET_TASKS_SUCCEED]: (state, { data }) => ({
    ...state,
    list: data,
  }),
})

const unsavedNoteReducer = createReducer(initialState.entity.unsavedNote, {
  [types.ENTITY_SET_UNSAVED_NOTE]: (state, { values }) => {
    const { ref, ...rest } = values
    if (typeof ref === 'undefined') {
      return { ...state, ...rest }
    }
    return { ...state, ...values }
  },
})

export default combineReducers({
  activities: activitiesReducer,
  attachments: attachmentsReducer,
  certificates: certificatesReducer,
  data: dataReducer,
  emails: emailsReducer,
  events: eventsReducer,
  info: infoReducer,
  insights: insightsReducer,
  lastNote: lastNoteReducer,
  notes: notesReducer,
  tasks: tasksReducer,
  unsavedNote: unsavedNoteReducer,
})
