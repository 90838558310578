import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const CalendarPlus = (props) => (
  <SvgIcon {...props}>
    <path d="M19 19V7H5v12h14M16 1h2v2h1a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.11.89-2 2-2h1V1h2v2h8V1m-5 8h2v3h3v2h-3v3h-2v-3H8v-2h3V9z" />
  </SvgIcon>
)

export default React.memo(CalendarPlus)
