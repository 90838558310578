import React from 'react'
import MuiSwitch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const SwitchField = ({ input, label, ...other }) => {
  const { value, ...otherInput } = input
  return (
    <FormControlLabel
      control={
        <MuiSwitch checked={value || false} {...otherInput} {...other} />
      }
      label={label}
    />
  )
}

export default SwitchField
