import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as modalReducer } from 'redux-modal'

import appReducer from './appReducer'
import applyReducer from './applyReducer'
import activityReducer from './activityReducer'
import authReducer from './authReducer'
import automationReducer from './automationReducer'
import cacheReducer from './cacheReducer'
import campaignReducer from './campaignReducer'
import candidateReducer from './candidateReducer'
import certificateReducer from './certificateReducer'
import dashboardReducer from './dashboardReducer'
import deletionReducer from './deletionReducer'
import entityReducer from './entityReducer'
import emailReducer from './emailReducer'
import eventReducer from './eventReducer'
import exportReducer from './exportReducer'
import funnelReducer from './funnelReducer'
import iframeReducer from './iframeReducer'
import importReducer from './importReducer'
import jobReducer from './jobReducer'
import lookupReducer from './lookupReducer'
import notificationReducer from './notificationReducer'
import pdfReducer from './pdfReducer'
import projectReducer from './projectReducer'
import quickReducer from './quickReducer'
import relationReducer from './relationReducer'
import searchReducer from './searchReducer'
import shareReducer from './shareReducer'
import shellReducer from './shellReducer'
import tableReducer from './tableReducer'
import taskReducer from './taskReducer'
import templateReducer from './templateReducer'
import themeReducer from './themeReducer'

const rootReducer = () =>
  combineReducers({
    activity: activityReducer,
    app: appReducer,
    apply: applyReducer,
    auth: authReducer,
    automation: automationReducer,
    cache: cacheReducer,
    campaign: campaignReducer,
    candidate: candidateReducer,
    certificate: certificateReducer,
    dashboard: dashboardReducer,
    deletion: deletionReducer,
    email: emailReducer,
    entity: entityReducer,
    event: eventReducer,
    export: exportReducer,
    form: formReducer,
    funnel: funnelReducer,
    iframe: iframeReducer,
    import: importReducer,
    job: jobReducer,
    lookup: lookupReducer,
    modal: modalReducer,
    notification: notificationReducer,
    pdf: pdfReducer,
    project: projectReducer,
    quick: quickReducer,
    relation: relationReducer,
    search: searchReducer,
    share: shareReducer,
    shell: shellReducer,
    table: tableReducer,
    task: taskReducer,
    template: templateReducer,
    theme: themeReducer,
  })

export default rootReducer
