import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const pdfReducer = createReducer(initialState.pdf, {
  [types.PDF_CREATING]: (state, { value }) => ({
    ...state,
    creating: value,
  }),
})

export default pdfReducer
