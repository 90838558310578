import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

// https://github.com/lipis/flag-icon-css/tree/master/flags/4x3

const FlagBelgium = (props) => (
  <SvgIcon viewBox="0 0 640 480" {...props}>
    <g fillRule="evenodd" strokeWidth="1pt">
      <path fill="black" d="M0 0h213.335v479.997H0z" />
      <path fill="#ffd90c" d="M213.335 0H426.67v479.997H213.335z" />
      <path fill="#f31830" d="M426.67 0h213.335v479.997H426.67z" />
    </g>
  </SvgIcon>
)

export default React.memo(FlagBelgium)
