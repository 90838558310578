import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagRomania = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <path fill="#FFDA44" d="M0 85.331h512v341.326H0z" />
    <path fill="#0052B4" d="M0 85.331h170.663v341.337H0z" />
    <path fill="#D80027" d="M341.337 85.331H512v341.337H341.337z" />
  </SvgIcon>
)

export default React.memo(FlagRomania)
