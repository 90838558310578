import React, { Component } from 'react'
import isEmail from 'validator/es/lib/isEmail'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { reduxForm, Field, Form, formValueSelector } from 'redux-form'
import {
  withGoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3'
import Timeout from 'Utils/Timeout'
import checkPhone from 'phone'
import GridContainer from 'Components/common/GridContainer'
import GridItem from 'Components/common/GridItem'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/Accordion'
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary'
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Base from 'Components/shell/PublicBase'
import { SelectField, TextField } from 'Components/common/form/'
import { checkEmail, getPartner, previewMode, signup } from 'Actions/auth'
import withTranslate from 'Root/app/withTranslate'

const styles = (theme) => ({
  expansionPanel: {
    boxShadow: 'unset',
  },
  info: {
    backgroundColor:
      theme.palette.type === 'light' ? 'antiquewhite' : 'rgb(0,0,0,0.2)',
    padding: 10,
  },
  infoWrapper: {
    marginBottom: 10,
  },
  leftMargin: {
    marginLeft: 5,
  },
  marginBottom: {
    marginBottom: 30,
  },
  questions: {
    marginBottom: 20,
    marginTop: 30,
  },
  questionDetails: {
    backgroundColor:
      theme.palette.type === 'light' ? 'antiquewhite' : 'rgb(0,0,0,0.2)',
  },
  questionSummary: {
    minHeight: 32,
    padding: 0,
  },
  questionsSummaryContent: {
    margin: 0,
  },
  questionsSummaryExpandIcon: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  recaptcha: {
    marginTop: 30,
  },
  submit: {
    marginTop: 20,
  },
})

const lower = (value) => value && value.toLowerCase()

const validate = (
  { code, country, name, email, phone },
  { partner, previewModeEnabled, t }
) => {
  const errors = {}
  if (!partner && previewModeEnabled && !code) {
    errors.code = t('auth.signup.error.code.required')
  }
  if (!name) {
    errors.name = t('auth.signup.error.name.required')
  }
  if (!email) {
    errors.email = t('auth.signup.error.email.required')
  }
  if (email && !isEmail(email)) {
    errors.email = t('auth.signup.error.email.invalid')
  }
  if (!phone) {
    errors.phone = t('auth.signup.error.phone.required')
  }
  const [formattedPhone] = checkPhone(phone, country)
  if (phone && !formattedPhone) {
    errors.phone = t('auth.signup.error.phone.invalid')
  }
  return errors
}

class Signup extends Component {
  state = {
    days: null,
    infoDays: 14,
    invalidDomain: false,
    finished: false,
    partner: null,
  }
  componentDidMount() {
    const query = queryString.parse(this.props.location.search)
    if (query.partner) {
      this.props.getPartner(query.partner)
      this.setState({ partner: query.partner, infoDays: 30 })
    }
    if (query.d) {
      const days = parseInt(query.d, 10)
      if (!isNaN(days)) {
        this.setState({ days, infoDays: days })
      }
    }
    this.props.previewMode()
  }

  checkEmail = (email) =>
    new Promise((resolve, reject) => {
      this.props.checkEmail({
        email,
        resolve,
        reject,
      })
    })

  signup = (values) =>
    new Promise((resolve, reject) => {
      this.props.signup({
        values,
        resolve,
        reject,
      })
    })

  handleSubmit = async (values) => {
    const timer = new Timeout()
    const data = {
      ...values,
      language: this.props.language,
    }
    try {
      const captcha = await Promise.race([
        window.grecaptcha.execute(process.env.RECAPTCHA, {
          action: 'signup',
        }),
        timer.set(5000, 'Captcha Timeout!'),
      ])
      const { days, partner } = this.state
      if (partner) {
        data.partner = partner
      }
      if (days !== null) {
        data.days = days
      }
      data.captcha = captcha
    } finally {
      timer.clear()
    }
    try {
      await this.checkEmail(values.email)
      await this.signup(data)
      this.setState({ finished: true })
    } catch (e) {}
  }

  handlePhoneInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9\s+-]/g, '')
  }

  render() {
    const {
      classes,
      countries,
      email,
      handleSubmit,
      invalid,
      partner,
      previewModeEnabled,
      pristine,
      submitting,
      t,
    } = this.props
    const { finished, infoDays } = this.state
    return (
      <Base title={t('auth.signup.title')} maxWidth={600}>
        {!finished && (
          <Form
            onSubmit={handleSubmit(this.handleSubmit)}
            autoComplete="off"
            noValidate
          >
            <GridContainer direction="row">
              <GridItem errorHeight xs={12} className={classes.infoWrapper}>
                <div className={classes.info}>
                  <Typography variant="subtitle1" align="center">
                    {partner ? (
                      <FormattedMessage
                        id="auth.signup.partner.info"
                        values={{ partner }}
                      />
                    ) : (
                      <>
                        {infoDays !== 0 ? (
                          <FormattedMessage
                            id="auth.signup.default.infoDays"
                            values={{ days: infoDays }}
                          />
                        ) : (
                          <FormattedMessage id="auth.signup.default.info" />
                        )}
                      </>
                    )}
                  </Typography>
                </div>
              </GridItem>
              <GridItem errorHeight xs={12} sm={6}>
                <Field
                  name="name"
                  required
                  component={TextField}
                  placeholder={t('auth.signup.name.placeholder')}
                  label={t('auth.signup.name.label')}
                  autoFocus
                />
              </GridItem>
              <GridItem errorHeight xs={12} sm={6}>
                <Field
                  name="email"
                  required
                  lower
                  type="email"
                  component={TextField}
                  placeholder={t('auth.signup.email.placeholder')}
                  label={t('auth.signup.email.label')}
                  normalize={lower}
                />
              </GridItem>
              <GridItem errorHeight xs={12} sm={6}>
                <Field
                  name="country"
                  required
                  component={SelectField}
                  label={t('auth.signup.country.label')}
                  items={countries}
                  onlySystem
                />
              </GridItem>
              <GridItem errorHeight xs={12} sm={6}>
                <Field
                  name="phone"
                  type="tel"
                  required
                  component={TextField}
                  placeholder={t('auth.signup.phone.placeholder')}
                  onInput={this.handlePhoneInput}
                  label={t('auth.signup.phone.label')}
                />
              </GridItem>
              {!partner && previewModeEnabled && (
                <GridItem errorHeight xs={12} sm={6}>
                  <Field
                    name="code"
                    required
                    component={TextField}
                    placeholder={t('auth.signup.code.placeholder')}
                    label={t('auth.signup.code.label')}
                  />
                </GridItem>
              )}
              <GridItem errorHeight xs={12}>
                <Typography>
                  <FormattedHTMLMessage id="auth.signup.info.accepted" />
                </Typography>
              </GridItem>
              <GridItem xs={12} className={classes.marginBottom}>
                <GridContainer justifyContent="center">
                  <Button
                    variant="contained"
                    className={classes.submit}
                    type="submit"
                    color="primary"
                    disabled={pristine || invalid || submitting}
                  >
                    <FormattedMessage id="auth.signup.button.submit" />
                  </Button>
                </GridContainer>
              </GridItem>
              <GridItem xs={12}>
                <GridContainer justifyContent="center" direction="row">
                  <Typography component="div" variant="subtitle1">
                    <FormattedMessage id="auth.signup.siginin.info" />
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.leftMargin}
                  >
                    <Link to={'/signin'}>
                      <FormattedMessage id="auth.signup.siginin.text" />
                    </Link>
                  </Typography>
                </GridContainer>
              </GridItem>
              <GridItem xs={12}>
                <Typography className={classes.questions} variant="subtitle2">
                  <FormattedMessage id="auth.signup.questions.info" />
                </Typography>
                {infoDays !== 0 && (
                  <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                      classes={{
                        content: classes.questionsSummaryContent,
                        expandIcon: classes.questionsSummaryExpandIcon,
                      }}
                      expandIcon={<ExpandMoreIcon />}
                      className={classes.questionSummary}
                    >
                      <Typography className={classes.heading}>
                        <FormattedMessage id="auth.signup.questions.1.header" />
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.questionDetails}>
                      <Typography>
                        {partner ? (
                          <FormattedHTMLMessage
                            id="auth.signup.questions.1.infoPartner"
                            values={{ days: infoDays }}
                          />
                        ) : (
                          <FormattedHTMLMessage
                            id="auth.signup.questions.1.info"
                            values={{ days: infoDays }}
                          />
                        )}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )}
                <ExpansionPanel className={classes.expansionPanel}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.questionSummary}
                    classes={{
                      content: classes.questionsSummaryContent,
                      expandIcon: classes.questionsSummaryExpandIcon,
                    }}
                  >
                    <Typography className={classes.heading}>
                      <FormattedMessage id="auth.signup.questions.2.header" />
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.questionDetails}>
                    <Typography>
                      <FormattedHTMLMessage id="auth.signup.questions.2.info" />
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* <ExpansionPanel className={classes.expansionPanel}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.questionSummary}
                    classes={{
                      content: classes.questionsSummaryContent,
                      expandIcon: classes.questionsSummaryExpandIcon,
                    }}
                  >
                    <Typography className={classes.heading}>
                      <FormattedMessage id="auth.signup.questions.3.header" />
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.questionDetails}>
                    <Typography>
                      <FormattedHTMLMessage id="auth.signup.questions.3.info" />
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel> */}
              </GridItem>
              <GridItem xs={12} className={classes.recaptcha}>
                <small>
                  This site is protected by reCAPTCHA and the Google{' '}
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://policies.google.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Terms of Service
                  </a>{' '}
                  apply.
                </small>
              </GridItem>
            </GridContainer>
          </Form>
        )}
        {finished && (
          <GridContainer direction="column">
            <GridItem xs={12} className={classes.marginBottom}>
              <Typography variant="subtitle1" paragraph>
                <FormattedHTMLMessage
                  id="auth.signup.finish.info"
                  values={{
                    email,
                  }}
                />
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer justifyContent="center" direction="row">
                <Typography component="div" variant="subtitle1">
                  <FormattedMessage id="auth.signup.siginin.info" />
                </Typography>
                <Typography variant="subtitle1" className={classes.leftMargin}>
                  <Link to={'/signin'}>
                    <FormattedMessage id="auth.signup.siginin.text" />
                  </Link>
                </Typography>
              </GridContainer>
            </GridItem>
          </GridContainer>
        )}
      </Base>
    )
  }
}

const selector = formValueSelector('signup')

const SignupForm = compose(
  withGoogleReCaptcha,
  withTranslate,
  withRouter,
  connect(
    (state) => ({
      country: selector(state, 'country'),
      countries: state.app.countries,
      email: selector(state, 'email'),
      initialValues: {
        country: state.app.countries.nl ? 'nl' : '',
      },
      language: state.app.language,
      partner: state.auth.partner.name,
      phone: selector(state, 'phone'),
      previewModeEnabled: state.auth.info.previewMode,
    }),
    { checkEmail, getPartner, previewMode, signup }
  ),
  withStyles(styles),
  reduxForm({
    enableReinitialize: true,
    form: 'signup',
    shouldError: () => true,
    validate,
  })
)(Signup)

const Wrapper = ({ language }) => {
  return (
    <GoogleReCaptchaProvider
      language={language}
      reCaptchaKey={process.env.RECAPTCHA}
    >
      <SignupForm />
    </GoogleReCaptchaProvider>
  )
}

export default connect((state) => ({ language: state.app.language }))(Wrapper)
