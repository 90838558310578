import * as types from 'Actions/types'
import { combineReducers } from 'redux'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const allReducer = createReducer(initialState.notification.all, {
  [types.NOTIFICATION_ADD]: (state, { values }) => {
    return {
      ...state,
      list: [values, ...state.list],
    }
  },
  [types.NOTIFICATION_LOAD_ALL_SUCCEED]: (state, { data }) => {
    return {
      ...state,
      list: data,
    }
  },
  [types.NOTIFICATION_SET_LOADING]: (state, { value }) => {
    return {
      ...state,
      loading: value,
    }
  },
  [types.NOTIFICATION_SET_READ]: (state, { id }) => {
    return {
      ...state,
      list: state.list.map((notification) => ({
        ...notification,
        read: notification.id === id ? true : notification.read,
      })),
    }
  },
})

const newReducer = createReducer(initialState.notification.new, {
  [types.NOTIFICATION_ADD]: (state, { values }) => {
    return {
      ...state,
      list: [values, ...state.list],
    }
  },
  [types.NOTIFICATION_GET_NEW_SUCCEED]: (state, { data }) => {
    return {
      ...state,
      list: data,
    }
  },
  [types.NOTIFICATION_SET_READ]: (state, { id }) => {
    return {
      ...state,
      list: state.list.filter((notification) => notification.id !== id),
    }
  },
})

const subscriptionReducer = createReducer(
  initialState.notification.subscription,
  {
    [types.NOTIFICATION_GET_SUBSCRIPTIONS_SUCCEED]: (
      state,
      { data, role = '' }
    ) => {
      return {
        ...state,
        list: data,
        role,
      }
    },
    [types.NOTIFICATION_UPDATE_SUBSCRIPTION]: (state, action) => {
      const { app, mail, notificationType, settings } = action.payload
      const list = state.list.map((item) => {
        if (item.type !== notificationType) {
          return item
        } else {
          const result = { ...item }
          if (typeof app !== 'undefined') {
            result.app = app
          }
          if (typeof mail !== 'undefined') {
            result.mail = mail
          }
          if (typeof settings !== 'undefined') {
            result.settings = settings
          }
          return result
        }
      })
      return {
        ...state,
        list,
      }
    },
    [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.notification.subscription,
  }
)

export default combineReducers({
  all: allReducer,
  new: newReducer,
  subscription: subscriptionReducer,
})
