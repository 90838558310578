import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TimelineClockOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M4 2v6H2V2h2M2 22v-6h2v6H2m3-10c0 1.11-.89 2-2 2a2 2 0 112-2m11-8c4.42 0 8 3.58 8 8s-3.58 8-8 8c-3.6 0-6.64-2.38-7.65-5.65L6 12l2.35-2.35C9.36 6.38 12.4 4 16 4m0 2c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6m-1 7V8h1.5v4.2l3 1.8-.82 1.26L15 13z" />
  </SvgIcon>
)

export default React.memo(TimelineClockOutline)
