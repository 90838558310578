import * as types from 'Actions/types'
import deepmerge from 'deepmerge'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'
import storage from 'Utils/storage'

const funnelReducer = createReducer(initialState.funnel, {
  [types.FUNNEL_LOAD_CUSTOMERS]: (state, action) => {
    const lanes = [
      ...action.container.lanes.map((lane) => {
        lane.customers &&
          lane.customers.forEach((customer) => (customer.status = lane.id))
        return { ...lane }
      }),
    ]
    return { ...state, lanes }
  },
  [types.FUNNEL_MOVE_CUSTOMER]: (
    state,
    { fromStatus, toStatus, customerId }
  ) => {
    if (fromStatus === toStatus) {
      return state
    }
    const newState = deepmerge({}, state)
    const fromLane = newState.lanes.find((x) => x.id === fromStatus)
    const toLane = newState.lanes.find((x) => x.id === toStatus)
    if (!fromLane) {
      return newState
    }
    const customer = fromLane.customers.find((x) => x.id === customerId)
    if (!customer) {
      return newState
    }
    fromLane.customers = fromLane.customers.filter((x) => x.id !== customerId)
    if (toLane) {
      toLane.customers = [
        { ...customer, status: toStatus, days: 0 },
        ...toLane.customers,
      ]
    }
    return newState
  },
  [types.FUNNEL_REMOVE_CUSTOMER_SUCCEED]: (state, { customerId }) => {
    const laneId = state.lanes.reduce((found, item) => {
      const customer = item.customers.find(
        (customer) => customer.id === customerId
      )
      if (customer) {
        return item.id
      } else {
        return found
      }
    }, null)
    if (!laneId) {
      return state
    }
    const newState = deepmerge({}, state)
    const lane = newState.lanes.find((x) => x.id === laneId)
    if (lane) {
      lane.customers = lane.customers.filter(
        (customer) => customer.id !== customerId
      )
    }
    return newState
  },
  [types.FUNNEL_SET_ASSIGNED_TO]: (state, action) => {
    storage.set('funnel.assignedTo', action.value)
    return {
      ...state,
      assignedTo: action.value,
    }
  },
  [types.FUNNEL_SHOW_CONFETTI]: (state, action) => ({
    ...state,
    confetti: action.value,
  }),
  [types.FUNNEL_TIMELINE_LOAD_SUCCEED]: (state, { values }) => ({
    ...state,
    timeline: values,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.funnel,
})

export default funnelReducer
