import React from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const styles = () => ({
  flexbox: {
    display: 'flex',
  },
  item: {
    paddingBottom: '0px !important',
    paddingTop: '0px !important',
  },
  minHeight: {
    minHeight: 67,
  },
})

const GridItem = ({
  children,
  classes,
  row,
  className,
  errorHeight = false,
  ...props
}) => (
  <Grid
    item
    classes={{ item: classes.item }}
    className={clsx(
      { [classes.flexbox]: row },
      errorHeight && classes.minHeight,
      className
    )}
    {...props}
  >
    {children}
  </Grid>
)

export default withStyles(styles)(GridItem)
