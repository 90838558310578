import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const EmoticonSad = (props) => (
  <SvgIcon {...props}>
    <path d="M12 2A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2M7 9.5C7 8.7 7.7 8 8.5 8s1.5.7 1.5 1.5S9.3 11 8.5 11 7 10.3 7 9.5m7.77 7.73C14.32 16.5 13.25 16 12 16s-2.32.5-2.77 1.23l-1.42-1.42C8.71 14.72 10.25 14 12 14c1.75 0 3.29.72 4.19 1.81l-1.42 1.42M15.5 11c-.8 0-1.5-.7-1.5-1.5S14.7 8 15.5 8s1.5.7 1.5 1.5-.7 1.5-1.5 1.5z" />
  </SvgIcon>
)

export default React.memo(EmoticonSad)
