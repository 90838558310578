import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagChina = (props) => (
  <SvgIcon viewBox="0 0 512 341.3" {...props}>
    <path fill="#D80027" d="M0 0h512v341.3H0z" />
    <g fill="#FFDA44">
      <path d="M352.3 312.6L328 294.9l-24.4 17.7 9.3-28.6-24.3-17.7h30.1l9.3-28.6 9.3 28.6h30.1L343 284zM415.9 120.9l-24.4 17.7 9.3 28.6-24.3-17.7-24.4 17.7 9.3-28.6-24.3-17.7h30.1l9.3-28.6 9.3 28.6zM367.4 57.4L343 75l9.3 28.7L328 86l-24.4 17.7 9.3-28.7-24.3-17.6h30.1l9.3-28.7 9.3 28.7zM415.9 204.4L391.5 222l9.3 28.7-24.3-17.7-24.4 17.7 9.3-28.7-24.3-17.6h30.1l9.3-28.7 9.3 28.7z" />
    </g>
    <path
      fill="#FFDA44"
      d="M167.6 64.4l26 80.3h84.5l-68.3 49.6 26.1 80.4-68.3-49.7-68.4 49.7 26.1-80.4L57 144.7h84.5z"
    />
  </SvgIcon>
)

export default React.memo(FlagChina)
