import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TextBoxPlusOutline = (props) => (
  <SvgIcon {...props}>
    <path d="M17 14h2v3h3v2h-3v3h-2v-3h-3v-2h3v-3M5 3h14c1.11 0 2 .89 2 2v7.8c-.61-.35-1.28-.6-2-.72V5H5v14h7.08c.12.72.37 1.39.72 2H5c-1.11 0-2-.89-2-2V5c0-1.11.89-2 2-2m2 4h10v2H7V7m0 4h10v1.08c-.85.14-1.63.46-2.32.92H7v-2m0 4h5v2H7v-2Z" />
  </SvgIcon>
)

export default React.memo(TextBoxPlusOutline)
