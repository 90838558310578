import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagSerbia = (props) => (
  <SvgIcon viewBox="0 85.333 512 341.333" {...props}>
    <path fill="#0052B4" d="M0 85.331h512v341.337H0z" />
    <path fill="#D80027" d="M0 85.331h512v113.775H0z" />
    <path fill="#FFF" d="M0 312.882h512v113.775H0z" />
    <path
      fill="#D80027"
      d="M129.468 181.793v85.136c0 48.429 63.267 63.267 63.267 63.267S256 315.356 256 266.929v-85.136H129.468z"
    />
    <path
      d="M155.634 196.634h74.201v-29.681l-14.841 7.42-22.261-22.26-22.259 22.26-14.84-7.42zm85.526 82.148l-48.231-48.231-48.231 48.231 15.741 15.74 32.49-32.49 32.49 32.49z"
      fill="#FFDA44"
    />
    <path
      fill="#FFF"
      d="M241.16 233.734h-22.504a14.74 14.74 0 002.001-7.418c0-8.196-6.645-14.84-14.84-14.84-5.663 0-10.581 3.172-13.083 7.836-2.502-4.663-7.421-7.836-13.083-7.836-8.195 0-14.84 6.644-14.84 14.84 0 2.706.736 5.235 2.001 7.418h-22.114c0 8.196 7.139 14.84 15.334 14.84h-.494c0 8.196 6.644 14.84 14.84 14.84 0 7.257 5.211 13.286 12.094 14.576l-11.694 26.401a48.075 48.075 0 0018.149 3.544 48.079 48.079 0 0018.149-3.544l-11.694-26.401c6.883-1.29 12.094-7.319 12.094-14.576 8.196 0 14.84-6.644 14.84-14.84h-.494c8.199 0 15.338-6.644 15.338-14.84z"
    />
  </SvgIcon>
)

export default React.memo(FlagSerbia)
