import React, { useEffect } from 'react'
import { useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import isEmail from 'validator/es/lib/isEmail'
import queryString from 'query-string'
import Base from 'Components/shell/PublicBase'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import GridContainer from 'Components/common/GridContainer'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import GridItem from 'Components/common/GridItem'
import { useFormatMessage } from 'Root/app/useTranslate'
import useLocalStorage from 'Root/app/useLocalStorage'
import { ssoSignin, ssoSigninFailed, ssoSigninSucceed } from 'Actions/auth'

const useStyles = makeStyles(() => ({
  link: {
    marginLeft: 5,
  },
  marginBottom: {
    marginBottom: 30,
  },
}))

const Sso = () => {
  const dispatch = useDispatch()
  const t = useFormatMessage()
  const { provider } = useParams()
  const location = useLocation()
  const classes = useStyles()
  const {
    code,
    error,
    error_description: errorDescription,
    iss,
    redirect,
    sso_email = '',
    state,
  } = queryString.parse(location.search)

  const [email, setEmail] = useLocalStorage('sso-email', '')
  if (sso_email && email !== sso_email) {
    setEmail(sso_email)
  }
  const callback = useRouteMatch('/sso/:provider/callback')
  const url = '/signin' + (redirect ? `?redirect=${redirect}` : '')

  useEffect(() => {
    if (iss) {
      dispatch(ssoSignin({ provider: 'sso', domain: iss }))
    }
  }, [dispatch, iss, provider])

  useEffect(() => {
    if (error) {
      dispatch(ssoSigninFailed(provider, state, error, errorDescription))
    }
  }, [dispatch, error, errorDescription, provider, state])

  useEffect(() => {
    if (callback && callback.isExact && code) {
      dispatch(ssoSigninSucceed(provider, code, state))
    }
  }, [callback, provider, code, state, dispatch])

  const handleChange = (event) => {
    setEmail(event.target.value)
  }

  const handleSignin = () => {
    dispatch(ssoSignin({ provider: 'sso', redirect, email }))
  }

  if (callback || iss) {
    return <div />
  } else {
    return (
      <Base title={t('auth.sso.title')}>
        <GridContainer direction="column">
          <GridItem x={12} className={classes.marginBottom}>
            <TextField
              name="email"
              required
              inputProps={{ style: { textTransform: 'lowercase' } }}
              type="email"
              placeholder={t('auth.sso.email.placeholder')}
              label={t('auth.sso.email.label')}
              autoFocus={!email}
              fullWidth
              value={email}
              onChange={handleChange}
            />
          </GridItem>
          <GridItem xs={12} className={classes.marginBottom}>
            <GridContainer justifyContent="center">
              <Button
                variant="contained"
                className={classes.submit}
                type="submit"
                color="primary"
                disabled={!isEmail(email)}
                onClick={handleSignin}
              >
                <FormattedMessage id="auth.signin.button.submit" />
              </Button>
            </GridContainer>
          </GridItem>
          <GridItem xs={12}>
            <GridContainer justifyContent="center">
              <Typography component="div" variant="subtitle1">
                <FormattedMessage id="auth.sso.siginin.info" />
              </Typography>
              <Typography variant="subtitle1" className={classes.link}>
                <Link to={url}>
                  <FormattedMessage id="auth.sso.siginin.text" />
                </Link>
              </Typography>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Base>
    )
  }
}

export default Sso
