import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagLuxembourg = (props) => (
  <SvgIcon viewBox="0 0 640 480" fillOpacity={14.118} fill="#28ff09" {...props}>
    <g fillRule="evenodd" fillOpacity={1}>
      <path fill="red" d="M0 0h640v160.683H0z" />
      <path fill="#fff" d="M0 160.683h640V321.55H0z" />
      <path fill="#0098ff" d="M0 321.55h640v158.448H0z" />
    </g>
  </SvgIcon>
)

export default React.memo(FlagLuxembourg)
