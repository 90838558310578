import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const FlagGermany = (props) => (
  <SvgIcon viewBox="0 0 640 480" {...props}>
    <g strokeWidth="1pt">
      <rect
        id="gold_stripe"
        width="640"
        height="480"
        y="2"
        x="0"
        fill="#FFCE00"
      />
      <rect id="red_stripe" width="640" height="320" y="1" x="0" fill="#D00" />
      <rect
        id="black_stripe"
        width="640"
        height="160"
        y="0"
        x="0"
        fill="#000"
      />
    </g>
  </SvgIcon>
)

export default React.memo(FlagGermany)
