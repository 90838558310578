export const keyify = (obj, prefix = '') =>
  Object.keys(obj).reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      return res
    } else if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyify(obj[el], prefix + el + '.')]
    } else {
      return [...res, prefix + el]
    }
  }, [])

export const isEmpty = (map) => {
  for (var key in map) {
    if (Object.prototype.hasOwnProperty.call(map, key)) {
      return false
    }
  }
  return true
}
