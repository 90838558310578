import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TableRowMerge = (props) => (
  <SvgIcon {...props}>
    <path
      d="M4.719 8.99h14.78c1.03 0 1.865.835 1.865 1.865v2.449c0 1.03-.835 1.865-1.865 1.865H4.719a1.865 1.865 0 01-1.865-1.865v-2.449c0-1.03.835-1.865 1.865-1.865z"
      fill="none"
    />
    <path d="M4.719 7.99h14.78c.719.044 1.562.376 2.026.839.463.464.795 1.307.839 2.026v2.449c-.044.719-.376 1.562-.839 2.026-.464.463-1.307.795-2.026.839H4.719c-.719-.044-1.562-.376-2.026-.839-.463-.464-.795-1.307-.839-2.026v-2.449c.044-.719.376-1.562.839-2.026.464-.463 1.307-.795 2.026-.839zm-.612 2.253c-.211.212-.297.301-.253.612v2.449c-.044.311.042.4.253.612.212.211.301.297.612.253h14.78c.311.044.4-.042.612-.253.211-.212.297-.301.253-.612v-2.449c.044-.311-.042-.4-.253-.612-.212-.211-.301-.297-.612-.253H4.719c-.311-.044-.4.042-.612.253z" />
  </SvgIcon>
)

export default React.memo(TableRowMerge)
