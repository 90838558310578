export const emailProvider = {
  domain: 'domain',
  forceflow: 'forceflow',
}

export const entity = {
  single: {
    campaign: 'campaign',
    candidate: 'candidate',
    deletion: 'deletion',
    email: 'email',
    job: 'job',
    project: 'project',
    relation: 'relation',
    user: 'user',
  },
  plural: {
    campaign: 'campaigns',
    candidate: 'candidates',
    deletion: 'deletions',
    email: 'emails',
    job: 'jobs',
    project: 'projects',
    relation: 'relations',
    user: 'users',
  },
}

export const language = {
  en: 'en',
  nl: 'nl',
}

export const messageLevel = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
}

export const relationType = {
  customer: 'customer',
  contact: 'contact',
  supplier: 'supplier',
}

export const role = {
  admin: 'admin',
  hiring: 'hiring',
  sourcer: 'sourcer',
  system: 'system',
  timesheet: 'timesheet',
  user: 'user',
  viewer: 'viewer',
}

export const palette = {
  dark: 'dark',
  light: 'light',
}
