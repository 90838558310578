import React from 'react'
import ForceFlowIcon from 'Icons/ForceFlow'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 48,
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 20,
  },
}))

const Loading = () => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.root}>
      <ForceFlowIcon
        className={classes.icon}
        color={theme.palette.type === 'dark' ? 'white' : undefined}
      />
      <Typography color="textSecondary">Loading</Typography>
    </div>
  )
}

export default Loading
